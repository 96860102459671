


<template>
<div class="pullout-content pullout-panel">
  <div class="bkg"></div>
  <div class="pullout-screens">

    <img v-if="settings.turnoverCompare" class="w-full" src="/img/screens/pullout-turnover-compare-selected.png?v=10122022" />
    <img v-else class="w-full" src="/img/screens/pullout-turnover-compare.png?v=10122022" />
    <screen-link v-if="settings.turnoverCompare" left="83%" top="1%" width="14%" height="5%" data-tgt="reports-turnover-12months-compare-date" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>
    <screen-link v-else left="83%" top="1%" width="14%" height="5%" data-tgt="reports-turnover-12months" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>
    <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
    <screen-link left="48%" top="29%" width="14%" height="5%" data-tgt="profile" dot-class="dot-top-center" data-is-overlay="true" @click.native="settings.turnoverCompare = !settings.turnoverCompare"></screen-link>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "reports-compare-pullout",
  components: {ScreenLink},
  mounted() {

  },
  props: {
      settings: {
        type: Object,
        default: function(){
          return { turnoverCompare: false }
        }
      }

  },
    data: function(){
        return {

        }
    },
    methods: {

      onLinkClick: function(e){
        this.$emit('link', e);
        this.$emit('close');
      },

      onSave: function(){
        this.$emit('close')
      }
    },
    computed: {}
}
</script>

<style scoped>


.pullout-content {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.labor-cost-threshold-pullout img {
  display: block;
}

.pullout-content .bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.pullout-content .pullout-screens {
  width: calc((600/1580) * 100% + 15px);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;


}



.w-full {
  width: 100%;
}

.links {

}


</style>
