<template>
    <div class="filters-panel text-black" :style="panelStyle">
      <div v-if="currentLevel === 0">
        <header class="header">
            <span>Template</span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <div class="menu-items">
          <small class="menu-heading">OPEN RECENT</small>
          <div class="menu-item">
            <svg class="icon" viewBox="0 0 20 20" id="gly_document_solid" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M13.046 2a1 1 0 0 1 .753.341l2.954 3.376a1 1 0 0 1 .174.283H13V2h.046zM12 7h5v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h8v5z" fill="#898A8C"></path></svg> <span>12 Month</span><span class="tag">Custom</span>
          </div>
        </div>
        <ul class="selections">
          <li>
            <span>More Templates...</span>
          </li>
        </ul>
        <footer>
          <ul class="selections">
            <li class="muted">
              <span>Save...</span>
            </li>
            <li>
              <span>Save As...</span>
            </li>
          </ul>
        </footer>
      </div>

    </div>
</template>

<script>


    export default {
        name: "report-template-panel",
        components: {},
      mounted: function(){

      },
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
              default: "30days"
          }
        },
        data: function () {
            return {
                isInactive: true,
                hasChanges: false,
                title: "Filters",
                currentLevel: 0,
                currentCategory: 0,
                selections: [
                        { title: "Compare Dates...", selection: "All", selections: [

                            ]
                        },
                        { title: "Group By", selection: "All", selections: [
                                { title: "Check Date", selected: true },
                            ]
                        },
                  { title: "Set Threshold...", selection: "All", selections: [

                    ]
                  },
                  { title: "Maximize", selection: "All", selections: [

                    ]
                  },

                    ]



            }
        },
        methods: {
            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e);
            this.$emit('close');
          },

          onShowPullout: function(e){
              console.log("emiting pullout",e);
            this.$emit('pullout',e);
            this.$emit('close');
          },




            apply: function(){
                this.hasChanges = false;
                this.$emit('apply', this.selections);
            },



            setLevel: function(level){
                this.currentLevel = level;
            },




        },
        computed: {
            panelStyle: function(){
                return {
                    width: this.width,
                    top: this.top,
                    left: this.left
                }
            },


            currentSelections: function(){

                if(this.currentLevel){
                    return this.selections[this.currentCategory].selections;

                }else{
                    return this.selections;
                }
            }

        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

    .menu-items {
      padding: 0.75em 1em;
    }

    .menu-heading {
      opacity: 0.6;
    }

    .menu-item {
      display: flex;
      align-items: center;
    }

    .menu-item .icon {
      width: 1.25em;
      height: 2em;
      margin-right: 1em;
    }

    .muted {
      opacity: 0.25;
    }

    .tag {
      font-size: 0.8em;
      padding: 0.2em 0.5em;
      opacity: 0.6;
      border: 1px solid black;
      border-radius: 1em;
      margin-left: 0.5em;
    }



    .drop-down-arrow::after {
      display: block;
      content: '\25BC';
      position: relative;
      top: 2px;
      speak: none;
      font-size: 1em;
      transform: scaleY(.5) scaleX(1);
    }

    footer {
      border-top: 1px solid #d8d8d8;
    }




    .v-center {
      display: flex;
      align-items: center;
    }

</style>
