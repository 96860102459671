<template>



    <div class="main-nav">
        <div class="nav-container">
            <div class="header-bar">
                <svg class="logo" viewBox="0 0 218.18 22" id="paychexbeta_logo" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M20.77 7.26c0-4.65-2.46-6.76-6.24-6.76H4.3L0 21.5h6.27l1.3-6.34h4.28c5.42.01 8.92-3.64 8.92-7.9zM11 10.16H8.61l.89-4.29h2.16c1.33 0 2.75.27 2.75 1.89 0 1.43-.83 2.4-3.41 2.4zM26.79.51l-11.92 21h6.47l1.59-3h6.73l.35 3h6.23L33 .51zM25.23 14l3.21-6.29h.06l.69 6.29h-4zM48.93.51l-5 7.82-2-7.82h-6.4L40 13.8l-1.56 7.7h6.35l1.56-7.76 10-13.23zm17 13c-.4 1.85-1.53 3.23-3.52 3.23-2.92 0-3.33-2.7-3.33-4.44 0-3 1.27-7.07 4.8-7.07 2 0 2.89 1.12 2.83 3.08h6C72.55 2 68.45 0 63.85 0c-7 0-11.19 5.66-11.19 12.44 0 5.56 2.89 9.56 8.87 9.56 8.58 0 10.2-6.34 10.58-8.48h-6.18zm22.63-13L87 7.86h-5.8L82.7.5h-6.32l-4.3 21h6.35l1.7-8.27h5.8l-1.7 8.27h6.35l4.35-21h-6.37zm38.24 0L122.68 6l-2-5.47H97.1l-4.31 21h23.76l4.76-5.87 2.28 5.87h7.13l-5-11.14L134.3.53h-7.54zm-16.31 19l.7-3.42h-10.94l.55-2.66h9.94l1-5h-9.91l.55-2.61h10.69L114 1l4.48 9.35zm38.14-10.27l1-4.85h7.78l3.37-3.88h-14.92l-4.44 21H146l1.85-8.67h6.26l3.1-3.59h-8.58zm13.92 8.38L166.14.5h-4.64l-4.41 21h12.26l3.32-3.88zm15.47 0l1.09-5.15 6.35-.07 3-3.52h-8.63l.94-4.5h7.8L191.82.5H177l-4.41 21h12.7l3.36-3.88h-10.63zM211.29.51h-5.47l-5.38 6.94-3-6.94h-5l4.91 9.94-9.56 11.05h5.59l6-7.5 3.29 7.5h5.09l-5.2-10.85zM215.18 0a3 3 0 0 0-3 3.07 3 3 0 0 0 3 3.07 3 3 0 0 0 3-3.07 3 3 0 0 0-3-3.07zm0 5.65a2.52 2.52 0 0 1-2.53-2.59 2.53 2.53 0 1 1 5.05 0 2.45 2.45 0 0 1-2.52 2.59zm1.38-3.43c0-.6-.36-1-1.14-1h-1.32v3.59h.42V3.25h.72l1 1.56h.48l-1-1.56c.48-.12.84-.36.84-1.02zm-1.44.6h-.54v-1.2h.78c.42 0 .84.12.84.6s-.54.61-1.08.61z" fill="#fff"></path></svg>
            </div>

            <div class="app-links">
                <div class="app-group-header">
                    <span>MAIN</span>
                </div>
                <div class="app-link dot-right-center" data-show-dot="false" :data-selected='(activeAppLink=="easy-setup-start")'  @click="onNavLink('/easysetup/easy-setup-start')">
                    <div class="app-link-icon">
                      <svg id="g-app-glyph-onboarding" viewBox="0 0 20 20" style="transform:scale(0.7);"><path fill-rule="evenodd" d="M10 0C11.319 0 13.719 3.194 14.289 4.049C15.425 5.751 16 7.417 16 9C16 9.06104 15.9966 9.12484 15.9931 9.18877V9.18879V9.1888V9.18881C15.9907 9.23378 15.9882 9.27882 15.987 9.323L17.281 10.401C17.738 10.782 18 11.342 18 11.937V15.945C18 16.353 17.776 16.725 17.416 16.916C17.254 17.002 17.077 17.045 16.9 17.045C16.685 17.045 16.47 16.982 16.284 16.856L14.414 15.609C14.237 15.75 14.023 15.842 13.788 15.855L12.496 15.905L12.5 16C12.5 16.6867 11.9968 17.6449 10.9904 18.8746L10.7958 19.1084C10.7507 19.1617 10.7001 19.2101 10.6449 19.2529C10.2084 19.5912 9.58029 19.5115 9.24199 19.075L9.05525 18.8298C8.15175 17.6216 7.7 16.6783 7.7 16H7.699H6.38C6.10829 16 5.85347 15.8957 5.65585 15.7191L5.562 15.625L3.711 16.86C3.529 16.983 3.316 17.045 3.103 17.045C2.926 17.045 2.747 17.003 2.584 16.916C2.224 16.725 2 16.353 2 15.945V11.937C2 11.341 2.263 10.781 2.721 10.4L4.013 9.323C4.01177 9.27952 4.00936 9.23485 4.00695 9.19012L4.00695 9.1901L4.00695 9.19008L4.00694 9.19007C4.00348 9.12577 4 9.06132 4 9C4 7.419 4.575 5.753 5.711 4.05C6.281 3.194 8.681 0 10 0ZM15.752 11.73C15.646 12.36 15.517 12.985 15.35 13.572L15.28 13.784L16 14.263V11.937L15.752 11.73ZM4.001 11.937L4.247 11.732C4.353 12.363 4.482 12.988 4.649 13.575C4.66174 13.6184 4.67447 13.6582 4.68721 13.6979C4.69647 13.7268 4.70574 13.7557 4.715 13.786L4 14.263L4.001 11.937ZM10 2.138C9.451 2.582 8.338 3.714 7.375 5.159C6.463 6.528 6 7.821 6 9C6 10.283 6.209 11.749 6.573 13.025C6.685 13.415 6.795 13.729 6.919 14H13C13 14 13.323 13.382 13.427 13.021C13.791 11.744 14 10.279 14 9C14 7.819 13.537 6.527 12.625 5.159C11.661 3.713 10.549 2.582 10 2.138ZM12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10C11.1046 10 12 9.10457 12 8Z"></path></svg>
                    </div>
                    <div class="app-link-label">
                        <span>Onboarding</span>
                    </div>
                </div>




                <hr class="app-group-header" />
                <div class="app-group-header">
                    <span>OTHER</span>
                </div>

              <div class="app-link dot-right-center" data-show-dot="false"   :data-selected='(activeAppLink=="easy-setup-help")' @click="onNavLink('/easysetup/easy-setup-help')">
                    <div class="app-link-icon">
                        <svg viewBox="0 0 30 30" id="help" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M24 15a9 9 0 0 0-18 0 9 9 0 0 0 18 0zm-7.88 4.9a.55.55 0 0 1-.56.55h-1.12a.55.55 0 0 1-.56-.55v-1.09a.55.55 0 0 1 .56-.55h1.13a.55.55 0 0 1 .56.55v1.09zm0-4v.73a.55.55 0 0 1-.56.55h-1.12a.55.55 0 0 1-.56-.55V15c0-.6.14-1.09.84-1.09h.84a.55.55 0 0 0 .56-.55v-1.08a.55.55 0 0 0-.56-.54h-1.12a.55.55 0 0 0-.56.54.54.54 0 0 1-.56.55h-1.13a.44.44 0 0 1-.52-.54 3.39 3.39 0 0 1 6.7.54 3.27 3.27 0 0 1-2.25 3.07z"></path></svg>
                    </div>
                    <div class="app-link-label ">
                        <span>Help Center</span>
                    </div>
                </div>


            </div>
        </div>

    </div>


</template>

<script>
    export default {
        name: "flex-easy-setup-side-nav",
        props: {
            activeLink: {
                type: String,
                required: false,
                default: null
            },
        },
        data: function () {
            return {}
        },
        methods: {
            onNavLink: function(url){
                let isExternal =  url.startsWith("http");
              if(isExternal){
                window.open(url,"_blank");
              }else{
                this.$store.commit("clearPanels");
                this.$router.push(url);
              }

            },
          openTab: function(url){


              this.$screens.showExternalPage({
                target: {
                  dataset: {
                    tgt: url,
                    tgtQuery: true
                  }
                }
              })


          }
        },
        computed: {
            activeAppLink: function(){
                return this.$route.params.screen;
            }


        }
    }
</script>

<style scoped>




    .main-nav {
        font-size: 0.9em;
        position: absolute;
        width: calc((225/1580) * 100%);
        box-sizing: border-box;
        background-color: white;
        top: 0;
        height: 0;
        padding-bottom: calc((920/1580) * 100%);


        font-family: "Helvetica", "Arial", sans-serif;
        -webkit-font-smoothing: antialiased;

    }

    .nav-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    .icon-container svg {
        position: relative;
        height:100%;
        width: 30px;

    }

    .icon-container svg.help-svg {
        width: 24px;

    }





    .header-bar {
        position: relative;
        width: 100%;
        height: calc((60/920) * 100%);
        background-color: #0369b1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-bar .logo {
        position: relative;
        width: calc((180/225) * 100%);
        height: calc((20/60) * 100%);

    }



    .app-group-header {
        font-size: 0.9em;
        margin: 0.9em;
        color: #7c8d99;
        box-sizing: border-box;
    }

    hr.app-group-header {
        border: 0;
        height: 1px;
        background-color: #D8D8D8;
        margin: calc((10/225) * 100%) calc((20/225) * 100%);
    }

    .app-links {
        position: relative;
        height: calc(((920 - 107)/920) * 100%);

    }

    .app-link {
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        justify-items: center;
        height: calc((40/(920 - 107)) * 100%);
        width: 100%;
        box-sizing: border-box;
        padding: calc((10/225) * 100%);
        padding-left: calc((14/225) * 100%);
        color: #7c8d99;
        cursor: pointer;
        fill: #7c8d99;


    }

    .app-link svg {

        cursor: pointer;
    }

    .app-link-icon {
        position: absolute;
        display: block;
        width: calc((30/225)*100%);
        height: calc((30/40)*100%);
        cursor: pointer;

    }


    .app-link-label {

        padding-left: calc((40/225) * 100%);
        cursor: pointer;

    }

    .app-link:hover {
        color: #0369b1;
        fill: #0369b1;
    }

    .app-link:hover .app-link-icon svg {

    }

    .app-link.link-disabled {
        cursor: default;
        pointer-events: none;
    }

    .app-link[data-selected="true"]{
        background-color: #f3f7fa;
        color: #0369b1;
        fill: #0369b1;
    }

    .app-link[data-selected="true"]:before {
        content: "";
        width: calc((5/225) * 100%);
        background-color: rgb(3, 105, 177);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

    }

    .hidden {
        display: none;
    }

</style>
