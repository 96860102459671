


<template>
<div class="labor-cost-threshold-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="labor-cost-threshold-list">


    <div class="labor-cost-threshold-scroll" v-if="!detailPanel">
      <img class="save-button" v-if="threshold" src="/img/screens/pullout-admin-reports-labor-cost-thresholds-save.png?v=10122022" />
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-reports-labor-cost-thresholds.png?v=10122022" />
      <div class="links">

        <img v-if="notify" class="notify" style="top:50%;" src="/img/screens/pullout-admin-reports-labor-cost-thresholds-notify.png?v=10122022" />
        <p class="threshold-num" style="top:11.2%; left:50.5%; width:5%;">{{thresholdValue}}</p>
        <p class="threshold-num" style="top:13.5%; left:36.3%; width:5%;">{{thresholdValue}}</p>
        <p class="threshold-30" style="bottom:60%; left:7%; width:70%;"><label>Enter Value <small>*</small></label><span v-if="threshold" class="amount">{{ thresholdValue }}</span></p>

       <toggle-switch :is-on="notify" style="top:43.5%; left: 87%;"></toggle-switch>

        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link v-if="threshold" left="83%" top="1%" width="14%" height="5%" :data-tgt="`reports-labor-cost-with-${(thresholdValue==='33')?'threshold':'supervisors-threshold'}`" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>
        <screen-link left="3%" top="33%" width="92%" height="8%" data-tgt="threshold" dot-class="dot-left-center" data-is-overlay="true" @click.native="threshold = !threshold"></screen-link>
        <screen-link left="86%" top="43.5%" width="9%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="notify = !notify"></screen-link>

      </div>
    </div>

    <div class="labor-cost-threshold-scroll" v-if="detailPanel">
      <img class="save-button" v-if="threshold" src="/img/screens/pullout-admin-reports-labor-cost-thresholds-save.png?v=10122022" />
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-reports-labor-cost-thresholds.png?v=10122022" />
      <div class="links">

        <img v-if="notify" class="notify" style="top:50%;" src="/img/screens/pullout-admin-reports-labor-cost-thresholds-notify.png?v=10122022" />
        <p class="threshold-num" style="top:11.2%; left:50.5%; width:5%;">{{thresholdValue}}</p>
        <p class="threshold-num" style="top:13.5%; left:36.3%; width:5%;">{{thresholdValue}}</p>
        <p class="threshold-30" style="bottom:60%; left:7%; width:70%;"><label>Enter Value <small>*</small></label><span v-if="threshold" class="amount">{{ thresholdValue }}</span></p>

        <toggle-switch :is-on="notify" style="top:43.5%; left: 87%;"></toggle-switch>

        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link v-if="threshold" left="83%" top="1%" width="14%" height="5%" :data-tgt="`reports-labor-cost-with-${(thresholdValue==='33')?'threshold':'supervisors-threshold'}`" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>
        <screen-link left="3%" top="33%" width="92%" height="8%" data-tgt="threshold" dot-class="dot-left-center" data-is-overlay="true" @click.native="threshold = !threshold"></screen-link>
        <screen-link left="86%" top="43.5%" width="9%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="notify = !notify"></screen-link>

      </div>
    </div>

  </div>





</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ToggleSwitch from "@/components/ToggleSwitch";
export default {
    name: "labor-cost-threshold-pullout",
  components: {ToggleSwitch, ScreenLink},
  props: {
      thresholdValue: {
        type: String,
        default: "33"
      }
  },
    data: function(){
        return {
          detailPanel: null,
          notify: false,
          threshold: false
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
        if(this.$store.state.panelInitInfo){
          this.thresholdValue = this.$store.state.panelInitInfo;
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.labor-cost-threshold-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.threshold-30 {
  position: absolute;
}

.threshold-30 label {
  font-size: 0.9em;
  opacity: 0.4;
}

.threshold-30 .amount {
  display: block;
  font-size: 1.2em;
}


.threshold-num {
  position: absolute;
  font-size: 1em;
  color: #222;
  -webkit-font-smoothing: antialiased;
}

.labor-cost-threshold-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.labor-cost-threshold-pullout img {
  display: block;
}

.labor-cost-threshold-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.labor-cost-threshold-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.labor-cost-threshold-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

.save-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.notify {
  width: 100%;
  position: absolute;
}


</style>
