


<template>
<div class="pullout-content pullout-panel">
  <div class="bkg"></div>
  <div class="pullout-screens">
    <div v-if="level">
      <img v-if="isDocMgmt" class="w-full" src="/img/screens/pullout-reports-document-mgmt-columns-edit.png?v=10122022" />
      <img v-else-if="settings.turnoverColumns" class="w-full" src="/img/screens/pullout-report-columns-add-annual.png?v=10122022" />
      <img v-else class="w-full" src="/img/screens/pullout-report-columns-add.png?v=10122022" />

      <screen-link v-if="!isDocMgmt" left="10%" top="35.5%" width="25%" height="6%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="settings.turnoverColumns = !settings.turnoverColumns"></screen-link>

      <screen-link left="66%" top="88%" width="15%" height="6%" data-tgt="profile" dot-class="dot-top-center" data-is-overlay="true" @click.native="level = 0"></screen-link>

      <screen-link left="81%" top="88%" width="15%" height="6%" data-tgt="profile" dot-class="dot-top-center" data-is-overlay="true" @click.native="level = 0"></screen-link>
    </div>
    <div v-else>
      <img v-if="isDocMgmt" class="w-full" src="/img/screens/pullout-reports-document-mgmt-columns.png?v=10122022" />
      <img v-else-if="settings.turnoverColumns" class="w-full" src="/img/screens/pullout-report-columns-modified.png?v=10122022" />
      <img v-else class="w-full" src="/img/screens/pullout-report-columns.png?v=10122022" />
      <screen-link left="92%" top="1%" width="7%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" @click.native="onSave"></screen-link>
      <screen-link left="72%" top="8%" width="26%" height="5%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="level = 1" v-if="!isLaborCost"></screen-link>


    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "reports-columns-pullout",
  components: {ScreenLink},

  mounted: function(){
      if(!this.disableEdit){
        this.initSelection = this.settings.turnoverColumns;
      }

  },

  props: {
      settings: {
        type: Object,
        default: function(){
          return {
            turnoverColumns: false
          }
        }
      },
    currentScreen: {
      type: String,
      default: ""
    }

  },
    data: function(){
        return {
          initSelection: false,
          level: 0
        }
        },
    methods: {

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },

      onSave: function(){

        if(this.disableEdit){
          this.$emit('close');
          return;
        }

        if(this.initSelection !== this.settings.turnoverColumns){
          this.$emit('link',{
            target: {
              dataset: {
                tgt: this.settings.turnoverColumns ? 'reports-turnover-12months-compare-date' : 'reports-turnover-12months-compare-date'
              }
            }
          })
        }

        this.$emit('close')
      }
    },
    computed: {
      disableEdit: function(){
        return (this.isDocMgmt || this.isLaborCost)
      },
      isDocMgmt: function(){
        return this.currentScreen.startsWith("reports-live-reports-document-management");
      },
      isLaborCost: function(){
        return this.currentScreen.startsWith("reports-labor-cost");
      },
    }
}
</script>

<style scoped>


.pullout-content {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.labor-cost-threshold-pullout img {
  display: block;
}

.pullout-content .bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.pullout-content .pullout-screens {
  width: calc((600/1580) * 100% + 15px);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;


}



.w-full {
  width: 100%;
}

.links {

}


</style>
