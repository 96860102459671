


<template>
<div class="">


  <div class="panel-content">


    <div class="help-scroll" v-if="detailPanel" ref="scrollPanel">


      <div class="detail-content" data-screen="profile" :data-active="detailPanel=='profile'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-add-new-employee-profile.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
        </div>
      </div>




      <div class="detail-content" data-screen="add-employee" :data-active="detailPanel=='add-employee'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-people-add-employee-info.png?v=10122022" />
        <div class="links">

          <screen-link left="89%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="47.5%" width="90%" height="6%" data-tgt="add-employee-details" @click.native="showDetails"></screen-link>



        </div>
      </div>

      <div class="detail-content" data-screen="add-employee-details" :data-active="detailPanel=='add-employee-details'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-people-add-employee-info-details.png?v=10122022" />
        <div class="links">

          <screen-link left="89%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="79%" top="192.5%" width="20%" height="6%" dot-class="dot-top-center" data-tgt="review-employment" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="review-employment" :data-active="detailPanel=='review-employment'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-add-new-employee-review.png?v=10122022" />
        <div class="links">

          <screen-link left="89%" top="1.5%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="90%" width="15%" height="6%" dot-class="dot-top-center" data-tgt="add-employee-details" @click.native="showDetails"></screen-link>
          <screen-link left="50%" top="90%" width="22%" height="6%" dot-class="dot-top-center" data-tgt="add-employee-finish" @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="90%" width="25%" height="6%" dot-class="dot-top-center" data-tgt="hire-tasks" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-tasks'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-add-new-employee-tasks.png?v=02032022" />
        <div class="links">
          <screen-link left="90%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="88%" width="15%" height="6%" dot-class="dot-top-center" data-tgt="review-employment" @click.native="showDetails"></screen-link>

          <screen-link left="48%" top="88%" width="25%" height="5%" data-tgt="add-employee-finish"  dot-class="dot-top-center"  @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='add-employee-finish'" >
        <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-add-new-employee-finished.png?v=02032022" />
        <div class="links">
          <screen-link left="90%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="52%" top="58.5%" width="17%" height="5.5%" data-tgt="profile" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-personal'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-personal.png?v=10122022" />
        <div class="links">
          <screen-link left="94%" top="1%" width="5%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="115%" width="15%" height="5.5%" data-tgt="hire-tasks" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="115%" width="23%" height="5.5%" data-tgt="hire-compensation" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="115.5%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-compensation'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-compensation.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="118.5%" width="15%" height="5.5%" data-tgt="hire-personal" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="118.5%" width="23%" height="5.5%" data-tgt="hire-taxes" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="119%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-taxes'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-taxes.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="100%" width="15%" height="5.5%" data-tgt="hire-compensation" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="74%" top="100%" width="23%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="48%" top="100.5%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-deductions'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-deductions.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="48%" width="15%" height="5.5%" data-tgt="hire-taxes" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="48%" width="15%" height="5.5%" data-tgt="hire-i9" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="59%" top="48%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-i9'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-i9.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="54%" width="15%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="6%" top="24%" width="41%" height="12.5%" data-tgt="hire-i9-company" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="56%" top="54%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="detail-content" :data-active="detailPanel=='hire-i9-company'" >
        <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-i9-company.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="6%" height="5.5%" data-tgt="profile" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="60.5%" width="15%" height="5.5%" data-tgt="hire-deductions" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="60.5%" width="15%" height="5.5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-top-center"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="56%" top="61%" width="23%" height="5%" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>



    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
//import ComingSoon from "@/components/ComingSoon";
//import CompanyTabStatic from "@/components/CompanyTabStatic";
export default {
    name: "easy-setup-people-pullout-profile-panel",
  components: {  ScreenLink},
  props: {
      detailPanel: {
        default: "profile"
      }
  },
    data: function(){
      return {}
    },


    methods: {
      showDetails: function(e){

        if(!this.$store.state.easySetup.addEmployees.completed){
          if(e.target.dataset.tgt === 'review-employment'){
            this.$store.state.easySetup.addEmployees.progress = "details"
          }
          if((e.target.dataset.tgt === 'profile')){
            this.$store.state.easySetup.addEmployees.completed = true;
          }
        }

        this.$emit('change',e);
        this.$refs.scrollPanel.scrollTo(0,0);
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },
      showExternalPage: function(e){
        this.$emit("external", e)
      }

    },
    computed: {}
}
</script>

<style scoped>


.profile-pullout-old {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;


}

.panel-content img {
  display: block;
}

.panel-content {

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

}



.help-scroll {
  position: relative;
  height: 100%;

  overflow-y: scroll;

}

.w-full {
  width: 100%;
}

.links {

}

.detail-content {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
}


.detail-content[data-active="true"] {
  display: block;
}

</style>
