<template>
    <div class="filters-panel text-black" :style="panelStyle">
      <div v-if="currentLevel === 0">
        <header class="header">
            <span>Documents count</span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <ul class="selections">

          <li>
            <span>Group By</span>
            <span class="text-primary">{{ selection }} <img class="icon chevron-icon" src="../assets/chevron-right-icon.svg" /></span>
          </li>
        </ul>

      <screen-link left="0%" top="52%" width="99%" height="30%" dot-class="dot-right-center" data-tgt="" @click.native="setLevel(1)"></screen-link>


        </div>
      <div v-if="currentLevel === 1">
        <header class="header">
          <span class="v-center" @click="setLevel(0)"><svg class="back-icon" viewBox="0 0 7 18" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path fill="#404040" d="M5.208 14.82c.224.24.58.24.8 0l.795-.859a.63.63 0 0 0 0-.857L2.957 9.017l3.876-4.121a.635.635 0 0 0 0-.859l-.795-.86a.542.542 0 0 0-.801 0L.165 8.586a.647.647 0 0 0 0 .86l5.043 5.374z"></path></svg> <span> Group By</span></span>
          <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <ul class="selections">
          <li :class="(selection==='Expiration date')?'selected':''">
            <span>Expiration date</span>
          </li>
          <li>
            <span>Uploaded date</span>
          </li>
          <li>
            <span>Signed/acknowledged</span>
          </li>
          <li>
            <span>Document category</span>
          </li>
          <li :class="(selection==='Worker location')?'selected':''">
            <span>Worker location</span>
          </li>
          <li>
            <span>Worker supervisor</span>
          </li>
          <li>
            <span>Worker position</span>
          </li>
          <li>
            <span>Custom...</span>
          </li>

        </ul>

        <screen-link left="0%" top="14.5%" width="100%" height="9%" dot-class="dot-right-center" data-tgt="reports-live-reports-document-management-expiration" @click.native="onLinkClick"></screen-link>


        <screen-link left="0%" top="56%" width="100%" height="9%" dot-class="dot-right-center" data-tgt="reports-live-reports-document-management-expiration-location" @click.native="onLinkClick"></screen-link>
      </div>

    </div>
</template>

<script>

    import ScreenLink from "@/components/ScreenLink";
    export default {
        name: "document-count-options-panel",
        components: {ScreenLink},
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
            default: "Expiration date"
          }
        },
        data: function () {
            return {
                isInactive: true,
                hasChanges: false,
                title: "Filters",
                currentLevel: 0,
                currentCategory: 0,




            }
        },
        methods: {
            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e)
          },

          showPullout: function(e){
            this.$emit('pullout',e)
          },



            apply: function(){
                this.hasChanges = false;
                this.$emit('apply', this.selections);
            },


            setLevel: function(level){
              console.log("Set Level", level)
                this.currentLevel = level;
            },



        },
        computed: {
            panelStyle: function(){
                return {
                    width: this.width,
                    top: this.top,
                    left: this.left
                }
            },


        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .v-center {
      display: flex;
      align-items: center;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

</style>
