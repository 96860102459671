<template>
  <div class="tablet-layout" :data-guided="$store.state.guidedMode" :data-show-links="$store.state.showLinks">
    <img class="tablet-bg" src="/img/backgrounds/office-space.jpg" />
      <div class="ipad-frame">
        <div class="tablet-screens" ref="screens">
          <slot name="screens"></slot>
        </div>

        <div class="tablet-pullouts" v-if="$store.state.pulloutPanel">
          <slot name="pullouts"></slot>
        </div>
        <img class="w-full ipad-img" src="../assets/ipad-frame-8.png" />

      </div>
    <div class="close-button" :data-tgt="dataTgt" :data-tgt-mode="dataTgtMode" @click="$emit('close', $event)">
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabletLayout",
  mounted() {
    this.$store.state.screenScrollRef = this.$refs["screens"];
  },
  props: {
    dataTgtMode: {
      type: String,
      default: ""
    },
    dataTgt: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

    .tablet-layout {
      width: 100%;
      position: absolute;
      height: 0;
      overflow: hidden;
      padding-bottom: calc((920/1580) * 100%);
      background-color: black;
      font-size: 1em;
    }

    .tablet-bg {
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      overflow: hidden;
    }
    .close-button {
      position: absolute;
      top: calc((30 / 920)*100%);
      right: calc((357 / 1580) * 100%);
      z-index: 20;


    }

    .close-button svg {
      fill:white;
      padding: 0.5em;
      background-color: var(--color-green);
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      border-radius: 100%;
      border: 2px solid white;
      pointer-events: none;

    }

    .ipad-frame {
      width: calc((866 / 1580)*100%);
      height: calc((890 / 920) * 100%);
      overflow: hidden;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      top: calc((30 / 920)*100%);
      z-index: 10;

    }

    .ipad-img {
      position: relative;
      pointer-events: none;
    }

    .tablet-screens {
      position: absolute;
      top: calc((50/890) * 100%);
      left: calc((47/866) * 100%);
      width: calc((768/866)*100%);
      height: calc((838 / 890)*100%);
      overflow-x: hidden;
      overflow-y: scroll;
      background: white;
    }

    .tablet-pullouts {
      position: absolute;
      top: calc((50/890) * 100%);
      left: calc((47/866) * 100%);
      width: calc((768/866)*100%);
      height: calc((838 / 890)*100%);
    }

</style>