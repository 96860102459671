<template>
  <div>
    <div class="screen" data-screen="access" :data-active="currentScreen=='access'">
      <img class="w-full max-w-100" src="/img/screens/admin-user-access.png?v=10122022" />
      <div class="links">
        <screen-link left="18.5%" top="16%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="access-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="15.5%" top="36%" width="82.5%" height="7%" dot-class="dot-right-center" data-tgt="pullout-access-role" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="15.5%" top="66%" width="82.5%" height="7%" dot-class="dot-right-center" data-tgt="pullout-access-role" data-init-info="mike" @click.native="$screens.showPullout"></screen-link>
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>User Access tab is where administrators can manage user roles, and grant access when necessary. It’s important that clients have admin roles setup from the onset to ensure changes to employee information.</p>
        </info-box>
      </div>
    </div>



    <div class="screen" data-screen="access-changed" :data-active="currentScreen=='access-changed'">
      <img class="w-full max-w-100" src="/img/screens/admin-user-access-changed.png?v=10122022" />
      <div class="links">
        <screen-link left="18.5%" top="16%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="access-roles" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="15.5%" top="36%" width="82.5%" height="7%" dot-class="dot-right-center" data-tgt="pullout-access-role" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="15.5%" top="66%" width="82.5%" height="7%" dot-class="dot-right-center" data-tgt="pullout-access-role" data-init-info="mike-role-changed" @click.native="$screens.showPullout"></screen-link>

      </div>
    </div>

    <div class="screen" data-screen="access-roles" :data-active="currentScreen=='access-roles'">
      <img class="w-full max-w-100" src="/img/screens/admin-user-access-roles.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="16%" width="3.5%" height="5%" dot-class="dot-bottom-center" data-tgt="access" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="15.5%" top="66%" width="82.5%" height="6%" dot-class="dot-right-center" data-tgt="pullout-role-editor" data-init-info="" @click.native="$screens.showPullout"></screen-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminAccess",
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>