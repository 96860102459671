


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='reports'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-reports.png?v=09142023" />
          <div class="links">
            <screen-link  left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="5%" top="42%" width="90%" height="7.5%"  data-tgt="hiring-v2-candidates-report" dot-class="dot-right-center"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs.png?v=09142023" />
          <div class="links">
            <screen-link  left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="5%" top="10%" width="90%" height="7.5%"  data-tgt="prefs-job-portal" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="22%" width="90%" height="7.5%"  data-tgt="prefs-templates" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs-templates'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs-templates.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="20%" height="4.5%" data-tgt="prefs" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

            <screen-link left="3%" top="26.5%" width="90%" height="4.5%" data-tgt="prefs-template" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs-template'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs-operations-template.png?v=09142023" />
          <div class="links">
            <screen-link left="66%" top="1%" width="14%" height="4.5%" data-tgt="prefs-templates" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="71%" top="72%" width="16%" height="4.5%" data-tgt="prefs-template-actions" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs-template-actions'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs-template-actions.png?v=09142023" />
          <div class="links">
            <screen-link left="66%" top="1%" width="16%" height="4.5%" data-tgt="prefs-template" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="26%" width="90%" height="15%" data-tgt="prefs-template-actions-details" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>


        <div class="detail-content" :data-active="detailPanel=='prefs-template-actions-details'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs-template-actions-details.png?v=09142023" />
          <div class="links">
            <screen-link left="66%" top="1%" width="16%" height="4.5%" data-tgt="prefs-template" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="82%" top="1%" width="16%" height="4.5%" data-tgt="prefs-template-save" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs-template-save'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-template-save.png?v=09142023" />
          <div class="links">
            <screen-link left="66%" top="1%" width="14%" height="4.5%" data-tgt="prefs-templates" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="83%" top="1%" width="16%" height="4.5%" data-tgt="prefs-templates" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='prefs-job-portal'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-prefs-job-portal.png?v=09142023" />
          <div class="links">
            <screen-link left="67%" top="1%" width="15%" height="4.5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="82%" top="1%" width="15%" height="4.5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>


          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>



            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="12%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="13%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="23%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="37%" top="8%" width="12%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>

            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-tools'" >

          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-tools-offer.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="21%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35%" top="8%" width="10%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="74%" top="23%" width="20%" height="5.5%" data-tgt="hiring-hire-right" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <screen-link left="74%" top="45%" width="20%" height="5.5%" data-tgt="hiring-offer-package" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-tools-bkgcheck-ordered'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-tools-bkgcheck-order.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="21%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35%" top="8%" width="10%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="74%" top="23%" width="20%" height="5.5%" data-tgt="hiring-hire-right" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>


        <div class="detail-content" :data-active="detailPanel=='profile-tools-offer'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-tools-offer.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="21%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35%" top="8%" width="10%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="74%" top="45%" width="20%" height="5.5%" data-tgt="hiring-offer-package" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-notes'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-notes.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="21%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35%" top="8%" width="10%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-ratings'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-rating.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="21%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35%" top="8%" width="10%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="80%" top="15%" width="18%" height="5%" data-tgt="profile-rate" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-rate'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-rate.png?v=09142023" />
          <div class="links">

            <screen-link left="66%" top="1%" width="15%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="22%" width="40%" height="5%" data-tgt="profile-rate-details" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-rate-details'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-rate-details.png?v=09142023" />
          <div class="links">

            <screen-link left="66%" top="1%" width="32%" height="5%" data-tgt="profile-ratings" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>


          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-status'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-status.png?v=09142023" />
          <div class="links">
            <div class="status-flag"><span>{{hiringStatus}}</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="12%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="23%" top="8%" width="14%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="37%" top="8%" width="12%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="58%" top="13%" width="21%" height="3.5%" data-tgt="profile-feedback-request" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
            <screen-link left="58%" top="26%" width="21%" height="3.5%" data-tgt="hire" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='profile-feedback-request'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-feedback-requests.png?v=09142023" />
          <div class="links">
            <screen-link left="87%" top="42%" width="10%" height="5.5%" data-tgt="profile-ratings" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
            <screen-link left="69%" top="58%" width="25%" height="5%" data-tgt="profile-feedback-request-list" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='profile-feedback-request-list'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-profile-feedback-requests-list.png?v=09142023" />
          <div class="links">
            <screen-link left="65%" top="79%" width="32%" height="5.5%" data-tgt="profile-ratings"  data-tgt-scrolltop="true" dot-class="dot-top-center"  @click.native="showDetails"></screen-link>
            <screen-link left="50%" top="79%" width="15%" height="5.5%" data-tgt="profile-ratings" dot-class="dot-top-center" data-tgt-scrolltop="true"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='hire'" >
          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-hire.png?v=09142023" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5.5%" data-tgt="hire-continue" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="67%" top="1%" width="14%" height="5.5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>

            <company-tab-onboarding top="14.5%" left="6%" width="50%" @select="setCompany" :company="$store.state.onboardingType" :show-links="true" variant="hiring" />

            <bubble-message  pointing="left" :styles="{left:'55%',top:'11.5%',width:'25%'}">Use the company selector to access PEO Onboarding.</bubble-message>



          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='hire-continue'" >
          <img v-if="$store.state.onboardingType === 'peo'" class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-continue-peo.png?v=08212023" />
          <img v-else class="w-full" src="/img/screens/hiring/pullouts/candidate-hire-continue.png?v=08212023" />
          <div class="links">
            <screen-link left="74%" top="58.5%" width="23%" height="5.5%" data-tgt="pullout-profile" :data-init-info="($store.state.onboardingType === 'peo')?'add-employee-details-peo':'hire-tasks'" dot-class="dot-top-center"  @click.native="$screens.showPullout"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='offer-confirmation'" >

          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-tools-offer-confirmation.png?v=08212023" />
          <div class="links">


            <div class="status-flag"><span>OFFER</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12.5%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="23%" top="8%" width="13%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35.5%" top="8%" width="9.5%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="52%" width="41%" height="5.5%" data-tgt="candidate-offer-review-email" data-tgt-mode="candidate" dot-class="dot-right-center"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='offer-signed'" >

          <img class="w-full" src="/img/screens/hiring/pullouts/candidate-tools-offer-signed.png?v=08212023" />
          <div class="links">


            <div class="status-flag"><span>OFFER</span></div>
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="8%" width="11%" height="5%" data-tgt="profile" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="12.5%" top="8%" width="10%" height="5%" data-tgt="profile-tools" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="23%" top="8%" width="13%" height="5%" data-tgt="profile-ratings" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="35.5%" top="8%" width="9.5%" height="5%" data-tgt="profile-notes" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>

            <screen-link left="72%" top="1%" width="21%" height="5%" data-tgt="profile-status" dot-class="dot-bottom-center"  @click.native="showOptions = true;"></screen-link>
            <hiring-profile-options-panel  v-if="showOptions" width="40%" left="52%" top="4%" :on-select="updateStatus"></hiring-profile-options-panel>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='i9'" >
          <img class="w-full" src="/img/screens/dashboard/pullout/pulllout-tasks-i9-employees-start.png?v=08212023" />
          <div class="links">
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="42%" top="39%" width="16%" height="5%" data-tgt="admin-i9" data-tgt-mode="hiretech" dot-class="dot-bottom-center"  @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>





    </div>

  </div>
  </div>

</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import HiringProfileOptionsPanel from "@/components/HiringProfileOptionsPanel";
import CompanyTabOnboarding from "@/components/CompanyTabOnboarding";
import BubbleMessage from "@/components/BubbleMessage";



export default {
    name: "candidate-pullout",
  components: {BubbleMessage, CompanyTabOnboarding, HiringProfileOptionsPanel, ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "loan-terms",
          showOptions: false,
          evaluation: false,
          hiringStatus: "screening"
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
        if(this.$route.query.level === "offer-confirmation"){
          this.hiringStatus = "offer"
        }
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      updateStatus: function(newstatus){

        //this.showDetails(e);
        this.hiringStatus = newstatus;
        this.showOptions=false;
        if(newstatus === "hire"){
          this.detailPanel = "hire"
        }

        if(newstatus === "background"){
          this.detailPanel = "profile-tools"
        }

        if(newstatus === "evaluation"){
          this.detailPanel = "profile-feedback-request"
        }

      },

      setCompany: function(company){
        //console.log(company)
        this.$store.state.onboardingType = company;
      },


      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        this.$refs['basicScroll'].scrollTo(0,0);


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

.status-flag {
  position: absolute;
  top: 5.5%;
  left: 6.2%;
  width: calc((130/600) * 100%);
  background: white;
  color: #00a4e7;
}

.status-flag span {
  text-transform: uppercase;
  font-size: 0.8em;
}

</style>
