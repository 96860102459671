


<template>
<div class="payroll-options-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="payroll-options-list">


    <div class="payroll-options-scroll" v-if="!detailPanel && $store.state.company === 'pro'">
      <img class="w-full" src="/img/screens/payroll/pullouts/pullout-admin-payroll-options-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="43.5%" width="92%" height="11%" data-tgt="customize" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="73.25%" width="92%" height="8%" data-tgt="block-direct" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="payroll-options-scroll" v-if="!detailPanel && $store.state.company !== 'pro'">
      <img class="w-full" src="/img/screens/pullout-admin-payroll-options.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="43.5%" width="92%" height="11%" data-tgt="customize" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="73.25%" width="92%" height="8%" data-tgt="block-direct" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="82%" width="92%" height="8%" data-tgt="block-time-off" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="payroll-options-scroll" v-if="detailPanel === 'customize'">
      <img class="w-full" src="/img/screens/admin-payroll-center-more-options-customize.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="2%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="18%" width="92%" height="10%" data-tgt="customize-view-grid" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'timing-options'">
      <img class="w-full" src="/img/screens/payroll/pullouts/pullout-payroll-timing-options.png?v=03252024" />
      <div class="links">

        <screen-link left="91%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'payroll-center-options'">
      <img class="w-full" src="/img/screens/payroll/pullouts/pullout-payroll-center-options.png?v=03252024" />
      <div class="links">

        <screen-link left="91%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="31%" width="94%" height="8.5%" data-tgt="onsite-printing" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'onsite-printing'">
      <img class="w-full" src="/img/screens/payroll/pullouts/pullout-payroll-center-options-onsite-printing.png?v=03252024" />
      <div class="links">

        <screen-link left="91%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>


      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'customize-view-group'">
      <img class="w-full" src="/img/screens/admin-payroll-center-more-options-customize-view-group.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="customize" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="91%" top="1%" width="8%" height="4%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="67%" top="6%" width="16%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="29%" width="92%" height="7%" data-tgt="customize-view-group" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="36%" width="92%" height="7%" data-tgt="customize-view-list" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="43%" width="92%" height="7%" data-tgt="customize-view-grid" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'customize-view-list'">
      <img class="w-full" src="/img/screens/admin-payroll-center-more-options-customize-view-list.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="customize" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="91%" top="1%" width="8%" height="4%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="67%" top="6%" width="16%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="29%" width="92%" height="7%" data-tgt="customize-view-group" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="36%" width="92%" height="7%" data-tgt="customize-view-list" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="43%" width="92%" height="7%" data-tgt="customize-view-grid" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'customize-view-grid'">
      <img class="w-full" src="/img/screens/admin-payroll-center-more-options-customize-view-grid.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="4%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="67%" top="6%" width="16%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="18%" width="92%" height="7%" data-tgt="customize-view-group" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="25%" width="92%" height="7%" data-tgt="customize-view-list" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="32%" width="92%" height="7%" data-tgt="customize-view-grid" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
      </div>
    </div>


    <div class="payroll-options-scroll" v-if="detailPanel === 'block-direct'">
      <img class="w-full" src="/img/screens/pullout-admin-payroll-options-block-direct.png?v=10122022" />
      <div class="links">
        <screen-link left="65%" top="1%" width="35%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="payroll-options-scroll" v-if="detailPanel === 'block-time-off'">
      <img class="w-full" src="/img/screens/pullout-admin-payroll-options-block-timeoff.png?v=10122022" />
      <div class="links">
        <screen-link left="65%" top="1%" width="35%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <coming-soon label="Time Off is managed under Time & Attendance for Paychex Flex® Time subscribers" />
      </div>
    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ComingSoon from "@/components/ComingSoon";
export default {
    name: "payroll-options-pullout",
  components: {ComingSoon, ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.payroll-options-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.payroll-options-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.payroll-options-pullout img {
  display: block;
}

.payroll-options-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.payroll-options-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.payroll-options-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
