<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <!-- DASHBOARD -->


        <div class="screen z-max" data-screen="hr-training" :data-active="currentScreen=='hr-training'">
          <img class="w-full max-w-100" src="/img/screens/training.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-library" :data-active="currentScreen=='hr-training-library'">
          <img class="w-full max-w-100" src="/img/screens/training-library.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-calendar" :data-active="currentScreen=='hr-training-calendar'">
          <img class="w-full max-w-100" src="/img/screens/training-calendar.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

            <screen-link left="75%" top="73.5%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar-register" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-calendar-register" :data-active="currentScreen=='hr-training-calendar-register'">
          <img class="w-full max-w-100" src="/img/screens/training-calendar-register.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

            <screen-link left="76.75%" top="63.9%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar-registered" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-calendar-registered" :data-active="currentScreen=='hr-training-calendar-registered'">
          <img class="w-full max-w-100" src="/img/screens/training-calendar-registered.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

            <screen-link left="75%" top="73.5%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar-unregister" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-calendar-unregister" :data-active="currentScreen=='hr-training-calendar-unregister'">
          <img class="w-full max-w-100" src="/img/screens/training-calendar-unregister.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>

            <screen-link left="18.25%" top="11%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="26.5%" top="11%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-library" @click.native="onLinkClick"></screen-link>
            <screen-link left="36.75%" top="11%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>

            <screen-link left="5%" top="9%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="14.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

            <screen-link left="76.75%" top="63.9%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-calendar" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-journeys" :data-active="currentScreen=='hr-training-journeys'">
          <img class="w-full max-w-100" src="/img/screens/training-journeys.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="31.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>


            <screen-link left="5%" top="8.25%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="13.5%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-journeys" @click.native="onLinkClick"></screen-link>

          </div>
        </div>






        <div class="screen z-max" data-screen="hr-training-author" :data-active="currentScreen=='hr-training-author'">
          <img class="w-full max-w-100" src="/img/screens/training-courses.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>



          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys" :data-active="currentScreen=='hr-training-surveys'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="4.75%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-create" @click.native="onLinkClick"></screen-link>
            <screen-link left="28%" top="20%" width="25%" height="9%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-distributions" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-create" :data-active="currentScreen=='hr-training-surveys-create'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-create.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="96.5%" top="1.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-with-sample" @click.native="onLinkClick"></screen-link>
            <screen-link left="40%" top="45%" width="12%" height="9%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-create-type" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-create-type" :data-active="currentScreen=='hr-training-surveys-create-type'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-create-type.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="96.5%" top="1.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-with-sample" @click.native="onLinkClick"></screen-link>
            <screen-link left="42.5%" top="68.5%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-create-type-quality" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-create-type-quality" :data-active="currentScreen=='hr-training-surveys-create-type-quality'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-create-type-quality.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="96.5%" top="1.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-with-sample" @click.native="onLinkClick"></screen-link>
            <screen-link left="40%" top="45%" width="12%" height="9%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-create" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-with-sample" :data-active="currentScreen=='hr-training-surveys-with-sample'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-with-sample.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="4.75%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-create" @click.native="onLinkClick"></screen-link>
            <screen-link left="28%" top="19%" width="25%" height="9%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-distributions" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-distributions" :data-active="currentScreen=='hr-training-surveys-distributions'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-distributions.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="26%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-distributions-new" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-distributions-sales-added" :data-active="currentScreen=='hr-training-surveys-distributions-sales-added'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-distributions-sales-added.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="28.5%" top="42%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-distributions-sales-results" @click.native="onLinkClick"></screen-link>

            <screen-link left="92%" top="43%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys-distributions" data-reset="true" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-distributions-sales-results" :data-active="currentScreen=='hr-training-surveys-distributions-sales-results'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-distributions-sales-results.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="32.5%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="9.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>
            <screen-link left="5.5%" top="25.25%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="26%" top="1.25%" width="14%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-distributions-sales-added" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-distributions-new" :data-active="currentScreen=='hr-training-surveys-distributions-new'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-distributions-new.png?v=01192023" />
          <div class="links">


            <screen-link left="55.5%" top="69.5%" width="5.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-surveys-distributions" @click.native="onLinkClick"></screen-link>
            <screen-link left="30.5%" top="27%" width="25%" height="28%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="hr-training-surveys-distributions-new-sales" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="hr-training-surveys-distributions-new-sales" :data-active="currentScreen=='hr-training-surveys-distributions-new-sales'">
          <img class="w-full max-w-100" src="/img/screens/training-surveys-distributions-new-sales.png?v=01192023" />
          <div class="links">


            <screen-link left="55.5%" top="69.5%" width="5.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-surveys-distributions" @click.native="onLinkClick"></screen-link>
            <screen-link left="61%" top="69.5%" width="9.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-training-surveys-distributions-sales-added" @click.native="onLinkClick"></screen-link>


          </div>
        </div>



        <div class="screen z-max" data-screen="hr-training-analytics" :data-active="currentScreen=='hr-training-analytics'">
          <img class="w-full max-w-100" src="/img/screens/training-analytics.png?v=01192023" />
          <div class="links">
            <screen-link left="0%" top="3%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="22%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="42%" width="4.5%" height="8%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-training-author" @click.native="onLinkClick"></screen-link>



          </div>
        </div>


    </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";
// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "training-screens",
  components: { ScreenLink, DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      if(this.$route.query.guided){

        let value = (this.$route.query.guided.trim() === "true");
        console.log("guided param",this.$route.query.guided, value )
        this.$store.commit("guidedMode", value);
      }else{
        this.$store.commit("guidedMode", false);
      }

      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'hr-training',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,
      salesAdded: false

    }
  },
  methods: {

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{

        if(e.target.dataset.tgt === 'hr-training-surveys-distributions-sales-added'){
          this.salesAdded = true;
        }

        if(e.target.dataset.reset){
          this.salesAdded = false;
        }

        if((e.target.dataset.tgt === 'hr-training-surveys-distributions') && this.salesAdded){
          this.setScreen('hr-training-surveys-distributions-sales-added', e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
        }else{
          this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
        }



      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "training"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          this.$refs['screens'].scrollTo(0,0);
          //window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "hr"){

          this.$router.push({name: "training", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}




.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
  html {
    background: black;
  }
</style>
