


<template>
<div class="live-reports-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="live-reports-list">


    <div class="live-reports-scroll" v-if="!detailPanel && $store.state.company === 'pro'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="65%" width="92%" height="8%" data-tgt="general-ledger" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="3%" top="83%" width="92%" height="12%" data-tgt="pay-benchmarking" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="3%" top="104.5%" width="92%" height="10.5%" data-tgt="labor-cost" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="114.75%" width="92%" height="10.5%" data-tgt="retention-insights" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll" v-if="!detailPanel && $store.state.company !== 'pro'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="83%" width="92%" height="9.5%" data-tgt="pay-discrepancy" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="92.5%" width="92%" height="8%" data-tgt="general-ledger" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="45%" width="92%" height="8%" data-tgt="document-management" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="141%" width="92%" height="12%" data-tgt="pay-benchmarking" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="3%" top="162.75%" width="92%" height="10.5%" data-tgt="labor-cost" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="182.75%" width="92%" height="10.5%" data-tgt="retention-insights" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'pay-discrepancy'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-pay-discrepancy.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="32%" width="92%" height="6%" data-tgt="reports-pay-discrepancy" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'general-ledger'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-general-ledger.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="32%" width="92%" height="6%" data-tgt="reports-general-ledger" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'document-management'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-document-management.png?v=10122022" />
      <div class="links">
        <div v-if="hideBack" style="position: absolute; left: 1%; top: 1%; height: 4%; width: 13%; background: white;"></div>
        <screen-link v-else left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="38%" width="92%" height="6%" data-tgt="reports-live-reports-document-management-expiration" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'labor-cost'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-labor-cost.png?v=10122022" />
      <div class="links">
        <div v-if="hideBack" style="position: absolute; left: 1%; top: 1%; height: 4%; width: 13%; background: white;"></div>
        <screen-link v-else left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="32%" width="92%" height="6%" data-tgt="reports-labor-cost" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'pay-benchmarking'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-pay-benchmarking.png?v=10022023" />
      <div class="links">
        <div v-if="hideBack" style="position: absolute; left: 1%; top: 1%; height: 4%; width: 13%; background: white;"></div>
        <screen-link v-else left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="39%" width="92%" height="8%" data-tgt="reports-pay-range" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'retention-insights'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-admin-live-reports-retention.png?v=10022023" />
      <div class="links">
        <div v-if="hideBack" style="position: absolute; left: 1%; top: 1%; height: 4%; width: 13%; background: white;"></div>
        <screen-link v-else left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="3%" top="39%" width="92%" height="8%" data-tgt="reports-retention" dot-class="dot-left-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "live-reports-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null,
          hideBack: false
        }
        },

    mounted() {
      if(this.$route.query.level !== undefined){
        this.hideBack = true;
        this.detailPanel = this.$route.query.level;

      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
          let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });

      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.live-reports-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.live-reports-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.live-reports-pullout img {
  display: block;
}

.live-reports-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.live-reports-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.live-reports-scroll {
  position: relative;
  overflow-y: scroll;

}

.w-full {
  width: 100%;
}

.links {

}

</style>
