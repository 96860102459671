


<template>
<div class="manage-ledger-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="manage-ledger-list">


    <div v-if="!detailPanel && ($store.state.company === 'pro')" class="manage-ledger-scroll">
      <img class="w-full" src="/img/screens/ledger/pullouts/pullout-manage-general-ledger-pro.png?v=10122022" />
      <div class="links">

        <span class="format-label" style="top:37.5%;left:7.5%">{{format}}</span>
        <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="0.5%" top="13.25%" width="14%" height="5%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15%" top="13.25%" width="13%" height="5%" data-tgt="accounts" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="30%" top="13.25%" width="16%" height="5%" data-tgt="options" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="5%" top="34%" width="48%" height="7%" data-tgt="ledgerFormatOptions" dot-class="dot-right-center" data-is-overlay="true" @click.native="$screens.onOverlayPanel"></screen-link>

        <ledger-format-options-panel v-if="$store.state.overlayPanel === 'ledgerFormatOptions'" :set-format="setFormat" left="5%" top="32%" width="60%" @close="$screens.setOverlayPanel" ></ledger-format-options-panel>
      </div>
    </div>
    <div class="manage-ledger-scroll" v-if="!detailPanel && ($store.state.company !== 'pro')">
      <img class="w-full" src="/img/screens/ledger/pullouts/pullout-manage-general-ledger.png?v=10122022" />
      <div class="links">

        <span class="format-label" style="top:37.5%;left:7.5%">{{format}}</span>
        <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="0.5%" top="13.25%" width="14%" height="5%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15%" top="13.25%" width="13%" height="5%" data-tgt="accounts" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="30%" top="13.25%" width="16%" height="5%" data-tgt="options" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="5%" top="34%" width="48%" height="7%" data-tgt="ledgerFormatOptions" dot-class="dot-right-center" data-is-overlay="true" @click.native="$screens.onOverlayPanel"></screen-link>

        <ledger-format-options-panel v-if="$store.state.overlayPanel === 'ledgerFormatOptions'" :set-format="setFormat" left="5%" top="32%" width="60%" @close="$screens.setOverlayPanel" ></ledger-format-options-panel>
      </div>
    </div>
    <div class="manage-ledger-scroll" v-if="detailPanel === 'accounts'">
      <img class="w-full" src="/img/screens/ledger/pullouts/pullout-manage-gl-accounts.png?v=10122022" />
      <div class="links">

        <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="0.5%" top="13.25%" width="14%" height="5%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15%" top="13.25%" width="13%" height="5%" data-tgt="accounts" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="30%" top="13.25%" width="16%" height="5%" data-tgt="options" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="manage-ledger-scroll" v-if="detailPanel === 'options'">
      <img class="w-full" src="/img/screens/ledger/pullouts/pullout-manage-gl-payroll-options.png?v=10122022" />
      <div class="links">

        <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="0.5%" top="13.25%" width="14%" height="5%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15%" top="13.25%" width="13%" height="5%" data-tgt="accounts" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="30%" top="13.25%" width="16%" height="5%" data-tgt="options" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import LedgerFormatOptionsPanel from "@/components/LedgerFormatOptionsPanel";
export default {
    name: "ledger-manage-pullout",
  components: {LedgerFormatOptionsPanel, ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null,
          format: "QuickBooks® Desktop"
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },

      setFormat: function(val){
       this.format = val;
      }
    },
    computed: {}
}
</script>

<style scoped>




.manage-ledger-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.manage-ledger-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.manage-ledger-pullout img {
  display: block;
}

.manage-ledger-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.manage-ledger-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  overflow-y: scroll;

}



.manage-ledger-scroll {
  position: relative;
  height: 100%;


}

.w-full {
  width: 100%;
}

.links {

}

.format-label {
  position: absolute;
  font-size: 0.9em;
  background: white;
  width: 29ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}

</style>
