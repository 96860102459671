


<template>
<div class="toggle-switch">
  <img class="toggle-img"  :data-on="!isOn" src="/img/icons/toggle-off-5.png" />
  <img class="toggle-img"  :data-on="isOn" src="/img/icons/toggle-on-5.png" />
</div>
</template>

<script>
export default {
    name: "toggle-switch",
    props: {
      isOn: {
        type: Boolean,
        default: false
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.toggle-switch {
  width: 2.5em;
  height: 2.5em;
  display: inline-block;
  position: absolute;
  background: white;
}

.toggle-img {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-img[data-on="true"]{
  opacity: 1.0;
}



</style>
