<template>
  <div>
    <div class="screen" data-screen="people" :data-active="currentScreen=='people'">
      <img class="w-full max-w-100" src="/img/screens/directory/company-directory.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="34%" width="15%" height="8%" dot-class="dot-left-center" data-tgt="people-andrea" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>In Company Directory view, clients can see full listing of active employees, with contact information and quick search functionality. Employees can also be accessed in the People List – giving a full profile view. Admins can edit information from either location, and when employees have access to Paychex Flex®, they can make their own changes, eliminating time spent for business owners and managers.</p>
        </info-box>
      </div>
    </div>
    <div v-if="$store.state.company == 'pro'" class="screen" data-screen="people-andrea" :data-active="currentScreen=='people-andrea'">
      <img class="w-full max-w-100" src="/img/screens/directory/company-directory-pro-andrea.png?v=10182023" />
      <div class="links">

        <screen-link left="15%" top="14%" width="11%" height="4%" dot-class="dot-right-center" data-tgt="people" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="52%" top="55.5%" width="10%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="39%" top="91.75%" width="8%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="pay-compensation" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="67.5%" top="101.5%" width="9%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="pay-history" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="38%" top="116.5%" width="9%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="retention" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="people-andrea" :active-companies="{enterprise:true,pro:true}"/>

      </div>
    </div>

    <div v-else class="screen" data-screen="people-andrea" :data-active="currentScreen=='people-andrea'">
      <img class="w-full max-w-100" src="/img/screens/directory/company-directory-andrea.png?v=10182023" />
      <div class="links">

        <screen-link left="15%" top="14%" width="11%" height="4%" dot-class="dot-right-center" data-tgt="people" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="52%" top="55.5%" width="10%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="39%" top="91.75%" width="8%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="pay-compensation" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="37%" top="131%" width="11%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="conversations" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="67.5%" top="161.5%" width="11%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="performance" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="67.5%" top="101.5%" width="9%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="pay-history" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="65.5%" top="130%" width="13%" height="4%" dot-class="dot-left-center" data-tgt="time-attendance-home" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="67.5%" top="186%" width="9%" height="4%" dot-class="dot-left-center" data-tgt="pullout-profile" data-init-info="retention" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="people-andrea" :active-companies="{enterprise:true,pro:true}"/>

      </div>
    </div>
  </div>
</template>

<script>
import CompanyTab from "@/components/CompanyTab";
export default {
  name: "AdminDirectory",
  components: {CompanyTab},
  props: {
    currentScreen: {}
  },
  data: function(){
    return {
      companyStartScreen: "people"
    }
  }
}
</script>

<style scoped>

</style>