


<template>
<div class="live-reports-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="live-reports-list">


    <div class="live-reports-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-quick-reports.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>


        <screen-link left="3%" top="56%" width="92%" height="6%" data-tgt="payroll-journal" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="62.2%" width="92%" height="6%" data-tgt="cash-requirements" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="3%" top="76.5%" width="92%" height="6%" data-tgt="payroll-journal" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="3%" top="94.5%" width="92%" height="6%" data-tgt="cash-requirements" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="2%" top="5.5%" width="7.5%" height="4%" data-tgt="sets" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="10%" top="5.5%" width="15%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'sets'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-quick-reports-sets.png?v=10122022" />
      <div class="links">
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>



        <screen-link left="2%" top="5.5%" width="7.5%" height="4%" data-tgt="sets" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="10%" top="5.5%" width="15%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'payroll-journal'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-quick-reports-payroll-journal.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll pullout-screen" :data-active="detailPanel === 'cash-requirements'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-quick-reports-cash-requirements.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="13%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "quick-reports-pullout",
  components: {ScreenLink},
  props: {
      initInfo: {
        type: String,
        default: ""
      }
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {

      if(this.initInfo){
        this.detailPanel = this.initInfo;
      }else{
        if(this.$route.query.level !== undefined){
          this.detailPanel = this.$route.query.level;
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.live-reports-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.live-reports-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.live-reports-pullout img {
  display: block;
}

.live-reports-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.live-reports-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.live-reports-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
