<template>
  <div>

    <!--- LEGACY RETIREMENT ADMIN -->
    <div class="screen" data-screen="retirement-home" :data-active="currentScreen=='retirement-home'">
      <img class="w-full max-w-100" src="/img/screens/admin-retirement-home.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-home-assets-by-fund" :data-active="currentScreen=='retirement-home-assets-by-fund'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-assets-by-fund.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-home-assets-by-type" :data-active="currentScreen=='retirement-home-assets-by-type'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-assets-by-type.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-home-performance" :data-active="currentScreen=='retirement-home-performance'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-assets-performance.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-home-activity" :data-active="currentScreen=='retirement-home-activity'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-activity.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-home-billing" :data-active="currentScreen=='retirement-home-billing'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-billing.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="52%" width="7.75%" height="4%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="60%" width="5.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.75%" top="60%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-fund" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.75%" top="60%" width="12%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-assets-by-type" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="51.5%" top="60%" width="8.25%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-performance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="59.75%" top="60%" width="6%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-activity" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="66%" top="60%" width="8%" height="4%" dot-class="dot-top-center" data-tgt="retirement-home-billing" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="76%" top="67%" width="11%" height="4.5%" data-tgt="retirement-home-beneficiary-form" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-home-beneficiary-form" :data-active="currentScreen=='retirement-home-beneficiary-form'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-home-beneficiary-form.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>

      </div>
    </div>

    <!-- EMPLOYEE DATA -->
    <div class="screen" data-screen="retirement-employees-all" :data-active="currentScreen=='retirement-employees-all'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-all.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28%" top="87.5%" width="14%" height="5%" data-tgt="retirement-employees-all-filters" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="42%" top="210%" width="10%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-all-filters" :data-active="currentScreen=='retirement-employees-all-filters'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-all-filters.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28.5%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="42%" top="210%" width="10%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-newly" :data-active="currentScreen=='retirement-employees-newly'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-newly.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-newly-expanded" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-newly-filters" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />




      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-newly-filters" :data-active="currentScreen=='retirement-employees-newly-filters'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-newly-filters.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-newly-expanded-filters" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28.5%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-newly-expanded" :data-active="currentScreen=='retirement-employees-newly-expanded'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-newly-expanded.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-newly" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-newly-expanded-filters" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-newly-expanded-filters" :data-active="currentScreen=='retirement-employees-newly-expanded-filters'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-newly-expanded-filters.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-newly-filters" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-newly-expanded" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28.5%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="35%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-newly-expanded" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-enrolled" :data-active="currentScreen=='retirement-employees-enrolled'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-enrolled.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="42.25%" top="85%" width="2.5%" height="3%" data-tgt="retirement-employees-enrolled-expanded" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-enrolled-unchecked" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="42.25%" top="88%" width="10.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />




      </div>
    </div>


    <div class="screen" data-screen="retirement-employees-enrolled-expanded" :data-active="currentScreen=='retirement-employees-enrolled-expanded'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-enrolled-expanded.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-enrolled-unchecked-expanded" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="42.5%" top="88%" width="10%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-enrolled-unchecked" :data-active="currentScreen=='retirement-employees-enrolled-unchecked'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-enrolled-unchecked.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-enrolled-unchecked-expanded" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-enrolled" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="28.5%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="42.5%" top="88%" width="10%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />




      </div>
    </div>


    <div class="screen" data-screen="retirement-employees-enrolled-unchecked-expanded" :data-active="currentScreen=='retirement-employees-enrolled-unchecked-expanded'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-enrolled-unchecked-expanded.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <screen-link left="43%" top="85%" width="2%" height="3%" data-tgt="retirement-employees-enrolled-unchecked" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74%" top="72.5%" width="11.5%" height="3.75%" data-tgt="retirement-employees-newly-email" @click.native="showPullout"></screen-link>

        <screen-link left="28%" top="88.5%" width="7%" height="3%" data-tgt="retirement-employees-enrolled-expanded" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="28.5%" top="71%" width="6%" height="3%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="42.5%" top="88%" width="10%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>

        <info-box left="28.25%" top="27.5%" :hover="false"></info-box>
        <info-box left="39%" top="27.5%" :hover="false"></info-box>
        <info-box left="50.5%" top="27.5%" :hover="false"></info-box>
        <info-box left="61.5%" top="27.5%" :hover="false"></info-box>
        <info-box left="75.5%" top="27.5%" :hover="false"></info-box>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />
      </div>
    </div>

    <!-- EMPLOYEE AMANDA -->

    <div class="screen" data-screen="retirement-employees-amanda" :data-active="currentScreen=='retirement-employees-amanda'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="69.25%" top="47.75%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-profile-edit" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="69.25%" top="142.5%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-profile-edit" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-profile-edit" :data-active="currentScreen=='retirement-employees-amanda-profile-edit'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-profile-edit.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="69.25%" top="47.75%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="69.25%" top="119%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-balances" :data-active="currentScreen=='retirement-employees-amanda-balances'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-balances.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="38%" top="63.5%" width="14%" height="4%" data-tgt="vanguard-fund-pdf" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen z-max" data-screen="vanguard-fund-pdf" :data-active="currentScreen=='vanguard-fund-pdf'">
      <img class="w-full max-w-100" src="/img/screens/vanguard-fund-pdf.png?v=10122022" />
      <div class="links">

        <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="previous" @click.native="$screens.showPrevious"></screen-link>

      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-future" :data-active="currentScreen=='retirement-employees-amanda-future'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-future.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="38%" top="63.5%" width="14%" height="4%" data-tgt="vanguard-fund-pdf" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-loans" :data-active="currentScreen=='retirement-employees-amanda-loans'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-loans.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-withdrawals" :data-active="currentScreen=='retirement-employees-amanda-withdrawals'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-withdrawals.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-history" :data-active="currentScreen=='retirement-employees-amanda-history'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-history.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-amanda-requests" :data-active="currentScreen=='retirement-employees-amanda-requests'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-amanda-requests.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="30.5%" top="44%" width="7%" height="4%" data-tgt="retirement-employees-amanda-balances" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="37.5%" top="44%" width="8%" height="4%" data-tgt="retirement-employees-amanda-future" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="45.75%" top="44%" width="3.5%" height="4%" data-tgt="retirement-employees-amanda-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="49%" top="44%" width="9.5%" height="4%" data-tgt="retirement-employees-amanda-withdrawals" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-history" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="65.25%" top="44%" width="6.5%" height="4%" data-tgt="retirement-employees-amanda-requests" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>



    <!-- EMPLOYEES CENSUS -->

    <div class="screen" data-screen="retirement-employees-census" :data-active="currentScreen=='retirement-employees-census'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-census.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-loans" :data-active="currentScreen=='retirement-employees-loans'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-loans.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <div class="screen" data-screen="retirement-employees-withdrawals" :data-active="currentScreen=='retirement-employees-withdrawals'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-employees-withdrawals.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="41%" width="5.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="32.5%" top="41%" width="7%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-census" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39.5%" top="41%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-loans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="43.5%" top="41%" width="10%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-employees-withdrawals" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />


      </div>
    </div>

    <!-- ADMINISTRATION -->

    <div class="screen" data-screen="retirement-admin" :data-active="currentScreen=='retirement-admin'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-admin.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="52.25%" top="54.5%" width="20%" height="2.5%" data-tgt="retirement-admin-enrollment-options" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="52.25%" top="90%" width="20%" height="2.5%" data-tgt="retirement-admin-testing" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-admin-enrollment-options" :data-active="currentScreen=='retirement-admin-enrollment-options'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-admin-enrollment-options.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen" data-screen="retirement-admin-testing" :data-active="currentScreen=='retirement-admin-testing'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-admin-testing.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <!-- DOCUMENTS & REPORTS -->

    <div class="screen" data-screen="retirement-documents" :data-active="currentScreen=='retirement-documents'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="50%" width="7.75%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="34.75%" top="50%" width="4.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-reports" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39%" top="50%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-forms" @click.native="$screens.onLinkClick"></screen-link>



        <screen-link left="27%" top="95%" width="30%" height="2.5%" data-tgt="retirement-documents-401k-brochure" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="108.5%" width="30%" height="2.5%" data-tgt="retirement-documents-services-agreement" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="27%" top="113%" width="30%" height="2.5%" data-tgt="retirement-documents-qualified-plan" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="27%" top="117.5%" width="30%" height="2.5%" data-tgt="retirement-documents-adoption-agreement" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-401k-brochure" :data-active="currentScreen=='retirement-documents-401k-brochure'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-401k-brochure.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="230%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-services-agreement" :data-active="currentScreen=='retirement-documents-services-agreement'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-services-agreement.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="230%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-qualified-plan" :data-active="currentScreen=='retirement-documents-qualified-plan'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-qualified-plan.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="120%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-adoption-agreement" :data-active="currentScreen=='retirement-documents-adoption-agreement'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-adoption-agreement.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="120%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen" data-screen="retirement-documents-reports" :data-active="currentScreen=='retirement-documents-reports'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-reports.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="50%" width="7.75%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="34.75%" top="50%" width="4.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-reports" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39%" top="50%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-forms" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="63%" top="70%" width="11.0%" height="4.5%" dot-class="dot-right-center" data-tgt="retirement-documents-reports-download" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-reports-download" :data-active="currentScreen=='retirement-documents-reports-download'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-reports-download.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen" data-screen="retirement-documents-forms" :data-active="currentScreen=='retirement-documents-forms'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-forms.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="50%" width="7.75%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="34.75%" top="50%" width="4.5%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-reports" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="39%" top="50%" width="4.0%" height="4.5%" dot-class="dot-top-center" data-tgt="retirement-documents-forms" @click.native="$screens.onLinkClick"></screen-link>

        <screen-link left="27%" top="72.5%" width="30%" height="2.5%" data-tgt="retirement-documents-forms-authorize-disability" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="27%" top="77%" width="30%" height="2.5%" data-tgt="retirement-documents-forms-death-benefits" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />

      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-forms-authorize-disability" :data-active="currentScreen=='retirement-documents-forms-authorize-disability'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-forms-authorize-disability.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <div class="screen z-max" data-screen="retirement-documents-forms-death-benefits" :data-active="currentScreen=='retirement-documents-forms-death-benefits'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-documents-forms-death-benefits.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="" @click.native="$screens.showPrevious"></screen-link>
      </div>
    </div>

    <!-- TOOLS -->
    <div class="screen" data-screen="retirement-tools" :data-active="currentScreen=='retirement-tools'">
      <img class="w-full max-w-100" src="/img/screens/retirement/admin/rs-tools.png?v=10122022" />
      <div class="links">

        <screen-link left="28%" top="25.25%" width="10.25%" height="4.5%" data-tgt="retirement-home" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38.75%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="50.25%" top="25.25%" width="9.5%" height="4.5%" data-tgt="retirement-admin" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="61.25%" top="25.25%" width="12%" height="4.5%" data-tgt="retirement-documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="75.25%" top="25.25%" width="10%" height="4.5%" data-tgt="retirement-tools" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-home" :active-companies="{enterprise: true, pro: true}" />



      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: "AdminRetirement",
  props: {
    currentScreen: {}
  },
  methods: {
    
  }
}
</script>

<style scoped>

</style>