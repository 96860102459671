<template>
  <div class="task-button" :style="styles" :data-mode="buttonState">
    <div class="task-button-inner">
      <span v-if="buttonState === 'submit'">Submit</span>
      <span v-if="buttonState === 'start'">Start</span>
      <span v-if="buttonState === 'revisit'">Revisit</span>
    </div>
  </div>
</template>

<script>

let tasks = [
    "getStarted",
    "priorPayroll",
    "businessInfo",
    "bankingInfo",
    "paySchedule",
    "taxInfo",
    "addEmployees",
    "documents",
    "submitInfo"
]

export default {
  name: "OnboardingTaskButton",
  props: ["styles","task"],
  computed: {
    buttonState: function(){

      let lastTaskCompleted = [...tasks].reverse().findIndex((item)=>{
          return this.$store.state.easySetup[item]?.completed
      })

      let thisTaskIndex = [...tasks].reverse().indexOf(this.task)

      if(lastTaskCompleted > -1){
        if(lastTaskCompleted < thisTaskIndex){
          return "revisit"
        }
      }

      if((lastTaskCompleted === -1) && (this.task === "getStarted")){
        return "start"
      }

      if((lastTaskCompleted - thisTaskIndex) === 1){
        if(this.task === "submitInfo"){
          return "submit"
        }else{
          return "start"
        }

      }


      if(this.$store.state.easySetup[this.task]?.completed){
        return "revisit"
      }else{
        return "lock"
      }

    }
  }
}
</script>

<style scoped>
  .task-button {
    width: 4em;
    height: 1.2em;
    padding: 0.5em;
    position: absolute;

  }
  .task-button-inner {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 0.9em;
    justify-content: center;
    align-items: center;

  }

  .task-button[data-mode="start"]{
    background-color: #0369B1;
    color: white;
    border-radius: 0.2em;
  }

  .task-button[data-mode="submit"]{
    background-color: #0369B1;
    color: white;
    border-radius: 0.2em;
  }

  .task-button[data-mode="revisit"]{
    background-color: white;
    color: #0369B1;
  }
</style>