


<template>
<div class="live-reports-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="live-reports-list">


    <div class="live-reports-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-reports-edit-labor-cost-cleveland.png?v=10122022" />
      <div class="links">
        <screen-link left="67%" top="1%" width="16%" height="5%" data-tgt="reports-analytics" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="82.5%" top="1%" width="16%" height="5%" data-tgt="reports-analytics" dot-class="dot-bottom-center" @click.native="showResult"></screen-link>


        <screen-link left="50%" top="48%" width="45%" height="7%" data-tgt="location" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll" v-if="detailPanel === 'location'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-reports-edit-labor-cost-locations-cleveland.png?v=10122022" />
      <div class="links">
        <screen-link left="4%" top="13%" width="25%" height="24%" data-tgt="select-corporate" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="64%" top="38%" width="15.5%" height="5.5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="79.5%" top="38%" width="15.5%" height="5.5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="live-reports-scroll" v-if="detailPanel === 'select-corporate'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-reports-edit-labor-cost-locations-all-choice.png?v=10122022" />
      <div class="links">
        <screen-link left="4%" top="13%" width="25%" height="24%" data-tgt="location" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="64%" top="38%" width="15.5%" height="5.5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="79.5%" top="38%" width="15.5%" height="5.5%" data-tgt="corporate" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="live-reports-scroll" v-if="detailPanel === 'corporate'">
      <img class="w-full" src="/img/screens/reports/pullouts/pullout-reports-edit-labor-cost-all.png?v=10122022" />
      <div class="links">
        <screen-link left="67.5%" top="1%" width="15%" height="5%" data-tgt="reports-analytics" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="82.5%" top="1%" width="16%" height="5%" data-tgt="reports-analytics-total-labor-corporate" dot-class="dot-bottom-center" @click.native="showResult"></screen-link>


        <screen-link left="50%" top="48%" width="45%" height="7%" data-tgt="select-corporate" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "analytics-labor-cost-edit-pullout",
  components: {ScreenLink},

  props: {
      selection: {
        type: String,
        default: ""
      }
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      showResult: function(e){
        this.$emit('link', e);
        this.$emit('close');
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.live-reports-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.live-reports-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.live-reports-pullout img {
  display: block;
}

.live-reports-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.live-reports-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.live-reports-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
