<template>
    <div class="options-panel text-black" :style="panelStyle">
      <div v-if="level === 0">
        <header class="header">
            <span>Download</span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <div class="menu-items">
          <div class="menu-item">
            <svg class="icon" data-name="Layer 1" viewBox="0 0 29.92 30" id="doc-type" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <title>doc-type</title>
              <path d="M24.91 7.49a1.34 1.34 0 0 0-.39-1L19 .48A1.55 1.55 0 0 0 17.9 0H3a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h19a3 3 0 0 0 3-3zM22 28H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14.68v5a.5.5 0 0 0 .5.5h4.71L23 27a1 1 0 0 1-1 1z" fill="#aaabae" class="bg"></path>
              <rect x="4.69" y="10.72" width="25.23" height="14" rx="1.5" fill="#6dc185" class="ext-type-bg"></rect>
              <g class="ext-xlsx" fill="#fff"><path d="M8.76 20.36H7.41l1.81-2.83-1.69-2.75h1.39l1 1.82 1-1.81h1.34l-1.72 2.68 1.79 2.89h-1.39l-1-1.91zM13 14.81h1.2v4.57h2.78v1h-3.95zM18.54 18.7a1.26 1.26 0 0 0 .21.58 1.26 1.26 0 0 0 1 .35 2.22 2.22 0 0 0 .69-.09.64.64 0 0 0 .5-.64.47.47 0 0 0-.24-.43 2.66 2.66 0 0 0-.77-.27l-.61-.14a3.81 3.81 0 0 1-1.22-.43 1.35 1.35 0 0 1-.55-1.21 1.59 1.59 0 0 1 .56-1.24 2.36 2.36 0 0 1 1.62-.49 2.57 2.57 0 0 1 1.52.48 1.66 1.66 0 0 1 .65 1.36h-1.13a.8.8 0 0 0-.44-.71 1.48 1.48 0 0 0-.7-.15 1.35 1.35 0 0 0-.74.18.57.57 0 0 0-.27.5.46.46 0 0 0 .26.45 3.29 3.29 0 0 0 .74.24l1 .23a2.55 2.55 0 0 1 1 .41 1.37 1.37 0 0 1 .49 1.15 1.56 1.56 0 0 1-.6 1.26 2.47 2.47 0 0 1-1.67.5 2.8 2.8 0 0 1-1.73-.51 1.65 1.65 0 0 1-.62-1.37zM23.71 20.46h-1.35l1.81-2.83-1.69-2.75h1.39l1 1.82 1-1.8h1.35l-1.73 2.68 1.8 2.9h-1.4l-1.05-1.91z"></path></g>
              </svg> <span>Report Details</span>
          </div>
          <div class="menu-item">
            <svg class="icon" data-name="Layer 1" viewBox="0 0 29.92 30" id="doc-type" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <title>doc-type</title>
              <path d="M24.91 7.49a1.34 1.34 0 0 0-.39-1L19 .48A1.55 1.55 0 0 0 17.9 0H3a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h19a3 3 0 0 0 3-3zM22 28H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14.68v5a.5.5 0 0 0 .5.5h4.71L23 27a1 1 0 0 1-1 1z" fill="#aaabae" class="bg"></path>
              <rect x="4.69" y="10.72" width="25.23" height="14" rx="1.5" fill="#6dc185"  class="ext-type-bg"></rect>
              <g class="ext-csv" fill="#fff"><path d="M8.3 14.64a3 3 0 0 1 2.22-.87A3 3 0 0 1 13.14 15a2.64 2.64 0 0 1 .48 1.35h-1.5a2 2 0 0 0-.36-.78 1.46 1.46 0 0 0-1.19-.48 1.46 1.46 0 0 0-1.27.65 3.3 3.3 0 0 0-.48 1.87 2.89 2.89 0 0 0 .48 1.82 1.51 1.51 0 0 0 1.24.61 1.36 1.36 0 0 0 1.18-.51 2.22 2.22 0 0 0 .38-.83h1.49a3.2 3.2 0 0 1-1 1.91 2.82 2.82 0 0 1-2 .72 3.09 3.09 0 0 1-2.42-1 4.06 4.06 0 0 1-.86-2.75 4 4 0 0 1 .99-2.94zM15.84 18.91a1.55 1.55 0 0 0 .27.74 1.63 1.63 0 0 0 1.28.46 2.67 2.67 0 0 0 .88-.12.83.83 0 0 0 .65-.83.62.62 0 0 0-.31-.55 3.61 3.61 0 0 0-1-.35l-.77-.17a4.81 4.81 0 0 1-1.58-.56 1.78 1.78 0 0 1-.72-1.53 2.1 2.1 0 0 1 .73-1.61 3 3 0 0 1 2.09-.62 3.2 3.2 0 0 1 2 .61 2.14 2.14 0 0 1 .84 1.76h-1.48a1.06 1.06 0 0 0-.58-.93 2 2 0 0 0-.89-.18 1.58 1.58 0 0 0-.95.23.73.73 0 0 0-.36.65.61.61 0 0 0 .34.58 5 5 0 0 0 1 .3l1.26.3a3.35 3.35 0 0 1 1.24.54 1.73 1.73 0 0 1 .63 1.47 2 2 0 0 1-.77 1.63 3.2 3.2 0 0 1-2.16.64 3.48 3.48 0 0 1-2.23-.66 2.1 2.1 0 0 1-.8-1.77zM25.62 14h1.56l-2.5 7.18h-1.42L20.88 14h1.6L24 19.45z"></path></g>
            </svg> <span>Report Details</span>
          </div>
          <div class="menu-item">
            <svg class="icon" data-name="Layer 1" viewBox="0 0 29.92 30" id="doc-type" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <title>doc-type</title>
              <path d="M24.91 7.49a1.34 1.34 0 0 0-.39-1L19 .48A1.55 1.55 0 0 0 17.9 0H3a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h19a3 3 0 0 0 3-3zM22 28H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14.68v5a.5.5 0 0 0 .5.5h4.71L23 27a1 1 0 0 1-1 1z" fill="#aaabae" class="bg"></path>
              <rect x="4.69" y="10.72" width="25.23" height="14" rx="1.5" fill="#ef6e5f" class="ext-type-bg"></rect>
              <g class="ext-pdf" fill="#fff"><path d="M13.05 14.63a2 2 0 0 0-.77-.53 3.19 3.19 0 0 0-1.15-.21H7.88v7.22h1.54v-2.6h1.67a3.31 3.31 0 0 0 1.16-.19 2.27 2.27 0 0 0 .77-.52 1.92 1.92 0 0 0 .44-.74 2.7 2.7 0 0 0 .14-.85 2.53 2.53 0 0 0-.13-.86 1.9 1.9 0 0 0-.42-.72zM12 16.77a.9.9 0 0 1-.31.33 1.26 1.26 0 0 1-.45.15 3 3 0 0 1-.52 0H9.44v-2.16h1.23a2.89 2.89 0 0 1 .52 0 1.27 1.27 0 0 1 .45.16.8.8 0 0 1 .3.33 1.13 1.13 0 0 1 .12.56 1.09 1.09 0 0 1-.06.63zM20.07 14.84a2.78 2.78 0 0 0-1-.67 3.66 3.66 0 0 0-1.3-.23h-3.11v7.22h3.12a3.56 3.56 0 0 0 1.44-.27 2.91 2.91 0 0 0 1-.76 3.26 3.26 0 0 0 .56-1.13 5.51 5.51 0 0 0 .22-1.47 4.63 4.63 0 0 0-.25-1.53 3.06 3.06 0 0 0-.68-1.16zm-.83 3.84a1.85 1.85 0 0 1-.41.67 1.42 1.42 0 0 1-.58.36 1.87 1.87 0 0 1-.66.11h-1.42v-4.55h1.13a2.62 2.62 0 0 1 1 .17 1.74 1.74 0 0 1 .64.49 2 2 0 0 1 .34.75 4.31 4.31 0 0 1 .1 1 3 3 0 0 1-.14 1zM27.18 15.33l.01-1.33-5.08-.03-.04 7.21 1.58.02.02-2.99 3.03.02V17l-3.02-.02.01-1.67 3.49.02z"></path></g>
              </svg> <span>Summary Charts Only</span>
          </div>
        </div>
      </div>

    </div>
</template>

<script>


    export default {
        name: "download-options-panel",
        components: {},
      mounted: function(){

      },
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
              default: ""
          }
        },
        data: function () {
            return {
              level: 0
            }
        },
        methods: {
            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e);
            this.$emit('close');
          },

          onShowPullout: function(e){
              console.log("emiting pullout",e);
            this.$emit('pullout',e);
            this.$emit('close');
          },

          setLevel: function(level){
              this.currentLevel = level;
          },




        },
        computed: {
          panelStyle: function(){
            return {
              width: this.width,
              top: this.top,
              left: this.left
            }
          },
        }
    }
</script>

<style scoped>


    .options-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }



    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

    .menu-items {
      margin-bottom: 1em;
    }

    .menu-heading {
      opacity: 0.6;
    }

    .menu-item {
      display: flex;
      align-items: center;
      padding: 1em;
    }

    .menu-item:hover {
      background-color: #f8f8f8;
    }

    .menu-item .icon {
      width: 2em;
      height: 2em;
      margin-right: 1em;
    }

    .muted {
      opacity: 0.25;
    }

    .tag {
      font-size: 0.8em;
      padding: 0.2em 0.5em;
      opacity: 0.6;
      border: 1px solid black;
      border-radius: 1em;
      margin-left: 0.5em;
    }



    .drop-down-arrow::after {
      display: block;
      content: '\25BC';
      position: relative;
      top: 2px;
      speak: none;
      font-size: 1em;
      transform: scaleY(.5) scaleX(1);
    }

    footer {
      border-top: 1px solid #d8d8d8;
    }




    .v-center {
      display: flex;
      align-items: center;
    }

</style>
