<template>
  <div class="demo-frame">
    <TabletLayout data-tgt="dashboard" data-tgt-mode="employee" @close="closeWindow">
      <template #screens>
        <div class="screen" data-screen="flock-home" :data-active="currentScreen=='flock-home'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-home.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="71%" top="9%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home-spanish" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="80%" top="9%" width="17%" height="5%" dot-class="dot-right-center" data-tgt="flock-profile-options" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


            <screen-link left="4%" top="16%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="16%" width="12%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="16%" width="22%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-docs" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="68.5%" top="47%" width="25%" height="5%" dot-class="dot-left-center" data-tgt="flock-benefits-enroll" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="flock-tasks" :data-active="currentScreen=='flock-tasks'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-tasks.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


            <screen-link left="4%" top="16%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="16%" width="12%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="16%" width="22%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-docs" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="4%" top="29.5%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="29.5%" width="13%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks-completed" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-tasks-completed" :data-active="currentScreen=='flock-tasks-completed'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-tasks-completed.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


            <screen-link left="4%" top="16%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="16%" width="12%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="16%" width="22%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-docs" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="4%" top="29.5%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="29.5%" width="13%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks-completed" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-docs" :data-active="currentScreen=='flock-docs'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-docs.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


            <screen-link left="4%" top="16%" width="11%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="16%" top="16%" width="12%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-tasks" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="16%" width="22%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-docs" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="flock-profile-options" :data-active="currentScreen=='flock-profile-options'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-profile-options.png?v=10122022" />
          <div class="links">

            <screen-link left="71.5%" top="15%" width="16%" height="4%" dot-class="dot-left-center" data-tgt="flock-profile" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-home-spanish" :data-active="currentScreen=='flock-home-spanish'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-home-spanish.png?v=10122022" />
          <div class="links">

            <screen-link left="71%" top="9%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen" data-screen="flock-profile" :data-active="currentScreen=='flock-profile'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-profile.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-people" :data-active="currentScreen=='flock-people'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-people.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-benefits" :data-active="currentScreen=='flock-benefits'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/flock-benefits.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="77%" top="78%" width="20%" height="7%" dot-class="dot-right-center" data-tgt="flock-benefits-enroll" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="flock-benefits-enroll" :data-active="currentScreen=='flock-benefits-enroll'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll.png?v=10122022" />
          <div class="links">
            <screen-link left="87%" top="2%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="42%" top="183%" width="16%" height="6%" dot-class="dot-bottom-center" data-tgt="flock-enrollment-nextyear" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        
        
        <!-- ENROLLMENT -->
        <div class="screen" data-screen="flock-enrollment-nextyear" :data-active="currentScreen=='flock-enrollment-nextyear'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="79%" top="115.5%" width="20%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dependents" :data-active="currentScreen=='flock-enrollment-nextyear-dependents'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dependents.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="79%" top="24%" width="19%" height="6.5%" data-tgt="flock-enrollment-nextyear-dependents-add" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dependents-add" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-add'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dependents-add.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="88.5%" top="4.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="7%" top="17%" width="42%" height="5.5%" data-tgt="flock-enrollment-nextyear-dependents-add-details" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dependents-add-details" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-add-details'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dependents-add-details.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="88.5%" top="4.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="81%" top="97%" width="12%" height="7%" data-tgt="flock-enrollment-nextyear-dependents-added" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dependents-added" :data-active="currentScreen=='flock-enrollment-nextyear-dependents-added'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dependents-added.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="79%" top="61%" width="20%" height="5.5%" data-tgt="flock-enrollment-nextyear-medical" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

            <screen-link dot-class="dot-left-center" left="1%" top="61%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-medical" :data-active="currentScreen=='flock-enrollment-nextyear-medical'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-medical.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="2%" top="60%" width="47%" height="8%" data-tgt="flock-enrollment-nextyear-medical-wdep"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-medical-wdep" :data-active="currentScreen=='flock-enrollment-nextyear-medical-wdep'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-medical-wdep.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="2%" top="60%" width="47%" height="8%" data-tgt="flock-enrollment-nextyear-medical"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="78%" top="69%" width="21%" height="6%" data-tgt="flock-enrollment-nextyear-medical-compare" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="79%" top="276%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-dental" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-medical-compare.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="89%" top="23%" width="4%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="8%" top="30%" width="84%" height="10%" data-tgt="flock-enrollment-nextyear-medical-compare-choices"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare-choices" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare-choices'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-medical-compare-choices.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="89%" top="23%" width="4%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="74%" top="81%" width="19%" height="5.5%" data-tgt="flock-enrollment-nextyear-medical-compare-table"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-medical-compare-table" :data-active="currentScreen=='flock-enrollment-nextyear-medical-compare-table'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-medical-compare-table.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-left-center" left="95%" top="1%" width="4%" height="4%" data-tgt="flock-enrollment-nextyear-medical-wdep" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dental" :data-active="currentScreen=='flock-enrollment-nextyear-dental'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dental.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-left-center" left="2%" top="60%" width="47%" height="8%" data-tgt="flock-enrollment-nextyear-dental-wdep"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

            <screen-link dot-class="dot-left-center" left="1%" top="181%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-medical-wdep" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />




          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dental-wdep" :data-active="currentScreen=='flock-enrollment-nextyear-dental-wdep'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dental-wdep.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>


            <screen-link dot-class="dot-left-center" left="1%" top="123%" width="96%" height="45%" data-tgt="flock-enrollment-nextyear-dental-selected" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

            <screen-link dot-class="dot-left-center" left="1%" top="181%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-medical-selected" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="79%" top="181%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-medical-compare" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-dental-selected" :data-active="currentScreen=='flock-enrollment-nextyear-dental-selected'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-dental-selected.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="181%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-medical-wdep" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="79%" top="181%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-vision" :data-active="currentScreen=='flock-enrollment-nextyear-vision'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-vision.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="79%" top="156%" width="18%" height="6%" data-tgt="flock-enrollment-nextyear-vision-waive" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="1%" top="166.5%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-dental-selected" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />


          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-vision-waive.png?v=10122022" />
          <div class="links">

            <screen-link dot-class="dot-right-center" left="89%" top="4%" width="4%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="30%" top="18%" width="30%" height="6%" data-tgt="flock-enrollment-nextyear-vision-waive-options" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="81%" top="30%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive-options" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive-options'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-vision-waive-options.png?v=10122022" />
          <div class="links">

            <screen-link dot-class="dot-right-center" left="89%" top="4%" width="4%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="31%" top="41.5%" width="18%" height="3.5%" data-tgt="flock-enrollment-nextyear-vision-waive-not-interested" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="81%" top="30%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-vision-waive-not-interested" :data-active="currentScreen=='flock-enrollment-nextyear-vision-waive-not-interested'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-vision-waive-not-interested.png?v=10122022" />
          <div class="links">

            <screen-link dot-class="dot-right-center" left="89%" top="4%" width="4%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

            <screen-link dot-class="dot-right-center" left="81%" top="30%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-fsa" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-fsa" :data-active="currentScreen=='flock-enrollment-nextyear-fsa'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-fsa.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-right-center" left="87%" top="55%" width="10%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-fsa-dependent" :data-active="currentScreen=='flock-enrollment-nextyear-fsa-dependent'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-fsa-dependent.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="155%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-vision" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="66%" top="144.5%" width="31%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent-waive" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-fsa-dependent-waive" :data-active="currentScreen=='flock-enrollment-nextyear-fsa-dependent-waive'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-fsa-dependent-waive.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="89%" top="4%" width="4%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

            <screen-link dot-class="dot-right-center" left="81.5%" top="20.5%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-hsa" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-hsa" :data-active="currentScreen=='flock-enrollment-nextyear-hsa'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-hsa.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-right-center" left="80%" top="69.5%" width="12%" height="5%" data-tgt="flock-enrollment-nextyear-hsa-1000"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="1%" top="132%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />


          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-hsa-1000" :data-active="currentScreen=='flock-enrollment-nextyear-hsa-1000'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-hsa-1000.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="79%" top="132%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-life" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="1%" top="132%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-fsa-dependent" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-life" :data-active="currentScreen=='flock-enrollment-nextyear-life'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-life.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-right-center" left="1%" top="52%" width="98%" height="76%" data-tgt="flock-enrollment-nextyear-life-selected"  data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="1%" top="140%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-hsa-1000" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-life-selected" :data-active="currentScreen=='flock-enrollment-nextyear-life-selected'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-life-selected.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="140%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-hsa-1000" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="79%" top="140%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-disability" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-disability" :data-active="currentScreen=='flock-enrollment-nextyear-disability'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-disability.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="132%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-life-selected" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="63%" top="121.5%" width="34%" height="6%" data-tgt="flock-enrollment-nextyear-disability-waived" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-disability-waived" :data-active="currentScreen=='flock-enrollment-nextyear-disability-waived'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-disability-waived.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-left-center" left="69%" top="21%" width="12%" height="5%" data-tgt="flock-enrollment-nextyear-disability" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="82%" top="21%" width="11%" height="5%" data-tgt="flock-enrollment-nextyear-legal" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-legal" :data-active="currentScreen=='flock-enrollment-nextyear-legal'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-legal.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="77%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-disability" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="79%" top="77%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-beneficiary" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-beneficiary" :data-active="currentScreen=='flock-enrollment-nextyear-beneficiary'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-beneficiary.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="170%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-legal" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="79%" top="170%" width="20%" height="6%" data-tgt="flock-enrollment-nextyear-review" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-review" :data-active="currentScreen=='flock-enrollment-nextyear-review'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-review.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="1%" top="453.5%" width="12%" height="6%" data-tgt="flock-enrollment-nextyear-beneficiary" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-left-center" left="77%" top="453.5%" width="22%" height="6%" data-tgt="flock-enrollment-nextyear-success" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-enrollment-nextyear-success" :data-active="currentScreen=='flock-enrollment-nextyear-success'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-enroll-next-year-success.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="90.5%" top="2.5%" width="4%" height="4%" data-tgt="flock-benefits-summary" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link dot-class="dot-left-center" left="41%" top="41%" width="20%" height="6%" data-tgt="flock-home" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />
            <screen-link dot-class="dot-right-center" left="36%" top="30%" width="30%" height="6%" data-tgt="flock-benefits-summary" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick" />

          </div>
        </div>

        <div class="screen" data-screen="flock-benefits-summary" :data-active="currentScreen=='flock-benefits-summary'">
          <img class="w-full max-w-100" src="/img/screens/employee/flock/enrollment/flock-benefits-summary.png?v=10122022" />
          <div class="links">
            <screen-link left="30%" top="1%" width="8%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-home" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="39%" top="1%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-people" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="49.5%" top="1%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="flock-benefits" data-tgt-scrolltop="true" data-tgt-mode="employeeflock" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>


      </template>
    </TabletLayout>
  </div>
</template>

<script>
import TabletLayout from "@/views/TabletLayout";
export default {
  name: "EmployeeFlockExperience",
  components: {TabletLayout},
  computed: {
    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    }
  },
  methods: {
    closeWindow: function(){
      window.close();
    }
  }
}
</script>

<style scoped>

</style>