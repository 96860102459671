


<template>
<div class="access-role-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="access-role-list">


    <div class="access-role-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-suzy.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="6%" top="34%" width="90%" height="5%" data-tgt="suzy-role" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="access-role-scroll" v-if="detailPanel === 'suzy-role'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-suzy-role.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="2%" top="1%" width="20%" height="4%" data-tgt="" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="access-role-scroll" v-if="detailPanel === 'mike'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-mike.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="6%" top="34%" width="90%" height="5%" data-tgt="mike-role" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="access-role-scroll" v-if="detailPanel === 'mike-role'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-mike-role.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="2%" top="1%" width="20%" height="4%" data-tgt="mike" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
        <screen-link left="72%" top="77.5%" width="18%" height="6%" data-tgt="mike-role-edit" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="access-role-scroll" v-if="detailPanel === 'mike-role-edit'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-mike-role-edit.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="64.5%" top="28%" width="14%" height="4.5%" data-tgt="mike" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        <screen-link left="79.5%" top="28%" width="14%" height="4.5%" data-tgt="mike-role-warning" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="access-role-scroll" v-if="detailPanel === 'mike-role-warning'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-mike-role-edit.png?v=10122022" />


      <div class="links">
        <div class="warning-bkg">
          <img class="warning-popup" style="left: 10%; top: 20%;" src="/img/screens/pullout-admin-user-access-mike-role-warning.png?v=10122022" />
        </div>
        <screen-link left="78%" top="22%" width="5%" height="4%" data-tgt="mike-role-edit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        <screen-link left="53%" top="47.5%" width="14%" height="4.5%" data-tgt="mike-role-edit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        <screen-link left="68%" top="47.5%" width="14%" height="4.5%" data-tgt="mike-role-changed" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="access-role-scroll" v-if="detailPanel === 'mike-role-changed'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-mike-role-changed.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="access-changed" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>
      </div>
    </div>



  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "access-role-pullout",
  components: {ScreenLink},

  props: {
      user:{
        type: String,
        default: ""
      }
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {

      if(this.user){
        this.detailPanel = this.user
      }

      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.access-role-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.access-role-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.access-role-pullout img {
  display: block;
}


.access-role-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.access-role-scroll {
  position: relative;

  overflow-y: scroll;
}

.warning-bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: rgba(0,0,0,0.2);

}

.warning-popup {
  position: absolute;
  width: calc(100% * (450 / 600));
}

.w-full {
  width: 100%;
}

.links {

}

</style>
