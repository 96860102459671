<template>
  <div>
    <div class="screen" data-screen="help-center" :data-active="currentScreen=='help-center'">
      <img class="w-full max-w-100" src="/img/screens/employee-help-center.png?v=10122022" />
      <div class="links">

        <screen-link left="17%" top="36.5%" width="25%" height="11%"  dot-class="dot-right-center" data-tgt="help-center-covid" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="17%" top="61.5%" width="25%" height="11%"  dot-class="dot-right-center" data-tgt="help-center-get-started" @click.native="$screens.onLinkClick"></screen-link>
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>It is important that our clients have direct and quick access to help when and where they need it. Our help options give instructions on popular topics, quick access to resources that are current, like the COVID-19 resource center, and of course the ability to search and chat in-app for more complex situations. </p>
        </info-box>

      </div>
    </div>

    <div class="screen" data-screen="help-center-covid" :data-active="currentScreen=='help-center-covid'">
      <img class="w-full max-w-100" src="/img/screens/admin-help-center-covid.png?v=10122022" />
      <div class="links">

        <screen-link left="15%" top="23%" width="8%" height="3%"  dot-class="dot-right-center" data-tgt="help-center" @click.native="$screens.onLinkClick"></screen-link>


      </div>
    </div>

    <div class="screen" data-screen="help-center-get-started" :data-active="currentScreen=='help-center-get-started'">
      <img class="w-full max-w-100" src="/img/screens/admin-help-center-getting-started.png?v=10122022" />
      <div class="links">

        <screen-link left="15%" top="23%" width="8%" height="3%"  dot-class="dot-right-center" data-tgt="help-center" @click.native="$screens.onLinkClick"></screen-link>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHelpCenter",
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>