


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='add-document'" >
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="5.5%" top="30%" width="90%" height="15%" dot-class="dot-top-center" data-tgt="document-upload-file"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="5.2%" top="112%" width="90%" height="5%" dot-class="dot-left-center" data-tgt="document-upload-file-require-sign"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file-require-sign'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file-require-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="5.2%" top="131%" width="90%" height="5%" dot-class="dot-left-center" data-tgt="document-upload-file-add-group"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file-add-group'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file-groups.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="8%" top="135.7%" width="50%" height="4%" dot-class="dot-left-center" data-tgt="document-upload-file-add-group-all"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file-add-group-all'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file-groups-all.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="42%" top="151.5%" width="16%" height="5%" dot-class="dot-left-center" data-tgt="document-upload-file-add-group-added"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file-add-group-added'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file-groups-added.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="150.5%" width="11%" height="5%" dot-class="dot-left-center" data-tgt="document-upload-file-onboarding"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel === 'document-upload-file-onboarding'">
          <img class="w-full" src="/img/screens/documents/pullouts/pullouts-documents-add-upload-file-onboarding.png?v=09142023" />
          <div class="links">
            <screen-link left="89%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="79%" top="9%" width="15%" height="5%" dot-class="dot-left-center" data-tgt="document-upload-file-add-group"  @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='save-form'" >
          <img class="w-full" src="/img/screens/documents/pullouts/documents-add-form-save.png?v=09142023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="4.5%" top="82.5%" width="20%" height="4%" dot-class="dot-left-center" data-tgt="save-form-groups"  @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='save-form-groups'" >
          <img class="w-full" src="/img/screens/documents/pullouts/documents-add-form-save-groups.png?v=09142023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="6%" top="86.5%" width="50%" height="4%" dot-class="dot-left-center" data-tgt="save-form-groups-all"  @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='save-form-groups-all'" >
          <img class="w-full" src="/img/screens/documents/pullouts/documents-add-form-save-groups-all.png?v=09142023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="41%" top="102%" width="16%" height="6%" dot-class="dot-left-center" data-tgt="save-form-groups-added"  @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='save-form-groups-added'" >
          <img class="w-full" src="/img/screens/documents/pullouts/documents-add-form-save-groups-added.png?v=09142023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="102%" width="10%" height="4%" dot-class="dot-left-center" data-tgt="save-form-onboarding"  @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='save-form-onboarding'" >
          <img class="w-full" src="/img/screens/documents/pullouts/documents-add-form-save-groups-onboarding.png?v=09142023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="1%" width="14.5%" height="5%" dot-class="dot-bottom-center" data-tgt="documents"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "documents-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "add-document",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        if(e.target.dataset.tgtScrolltop){
          this.$refs['basicScroll'].scrollTo(0,0);
        }



      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
