


<template>
  <div class="login">

    <div class="login-banner">

      <img id="payx-logo" alt="paychex logo" src="/paychex-flex-logo.svg">
    </div>

    <div class="center-col">
      <div>
        <div class="login-panel" v-if="account">
          <div class="launch-demo" v-if="!$store.state.showFlexLogin">
            <div>
              <p>Logged in as <span @click="logout">{{ account.name }}</span></p>
              <div class="launch-btn" @click="beginDemo">Launch Demo</div>
            </div>

          </div>
          <div v-else>
            <form class="username-form" v-if="stage == 'username'">

              <div class="row">
                <div>
                  <LoginInput :value="username" @input="username = $event" id="username" placeholder="Username"/>
                </div>
                <div>
                  <LoginInput :value="password" input-type="password" @input="password = $event" id="password" placeholder="Password"/>
                </div>

                <p class="warning" :class="`${warningAnimation}`" style="font-size:12px;">
                  Invalid Username or Password. Too many failed attempts will lock your account.</p>


                <input class="submit-btn" type="button" value="Sign In" @click.prevent="submitPassword" />
              </div>
              <p class="creds">Forgot Username or Password?</p>
              <p class="sign-up">New to Paychex Flex? <span class="signup-link">Sign Up</span></p>


            </form>

            <footer class="footer" >






              <span id="security" data-payxautoid="payx.login.footers.security">Security</span>
              <span id="privacy" data-payxautoid="payx.login.footers.privacy">Privacy Policy</span>
              <span id="termsFooter" data-payxautoid="payx.login.footers.terms">Terms of Use</span>
              <span id="trademarks" data-payxautoid="payx.login.footers.trademarks">Trademarks</span>
              <span id="copyright" data-payxautoid="payx.login.footers.copyright">Copyright © 2023 by Paychex,Inc.</span>

            </footer>

            <p class="version-flex" @click="logout">FLEX-{{ $store.state.version }}</p>

          </div>




        </div>
        <div v-else>
          <h2 class="form-header">{{ authMsg }}</h2>
          <div class="processing" >
            <div class="auth-btn" v-if="!authorizing" @click="signIn">Request Authorization</div>
            <svg v-if="authorizing" width="135" height="135" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg" fill="#fff">
              <path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 67 67"
                    to="-360 67 67"
                    dur="2.5s"
                    repeatCount="indefinite"/>
              </path>
              <path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 67 67"
                    to="360 67 67"
                    dur="8s"
                    repeatCount="indefinite"/>
              </path>
            </svg>

          </div>
        </div>
        <p class="version">FLEX-{{ $store.state.version }}</p>
      </div>


    </div>


  </div>
</template>

<script>

import { InteractionRequiredAuthError } from "@azure/msal-browser"
import LoginInput from "@/components/login/LoginInput";


export default {
  name: "login",
  components: {LoginInput},
  props: {},
  data: function(){
    return {
      stage: "username",
      username: "",
      password: "",
      warning: "",
      warningAnimation: "",
      authorizing: false,
    }
  },
  mounted: function(){
    if(this.$store.state.prodMode){
      this.$gtag.pageview('/login')
    }
  },
  methods: {

    logout:function(){
      if(confirm('Logout of SSO account?')){
        this.$msalInstance.logout();
        window.localStorage.setItem('lastLogin', '')
      }
    },

    beginDemo: function(){
      if(this.$store.state.account){
        this.$store.commit("login", true);
        this.$router.push("/");
      }
    },
    signIn: async function(){
      this.authorizing = true;
      if(this.$store.state.prodMode) {
        this.$gtag.pageview('/sso-popup');
      }
      await this.$msalInstance
          .loginPopup({})
          .then(() => {
            const myAccounts = this.$msalInstance.getAllAccounts();
            this.$store.state.account = myAccounts[0];

            const accessTokenRequest = {
              scopes: ["user.read"],
              account: myAccounts[0]
            }

            this.$msalInstance.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse){
              let accessToken = accessTokenResponse.accessToken;
              console.log(accessToken)
            }).catch((error)=>{
              if (error instanceof InteractionRequiredAuthError) {
                console.log("Interaction required...");
                  this.$msalInstance.acquireTokenPopup(accessTokenRequest).then(function(accessTokenResponse) {
                  // Acquire token interactive success
                  let accessToken = accessTokenResponse.accessToken;
                  // Call your API with token
                    console.log(accessToken)
                }).catch(function(error) {
                  // Acquire token interactive failure
                  console.log(error);
                });
              }
              console.log(error);

            })



            if(this.$store.state.prodMode) {
              this.$gtag.pageview('/sso-success');
            }
            //this.loginMsg = "Account found!";
            this.authorizing = false;
          })
          .catch(error => {
            //this.loginMsg = "Login error!";
            console.error(`error during authentication: ${error}`);
            if(this.$store.state.prodMode) {
              this.$gtag.pageview('/sso-failed');
            }
            //alert("Error Authenticating!"+error)
            this.authorizing = false;
          });
    },
    signOut: async function(){
      await this.$msalInstance
          .logoutPopup({})
          .then((e) => {
            console.log(e);
            this.$store.state.account = null;
            //this.$emit('logout', 'logging out');
            this.authorizing = false;
          })
          .catch(error => {
            console.error(error);
            alert("Error Signing Out!"+error)
            this.authorizing = false;
          });
    },
    submitUsername: function(){
      if(this.username && (this.$store.state.sso || this.username.endsWith("@paychex.com"))){
        this.stage = "password";
        this.warning = ""
      }else{
        this.warning = "Please use a valid username."
      }
    },
    submitPassword: function(){

      if(this.username && (this.$store.state.sso || this.username.endsWith("@paychex.com"))){
        if(this.$store.state.sso || this.password == this.$store.state.accessid){
          this.$store.commit("login", true);
          this.$store.commit("setFlexUsername", this.username);
          if(!this.$store.state.sso){
            this.$store.state.account = {
              name: this.username.split("@")[0],
              username: this.username
            }
          }
          this.$router.push("/");
        }else{
          this.warning = "Password is incorrect."
          this.bounceWarning();
        }
      }else{
        this.warning = "Please use a valid username."
        this.bounceWarning();
      }

    },

    bounceWarning: function(){
      if(this.warningAnimation){
        this.warningAnimation = (this.warningAnimation === 'bounce')?'bounce1':'bounce'
      }else{
        this.warningAnimation = 'bounce'
      }
    },
    cancel: function(){
      this.warning = "";
      this.stage = "username";
    }
  },
  computed: {
    account: function(){
      return this.$store.state.account;
    },
    loginMsg: function(){
      if(!this.account){
        return "No Account Yet";
      }
      return "Account found!";
    },
    authMsg: function(){
      if(this.authorizing){
        return "Authorizing Demo Access..."
      }
      return "Authorized Paychex Users Only."
    }
  }
}
</script>


<style scoped>
.qst0{fill:#373e4d}
.form-header {
  color: #0369b1;
  margin-bottom: 20px;
  margin-top: 80px;
  font-size: 24px;
}
.warning {
  font-size: 12px;
  color: rgba(0,0,0,0.38);
  margin-top: 8px;
  margin-bottom: 10px;
}
.login {
  background-color: #f3f7fa;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}
.login-banner {
  background-color: #0369B1;
  height: 200px;
  margin-bottom: -30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
#payx-logo {
  margin: 0;
}
.submit-btn {
  background-color: #0369B1;
  color: white;
  height: 34px;
  padding: 0.1em 1em;
  border-radius: 3px;
  font-size: 14px;
  min-width: 6em;
  width: 100%;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  margin: 0;
  margin-bottom: 30px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.submit-btn:hover {
  background-color: #0056a0
}

.submit-btn:active {
  background-color: #0056a0;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

}

.cancel-btn {
  background-color: #9b9b9b;
  color: white;
  height: 34px;
  padding: 0.1em 1em;
  border-radius: 3px;
  font-size: 13px;
  min-width: 6em;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  margin: 0;
  margin-right: 0.5em;
}
label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 0.5em;
}
.input {
  width: 100%;
  height: 34px;
  max-width: 12em;
  padding: 0.1em 1em;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-right: 1em;
}
.creds {
  color: #0369B1;
  font-size: 14px;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d8d8d8;
  text-align: center;
  margin-bottom: 0;
}

.sign-up {
  margin: 0;
  padding: 20px 0;
  text-align: center;
  color: #9b9b9b;

}

.sign-up span {
  text-decoration: underline;
  color: #0369b1;
}


b {
  margin: 0 0.5em;
}
.login-panel {
  box-sizing: border-box;
}
.center-col {
  display: flex;
  justify-content: center;
}
.launch-demo {
  background-color: white;
  width: 340px;
  font-size: 14px;
  padding: 4px 30px 25px 30px;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
}
.msg {
  padding: 1em;
  background: white;
  margin: 1em 0;
}
.processing {
  text-align: center;
}
.auth-btn {
  background-color: #004b8d;
  color: white;
  height: 34px;
  padding: 0.1em 1em;
  border-radius: 3px;
  font-size: 13px;
  min-width: 6em;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.auth-btn {
  background-color: #004b8d;
  color: white;
  height: 34px;
  padding: 0.1em 1em;
  border-radius: 3px;
  font-size: 13px;
  min-width: 6em;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.launch-btn {
  background-color: #0369B1;
  color: white;
  padding: 1em 0em;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

}

.version {
  text-align: center;
  opacity: 0.5;
  font-size: 0.8em;
}

.version-flex {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1em;
  text-align: right;
  opacity: 0.2;
  font-size: 13px;
  margin-bottom: 30px;
}


.signOut {
  cursor: pointer;
}
.signOut:hover {
  text-decoration: underline;
}

.username-form {
  background-color: white;
  width: 340px;
  font-size: 14px;
  padding: 4px 30px 25px 30px;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  font-size: 13px;
  color: #0369B1;
}

.footer span {
  margin-left: 25px;
}

#copyright {
  color: #9b9b9b;
}

.footer span:first-child {
  margin-left: 0;
}

.invisible {
  opacity: 0;
}

@keyframes bounceAnim1 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  0% {
    transform: translateY(0px);
  }
}

@keyframes bounceAnim2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  0% {
    transform: translateY(0px);
  }
}

.warning.bounce {
  animation-name: bounceAnim1;
  animation-duration: 0.3s;
}

.warning.bounce1 {
  animation-name: bounceAnim2;
  animation-duration: 0.3s;
}

@media screen and (max-height: 656px) {
  .footer {
        display: none;
    }
}

</style>
