<template>
  <div>

    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="dashboard" :data-active="currentScreen=='dashboard'">
      <img class="w-full max-w-100" src="/img/screens/dashboard/admin-dashboard-pro.png?v=05072024" />
      <div class="links">
        <screen-link left="93%" top="14%" width="6%" height="4.5%" dot-class="dot-top-center" data-tgt="pullout-manage-tiles" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="39.5%" top="57%" width="6.5%" height="5%" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28.8%" top="28.25%" width="7%" height="8%" dot-class="dot-bottom-center" data-init-info="sets"  data-tgt="pullout-quick-reports" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="38.2%" top="28.25%" width="7%" height="8%" dot-class="dot-bottom-center" data-tgt="pullout-live-reports" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="96%" top="93.2%" width="4%" height="5%" dot-class="dot-left-center" data-tgt-scrolltop="true"  data-tgt="help" @click.native="$screens.showHelp"></screen-link>

        <screen-link left="28%" top="66.5%" width="17%" height="5%" dot-class="dot-left-center" data-init-info="timing-options" data-tgt="pullout-payroll-center-options" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="27.3%" top="91.5%" width="19%" height="11%" dot-class="dot-right-center" data-tgt-scrolltop="true"  data-tgt="pullout-tools" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="27.5%" top="80%" width="19%" height="10%"  dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <screen-link left="72.5%" top="40.5%" width="10%" height="4%"  dot-class="dot-right-center" data-tgt="/#/engage/dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="defaultScreen" :active-companies="activeCompanies"/>
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>The main dashboard was designed to give clients access to the most frequently accessed information at their fingertips. Users can edit their personal dashboard by adding and removing modules that fit their needs. They can review tasks, see cash requirements and have access to tools and resources. The dashboard offers quick access to employee handbook builder, where clients can create, edit, view, print their documentation.</p>
        </info-box>

<!--        <payroll-item-details-panel style="display:none;position:absolute;top: 72.3%;left: 27.4%;width: 18.9%;"></payroll-item-details-panel>-->

<!--        <payroll-labor-cost-panel style="display:none;position:absolute;top: 84%;left: 67.9%;width: 18.9%;"></payroll-labor-cost-panel>-->


      </div>
    </div>


    <div v-else class="screen" data-screen="dashboard" :data-active="currentScreen=='dashboard'">
      <img class="w-full max-w-100" src="/img/screens/dashboard/admin-dashboard-enterprise.png?v=05072024" />
      <div class="links">
        <screen-link left="93%" top="14%" width="6%" height="4.5%" dot-class="dot-top-center" data-tgt="pullout-manage-tiles" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="39.5%" top="38.5%" width="6.5%" height="5%" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="72.5%" top="117.5%" width="9.5%" height="4.5%" dot-class="dot-top-center" data-tgt="dashboard-all-tasks" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="58.5%" top="28.25%" width="7%" height="8%" dot-class="dot-bottom-center" data-tgt="pullout-live-reports" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="49%" top="28.25%" width="7%" height="8%" dot-class="dot-bottom-center" data-init-info="sets" data-tgt="pullout-quick-reports" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="96%" top="123.75%" width="4%" height="5%" dot-class="dot-left-center" data-tgt-scrolltop="true"  data-tgt="help" @click.native="$screens.showHelp"></screen-link>

        <screen-link left="28%" top="48%" width="17%" height="5%" dot-class="dot-left-center" data-init-info="timing-options" data-tgt="pullout-payroll-center-options" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="27.5%" top="61.75%" width="19.25%" height="10%"  dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <screen-link left="68%" top="124.25%" width="19%" height="11%" dot-class="dot-right-center" data-tgt-scrolltop="true"  data-tgt="pullout-tools" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="72.5%" top="40.5%" width="10%" height="4%"  dot-class="dot-right-center" data-tgt="/#/engage/dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="defaultScreen"/>





        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>The main dashboard was designed to give clients access to the most frequently accessed information at their fingertips. Users can edit their personal dashboard by adding and removing modules that fit their needs. They can review tasks, see cash requirements and have access to tools and resources. The dashboard offers quick access to employee handbook builder, where clients can create, edit, view, print their documentation.</p>
        </info-box>

        <!--        <payroll-item-details-panel style="display:none;position:absolute;top: 72.3%;left: 27.4%;width: 18.9%;"></payroll-item-details-panel>-->

        <!--        <payroll-labor-cost-panel style="display:none;position:absolute;top: 84%;left: 67.9%;width: 18.9%;"></payroll-labor-cost-panel>-->


      </div>
    </div>

    <div class="screen" data-screen="dashboard-all-tasks" :data-active="currentScreen=='dashboard-all-tasks'">
      <img class="w-full max-w-100" src="/img/screens/dashboard/admin-all-tasks.png?v=04162024" />
      <div class="links">
        <screen-link left="15%" top="14%" width="8%" height="4%" dot-class="dot-right-center" data-tgt="dashboard" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="29%" top="47.5%" width="56.8%" height="6%" dot-class="dot-right-center" data-tgt="hr-review-issues" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="29%" top="63%" width="56.8%" height="6%" dot-class="dot-right-center" data-tgt="time-attendance-tasks" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="29%" top="40%" width="56.8%" height="6%" dot-class="dot-right-center" data-tgt="dashboard-i9-tasks" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="defaultScreen" :active-companies="activeCompanies"/>
      </div>
    </div>

    <div class="screen" data-screen="dashboard-i9-tasks" :data-active="currentScreen=='dashboard-i9-tasks'">
      <img class="w-full max-w-100" src="/img/screens/dashboard/admin-tasks-i9-employees.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="14%" width="8%" height="4%" dot-class="dot-right-center" data-tgt="dashboard-all-tasks" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="29%" top="50%" width="56.8%" height="6%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="i9" @click.native="$screens.showPullout"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="defaultScreen" :active-companies="activeCompanies"/>
      </div>
    </div>

  </div>
</template>

<script>



// import PayrollItemDetailsPanel from "@/components/PayItemDetailsPanel";
// import PayrollLaborCostPanel from "@/components/PayrollLaborCostPanel";

import CompanyTab from "@/components/CompanyTab";

export default {
  name: "AdminDashboard",
  components: {CompanyTab},
  props: {
    currentScreen: {
    }
  },
  data: function(){
    return {
      defaultScreen: "dashboard",
      activeCompanies: {
        enterprise: true,
        pro: true
      }
    }
  }
}
</script>

<style scoped>

</style>