


<template>
<div class="tools-pullout pullout-panel">
  <div class="bkg"></div>

  <div class="help-list">


    <div class="help-scroll">

      <div v-if="detailPanel === 'pay-ranges'">
        <img class="w-full" src="/img/screens/company/pullouts/pullout-pay-ranges.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="14.8%" width="95%" height="6%" data-tgt="pay-ranges-hourly-fulfillment" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div v-if="detailPanel === 'pay-ranges-hourly-fulfillment'">
        <img class="w-full" src="/img/screens/company/pullouts/pullout-pay-ranges-hourly-fulfillment.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="80%" top="17.5%" width="14%" height="4.5%" data-tgt="pay-ranges-hourly-fulfillment-edit" dot-class="dot-top-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div v-if="detailPanel === 'pay-ranges-hourly-fulfillment-edit'">
        <img class="w-full" src="/img/screens/company/pullouts/pullout-pay-ranges-hourly-fulfillment-edit.png?v=10122022" />
        <div class="links">
          <screen-link left="68%" top="2%" width="14%" height="4%" data-tgt="pay-ranges-hourly-fulfillment" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div v-if="detailPanel === 'fulfillment-associate'">
        <img class="w-full" src="/img/screens/company/pullouts/pullout-fulfillment-associate.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="75%" top="13%" width="13.5%" height="4.5%" data-tgt="fulfillment-associate-edit" dot-class="dot-top-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div v-if="detailPanel === 'fulfillment-associate-edit'">
        <img class="w-full" src="/img/screens/company/pullouts/pullout-fulfillment-associate-edit.png?v=10122022" />
        <div class="links">
          <screen-link left="68%" top="1.5%" width="14%" height="4%" data-tgt="fulfillment-associate" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
        </div>
      </div>

    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "company-details-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },
      showExternalPage: function(e){
        this.$emit("external", e)
      }

    },
    computed: {
        companyName: function(){


          if(this.$store.state.company === 'pro'){
            return "Polarson Inc Sales"
          }else{
            return "Acme Inc Sample"
          }
        }
    }
}
</script>

<style scoped>


.tools-pullout {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;


}

.tools-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.tools-pullout img {
  display: block;
}

.tools-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.help-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  top: calc((60/920) * 100%);
  bottom: 0;

  right: 0;
  display: flex;
  flex-direction: column;

}



.help-scroll {
  position: relative;

  overflow-y: scroll;
  height: 100%;
}

.help-scroll .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.w-full {
  width: 100%;
}

.links {

}

.company-name {
  position: absolute;
  font-size: 14px;
  color: black;
  width: 80%;
 background: white;
  padding: 0.2em;
}

</style>
