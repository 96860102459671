<template>
    <div class="filters-panel text-black" :style="panelStyle">
      <div v-if="currentLevel === 0">
        <header class="header">
            <span>Select Report Date Range</span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <div class="date-select">
          <div><small>Show</small></div>
          <div class="range-text"><span>Select dynamic dates</span><span class="drop-down-arrow"></span></div>
        </div>
        <ul class="selections">
          <li class="selected">
            <span>Preset Ranges</span>
            <span class="text-primary"><img class="icon chevron-icon" src="../assets/chevron-right-icon.svg" /></span>
          </li>
          <li>
            <span>Custom date range</span>
            <span class="text-primary"><img class="icon chevron-icon" src="../assets/chevron-right-icon.svg" /></span>
          </li>
        </ul>

        <screen-link left="0%" top="59%" width="100%" height="18%" dot-class="dot-right-center" data-tgt="pullout-labor-cost-threshold" @click.native="setLevel(1)"></screen-link>
      </div>
      <div v-if="currentLevel === 1">
        <header class="header">
          <span @click="setLevel(0)"><svg class="back-icon" viewBox="0 0 7 18" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path fill="#404040" d="M5.208 14.82c.224.24.58.24.8 0l.795-.859a.63.63 0 0 0 0-.857L2.957 9.017l3.876-4.121a.635.635 0 0 0 0-.859l-.795-.86a.542.542 0 0 0-.801 0L.165 8.586a.647.647 0 0 0 0 .86l5.043 5.374z"></path></svg></span>
          <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <ul class="selections">
          <li>
            <span>Previous 12 Months</span>
          </li>
          <li>
            <span>Most Recent Check Date</span>
          </li>
          <li :class="(selection==='30days')?'selected':''">
            <span>Current Month</span>
          </li>
          <li>
            <span>Current Quarter</span>
          </li>
          <li :class="(selection==='12months')?'selected':''">
            <span>Current Year</span>
          </li>
          <li>
            <span>Previous Month</span>
          </li>

          <li>
            <span>Previous Quarter</span>
          </li>
          <li>
            <span>Previous Year</span>
          </li>
        </ul>

        <screen-link left="0%" top="35%" width="100%" height="11%" dot-class="dot-right-center" data-tgt="reports-pay-discrepancy" @click.native="onLinkClick"></screen-link>


        <screen-link left="0%" top="56%" width="100%" height="11%" dot-class="dot-right-center" data-tgt="reports-pay-discrepancy-year" @click.native="onLinkClick"></screen-link>
      </div>
    </div>
</template>

<script>

    import ScreenLink from "@/components/ScreenLink";
    export default {
        name: "report-pay-discrepancy-date-range-panel",
        components: {ScreenLink},
      mounted: function(){

      },
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
              default: "30days"
          }
        },
        data: function () {
            return {
                isInactive: true,
                hasChanges: false,
                title: "Filters",
                currentLevel: 0,
                currentCategory: 0,
                selections: [
                        { title: "Compare Dates...", selection: "All", selections: [

                            ]
                        },
                        { title: "Group By", selection: "All", selections: [
                                { title: "Check Date", selected: true },
                            ]
                        },
                  { title: "Set Threshold...", selection: "All", selections: [

                    ]
                  },
                  { title: "Maximize", selection: "All", selections: [

                    ]
                  },

                    ]



            }
        },
        methods: {
            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e);
            this.$emit('close');
          },




            apply: function(){
                this.hasChanges = false;
                this.$emit('apply', this.selections);
            },

            reset: function(){
                this.selections.forEach((selection)=>{

                    selection.selections.forEach((item)=>{
                        item.selected = true;
                    })

                    selection.selection = "All";

                })

                this.hasChanges = true;
            },

            setLevel: function(level){
                this.currentLevel = level;
            },

            setCategory: function(index){
                console.log("set Category ", index);
                this.currentCategory = index;
                this.currentLevel = 1;
            },

            toggleItem: function(index){
                console.log("toggle", index);
                this.hasChanges = true;
                let item = this.selections[this.currentCategory].selections[index];

                let selected = [];

                if(index){
                    item.selected = !item.selected;
                    if(!item.selected){
                        this.selections[this.currentCategory].selections[0].selected = false;
                    }

                    this.selections[this.currentCategory].selections.forEach((selection)=>{
                        if(selection.selected){
                            selected.push(selection.title);
                        }
                    })
                }else{
                    item.selected = !item.selected;
                    this.selections[this.currentCategory].selections.forEach((selection)=>{
                        selection.selected = item.selected;
                        if(selection.selected){
                            selected.push(selection.title);
                        }
                    })
                }


                if(selected.length){

                    if(selected.length < this.selections[this.currentCategory].selections.length){
                        if(selected.length == 1){
                            this.selections[this.currentCategory].selection = selected[0];
                        }else{
                            this.selections[this.currentCategory].selection = selected.length + " Items";
                        }

                    }else{
                        this.selections[this.currentCategory].selection = "All"
                    }
                }else{
                    this.selections[this.currentCategory].selection = "";
                }


            }
        },
        computed: {
            panelStyle: function(){
                return {
                    width: this.width,
                    top: this.top,
                    left: this.left
                }
            },


            currentSelections: function(){

                if(this.currentLevel){
                    return this.selections[this.currentCategory].selections;

                }else{
                    return this.selections;
                }
            }

        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }



    .drop-down-arrow::after {
      display: block;
      content: '\25BC';
      position: relative;
      top: 2px;
      speak: none;
      font-size: 1em;
      transform: scaleY(.5) scaleX(1);
    }

    .date-select {
      padding: 0 1em;
      margin: 0;
      padding-bottom: 2em;
      color: #707070;
    }

    .range-text {
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      padding-left: 0.25em;
      padding-bottom: 0.25em;
      border-bottom: 1px solid black;
    }

</style>
