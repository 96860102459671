<template>
  <div>
    <div class="screen" data-screen="ledger" :data-active="currentScreen=='ledger'">
      <img class="w-full max-w-100" src="/img/screens/ledger/general-ledger.png?v=10122022" />
      <div class="links">
        <screen-link left="84%" top="14%" width="6%" height="5%" dot-class="dot-top-center" data-tgt="pullout-manage-ledger" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="ledger" />
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>The General Ledger functionality allows clients to electronically and quickly post payroll information to many popular accounting programs. Export preferences can be setup directly in the module.</p>
        </info-box>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminLedger",
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>