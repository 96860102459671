<template>
  <div class="demo-frame">
    <TabletLayout data-tgt="dashboard" data-tgt-mode="employee" @close="$screens.onLinkClick">
      <template #screens>
        <div class="screen" data-screen="mobile-dashboard-trip" :data-active="currentScreen=='mobile-dashboard-trip'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/mobile-employee-dashboard-trip-started.png?v=10122022" />
          <div class="links">
            <screen-link id="trip" left="63.5%" top="228%" width="15%" height="18%" dot-class="dot-bottom-center" data-tgt="mobile-trip-summary" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="mobile-trip-summary" :data-active="currentScreen=='mobile-trip-summary'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/mobile-trip-summary.png?v=10122022" />
          <div class="links">
            <screen-link id="trip" left="75%" top="13%" width="11%" height="6%" dot-class="dot-bottom-center" data-tgt="mobile-dashboard-trip" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link id="trip" left="87%" top="13%" width="11%" height="6%" dot-class="dot-bottom-center" data-tgt="mobile-dashboard" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link id="trip" left="83%" top="30%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="mobile-trip-summary-edit" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="mobile-trip-summary-edit" :data-active="currentScreen=='mobile-trip-summary-edit'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/mobile-trip-summary-edit.png?v=10122022" />
          <div class="links">
            <screen-link id="trip" left="75%" top="8%" width="11%" height="6%" dot-class="dot-bottom-center" data-tgt="mobile-trip-summary" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link id="trip" left="87%" top="8%" width="11%" height="6%" dot-class="dot-bottom-center" data-tgt="mobile-trip-summary" data-tgt-mode="mobile" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="mobile-dashboard" :data-active="currentScreen=='mobile-dashboard'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/mobile-employee-dashboard.png?v=10122022" />
          <div class="links">
            <screen-link id="trip" left="63.5%" top="234.5%" width="15%" height="6%" dot-class="dot-top-center" data-tgt="mobile-dashboard-trip" data-tgt-mode="mobile"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>


      </template>
      <template #pullouts>

      </template>
    </TabletLayout>
  </div>
</template>

<script>
import TabletLayout from "@/views/TabletLayout";
//import TripPullout from "@/components/TripPullout";
export default {
  name: "MobileExperience",
  components: {TabletLayout},
  mounted() {
    if(this.currentScreen === "mobile-dashboard-trip"){
      this.scrollBottom();
    }

  },
  methods: {
    scrollBottom: function(){
      this.$store.state.screenScrollRef.scrollTo(0,this.$store.state.screenScrollRef.scrollHeight)
    }
  },
  computed: {
    currentScreen: function(){
      let screen = this.$route.params.screen?this.$route.params.screen:this.defaultScreen;
      return screen
    }
  }
}
</script>

<style scoped>

</style>