<template>
    <div class="filters-panel text-black" :style="panelStyle">
      <div v-if="currentLevel === 0">
        <header class="header">
            <span></span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <ul class="selections">
            <li>
                <span>Edit Full Check</span>
            </li>
          <li>
            <span>Check Preview</span>
          </li>

        </ul>
        <ul class="selections">
          <li>
            <span>Add Row</span>
          </li>
          <li class="disabled">
            <span>Apply Check Template</span>
          </li>
          <li>
            <span>{{ $store.state.printOnsite?"Cancel ":""}}Print On-site</span>
          </li>
          <li class="destructive">
            <span>Delete Check</span>
          </li>
        </ul>
        <ul class="selections">
          <li>
            <span>Add New Check</span>
            <span class="text-primary"><img class="icon chevron-icon" src="../assets/chevron-right-icon.svg" /></span>
          </li>
        </ul>

        <screen-link left="84%" top="1%" width="15%" height="10%"  data-tgt="pullout-edit-check" @click.native="$emit('close')"></screen-link>

      <screen-link left="0%" top="13%" width="50%" height="10%"  data-tgt="pullout-edit-check" @click.native="showPullout"></screen-link>
        <screen-link left="0%" top="24%" width="50%" height="10%"  data-tgt="pullout-check-preview" @click.native="showPullout"></screen-link>
        <screen-link left="0%" top="60.5%" width="50%" height="10%"  data-tgt="pullout-check-preview" @click.native="togglePrintOnsite"></screen-link>



      </div>


    </div>
</template>

<script>

    import ScreenLink from "@/components/ScreenLink";
    export default {
        name: "payroll-edit-panel",
        components: {ScreenLink},
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
            default: "Check date"
          }
        },
        data: function () {
            return {
                isInactive: true,
                hasChanges: false,
                title: "Filters",
                currentLevel: 0,
                currentCategory: 0,




            }
        },
        methods: {
            close: function(){
                this.$emit('close');
            },

          togglePrintOnsite: function(){
              this.$store.state.printOnsite = !this.$store.state.printOnsite;

                  this.$emit('close')
          },

          onLinkClick: function(e){
            this.$emit('link',e)
          },

          showPullout: function(e){
            this.$store.state.overlayPanel = "";
            this.$emit('pullout',e);
          },



            apply: function(){
                this.hasChanges = false;
                this.$emit('apply', this.selections);
            },

            reset: function(){
                this.selections.forEach((selection)=>{

                    selection.selections.forEach((item)=>{
                        item.selected = true;
                    })

                    selection.selection = "All";

                })

                this.hasChanges = true;
            },

            setLevel: function(level){
              console.log("Set Level", level)
                this.currentLevel = level;
            },

            setCategory: function(index){
                console.log("set Category ", index);
                this.currentCategory = index;
                this.currentLevel = 1;
            },

            toggleItem: function(index){
                console.log("toggle", index);
                this.hasChanges = true;
                let item = this.selections[this.currentCategory].selections[index];

                let selected = [];

                if(index){
                    item.selected = !item.selected;
                    if(!item.selected){
                        this.selections[this.currentCategory].selections[0].selected = false;
                    }

                    this.selections[this.currentCategory].selections.forEach((selection)=>{
                        if(selection.selected){
                            selected.push(selection.title);
                        }
                    })
                }else{
                    item.selected = !item.selected;
                    this.selections[this.currentCategory].selections.forEach((selection)=>{
                        selection.selected = item.selected;
                        if(selection.selected){
                            selected.push(selection.title);
                        }
                    })
                }


                if(selected.length){

                    if(selected.length < this.selections[this.currentCategory].selections.length){
                        if(selected.length == 1){
                            this.selections[this.currentCategory].selection = selected[0];
                        }else{
                            this.selections[this.currentCategory].selection = selected.length + " Items";
                        }

                    }else{
                        this.selections[this.currentCategory].selection = "All"
                    }
                }else{
                    this.selections[this.currentCategory].selection = "";
                }


            }
        },
        computed: {
            panelStyle: function(){
                return {
                    width: this.width,
                    top: this.top,
                    left: this.left
                }
            },


            currentSelections: function(){

                if(this.currentLevel){
                    return this.selections[this.currentCategory].selections;

                }else{
                    return this.selections;
                }
            }

        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .v-center {
      display: flex;
      align-items: center;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

    li.destructive {
      color: #da3224;

    }

    li.disabled {
      color: #bbb;

    }

    .selections + .selections {
      border-top: 1px solid rgba(0,0,0,0.12);
    }

</style>
