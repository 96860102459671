<template>
  <div>
    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="hr" :data-active="currentScreen=='hr'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="72%" top="66%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="42%" top="55.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-demographics" @click.native="$screens.onLinkClick"></screen-link>





        <screen-link left="42%" top="111.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-calendar" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="68.5%" top="99.25%" width="18%" height="7.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link left="69.5%" top="80%" width="8%" height="9%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-post-job"   @click.native="$screens.showPullout"></screen-link>
        <screen-link left="72%" top="90%" width="11%" height="7%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="/#/indeed/post-job" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hr" />


        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>In the Human Resources module, users can manage employee trainings, performance reviews, and even upcoming events. </p>
        </info-box>

      </div>
    </div>
    <div v-else class="screen" data-screen="hr" :data-active="currentScreen=='hr'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources.png?v=10122022" />
      <div class="links">
        <screen-link left="72%" top="66%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="42%" top="55.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-demographics" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="42%" top="93.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-reviews" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="69%" top="106%" width="8%" height="9%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="hr-review-issues" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="78%" top="106%" width="8%" height="9%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-submit-issue" @click.native="$screens.showPullout"></screen-link>



        <screen-link left="42%" top="149.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-calendar" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="68.5%" top="118.5%" width="18%" height="7.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link left="69.5%" top="80%" width="8%" height="9%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-post-job"   @click.native="$screens.showPullout"></screen-link>
        <screen-link left="72%" top="90%" width="11%" height="7%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="/#/indeed/post-job" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link left="68.5%" top="129.5%" width="18%" height="7.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />

        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>In the Human Resources module, users can manage employee trainings, performance reviews, and even upcoming events. </p>
        </info-box>

      </div>
    </div>

    <div class="screen" data-screen="hr-review-issues" :data-active="currentScreen=='hr-review-issues'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-hr-review-issues.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="91.25%" top="18.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-submit-issue" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="28%" top="45.25%" width="39%" height="13%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-review-issues" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="28%" top="58.5%" width="39%" height="12%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-review-issues" data-init-info="michael" @click.native="$screens.showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>



    <div class="screen" data-screen="hr-compliance-assistance" :data-active="currentScreen=='hr-compliance-assistance'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-compliance-assistance.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="60%" top="48.25%" width="6.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance-eeo-1-reporting" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="60%" top="63.75%" width="6.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance-osha" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>

    <div class="screen" data-screen="hr-compliance-assistance-eeo-1-reporting" :data-active="currentScreen=='hr-compliance-assistance-eeo-1-reporting'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-compliance-assistance-eeo-1-reporting.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="44%" top="40.5%" width="6.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance-eeo-1-employment-data" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>

    <div class="screen" data-screen="hr-compliance-assistance-eeo-1-reporting" :data-active="currentScreen=='hr-compliance-assistance-eeo-1-employment-data'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-compliance-assistance-eeo-1-employment-data.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance-eeo-1-reporting" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>

    <div class="screen" data-screen="hr-compliance-assistance-osha" :data-active="currentScreen=='hr-compliance-assistance-osha'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-compliance-assistance-osha.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-compliance-assistance" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>

    <div class="screen" data-screen="hr-calendar" :data-active="currentScreen=='hr-calendar'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-calendar.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="90%" top="18.5%" width="9%" height="4.5%" data-tgt-scrolltop="true" data-tgt="pullout-calendar-event" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>


    <div class="screen" data-screen="hr-calendar-event-added" :data-active="currentScreen=='hr-calendar-event-added'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-calendar-event-added.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />

      </div>
    </div>

    <div class="screen" data-screen="hr-reviews" :data-active="currentScreen=='hr-reviews'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-reviews.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="76%" top="29%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-reviews-create" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="15%" top="22.5%" width="4.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="hr-reviews" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="19.5%" top="22.5%" width="9%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="hr-reviews-assigned" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="68%" top="50.5%" width="20%" height="10%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-performance" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />
      </div>
    </div>

    <div class="screen" data-screen="hr-reviews-assigned" :data-active="currentScreen=='hr-reviews-assigned'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-reviews-assigned.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="15%" top="22.5%" width="4.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="hr-reviews" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="19.5%" top="22.5%" width="9%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="hr-reviews-assigned" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />

      </div>
    </div>

    <div class="screen" data-screen="hr-reviews-create" :data-active="currentScreen=='hr-reviews-create'">
      <img class="w-full max-w-100" src="/img/screens/hr/admin-human-resources-reviews-create.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-reviews" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" />

      </div>
    </div>
  </div>
</template>

<script>
import CompanyTab from "@/components/CompanyTab";
export default {
  name: "AdminHr",
  components: {CompanyTab},
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>