


<template>
<div class="onboarding-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="onboarding-list">


    <div class="onboarding-scroll" :data-active="!detailPanel">
      <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-banking-info.png?v=10122022" />

      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="6%" top="34%" width="46%" height="5%" data-tgt="details" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="onboarding-scroll" :data-active="detailPanel === 'details'">
      <img class="w-full" src="/img/screens/easy-setup/pullouts/easy-setup-banking-info-details.png?v=10122022" />
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="onSave"></screen-link>

      </div>
    </div>


    <div v-if="confirm" class="confirm">
      <div class="confirm-panel">
        <img src="/img/screens/easy-setup/pullouts/confirm-modal.png" />
        <div class="links">
          <screen-link left="90%" top="10%" width="9%" height="25%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="()=>{confirm = false}"></screen-link>
          <screen-link left="60%" top="65%" width="15.5%" height="25%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="()=>{confirm = false}"></screen-link>
          <screen-link left="78%" top="65%" width="19%" height="25%" data-tgt="" dot-class="dot-top-center" data-is-overlay="true" @click.native="complete"></screen-link>
        </div>
      </div>

    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "banking-info-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null,
          agreed: false,
          confirm: false
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      complete: function(){
        this.$store.state.easySetup.bankingInfo.completed = true;
        this.$emit('close');
      },

      onSave: function(){
        this.confirm = true;
      },

      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.onboarding-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);
}

.onboarding-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.onboarding-pullout img {
  display: block;
}


.onboarding-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.onboarding-scroll {

  display: none;
  position: relative;

  min-height: 100%;

  overflow-y: scroll;

}

.w-full {
  width: 100%;
}

.links {

}

.confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-panel {
  position: relative;
  width: calc(452/600 * 100%);
  margin-left: -120%;
}

.paidThisYear {
  position: absolute;
  background-color: white;
  padding: 1em;
  font-size: 0.8em;

}

.onboarding-scroll[data-active="true"]{
  display: block;
}

</style>
