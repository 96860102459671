<template>
  <div class="demo-frame">
  <div class="flexlike-layout" :data-noscroll="$store.state.overlayPanel?true:false" :data-offset="!!$store.state.helpPanel" :data-guided="$store.state.guidedMode" :data-show-links="$store.state.showLinks">

    <div class="flexlike-screens" ref="screens">
      <div class="screen" data-screen="easy-setup-start" :data-active="currentScreen=='easy-setup-start'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-start-base.png?v=10122022" />

        <onboarding-task-button styles="top:38.6%;left:79.4%;" task="getStarted" />
        <onboarding-task-button styles="top:46.8%;left:79.4%;" task="priorPayroll" />
        <onboarding-task-button styles="top:55.2%;left:79.4%;" task="businessInfo" />
        <onboarding-task-button styles="top:63%;left:79.4%;" task="bankingInfo" />
        <onboarding-task-button styles="top:71.4%;left:79.4%;" task="paySchedule" />
        <onboarding-task-button styles="top:79.3%;left:79.4%;" task="taxInfo" />
        <onboarding-task-button styles="top:87.6%;left:79.4%;" task="addEmployees" />
        <onboarding-task-button styles="top:95.6%;left:79.4%;" task="documents" />
        <onboarding-task-button styles="top:104%;left:79.4%;" task="submitInfo" />


        <div class="links">
          <screen-link left="79%" top="39%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="get-started"  @click.native="$screens.showPullout"></screen-link>
          <screen-link left="79%" top="47%" width="6%" height="4.5%" dot-class="dot-right-center" :data-init-info="$store.state.easySetup.priorPayroll.completed?'revisit':''" data-tgt="prior-payroll"  @click.native="$screens.showPullout"></screen-link>
          <screen-link left="79%" top="55.5%" width="6%" height="4.5%" dot-class="dot-right-center" :data-init-info="$store.state.easySetup.businessInfo.completed?'details':''"  data-tgt="business-info"  @click.native="$screens.showPullout"></screen-link>
          <screen-link left="79%" top="63.5%" width="6%" height="4.5%" dot-class="dot-right-center" :data-init-info="$store.state.easySetup.bankingInfo.completed?'details':''" data-tgt="banking-info"  @click.native="$screens.showPullout"></screen-link>
          <screen-link left="79%" top="72%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="pay-schedule"  @click.native="$screens.showPullout"></screen-link>
          <screen-link left="79%" top="80%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="tax-info" data-tgt-mode="easysetup" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="79%" top="88.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="people"  @click.native="()=>{$screens.setOverlayPanel('people')}"></screen-link>
          <screen-link left="79%" top="96.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="documents"  data-tgt-mode="easysetup" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="79%" v-if="$store.state.easySetup.documents.completed" top="105%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="finished" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>
      <!-- Tax Info -->

      <div class="screen" data-screen="tax-info" :data-active="currentScreen=='tax-info'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info.png?v=10122022" />
        <div class="links">
          <screen-link left="46%" top="51%" width="9%" height="4.5%" dot-class="dot-right-center" data-tgt="seasonal-employer"  @click.native="showInfo"></screen-link>
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38%" top="55%" width="5%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="tax-info-not-seasonal"  @click.native="$screens.onLinkClick"></screen-link>

          <div v-if="info === 'seasonal-employer'" class="info-bubble" @click="hideInfo" style="top:40.5%;left:41%;width:17%;">
            <div class="info-inner">
              Seasonal employers have at least one quarter without tax liability because they don't pay any wages.
            </div>

          </div>
        </div>
      </div>

      <div class="screen" data-screen="tax-info-not-seasonal" :data-active="currentScreen=='tax-info-not-seasonal'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-seasonal-no.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="53%" top="65%" width="16%" height="4.5%" dot-class="dot-right-center" data-tgt="futa"  @click.native="showInfo"></screen-link>
          <screen-link left="38%" top="69%" width="4%" height="3.5%" dot-class="dot-left-center" data-tgt="tax-info-not-seasonal-no-futa" data-tgt-mode="easysetup" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="42.1%" top="69%" width="5%" height="3.5%" dot-class="dot-right-center" data-tgt="form-940"  @click.native="showInfo"></screen-link>

          <div v-if="info === 'futa'" class="info-bubble" @click="hideInfo" style="top:46.5%;left:52%;width:17%;">
            <div class="info-inner">
              The Federal Unemployment Tax Act (FUTA) establishes a payroll tax for employers, also known as federal unemployment withholdings. Revenue from these payroll taxes funds unemployment benefits.
            </div>
          </div>
          <div v-if="info === 'form-940'" class="info-bubble" @click="hideInfo" style="top:58.5%;left:36%;width:17%;">
            <div class="info-inner">
              IRS Form 940 Employer's Annual Federal Unemployment Tax Return.
            </div>
          </div>
        </div>
      </div>

      <div class="screen" data-screen="tax-info-not-seasonal-no-futa" :data-active="currentScreen=='tax-info-not-seasonal-no-futa'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-exempt-no.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38%" top="83.4%" width="4%" height="3.5%" dot-class="dot-left-center" data-tgt="tax-info-no-agricultural"  data-tgt-mode="easysetup" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="53%" top="87.2%" width="5.5%" height="3.5%" dot-class="dot-right-center" data-tgt="form-943"  @click.native="showInfo"></screen-link>

          <div v-if="info === 'form-943'" class="info-bubble" @click="hideInfo" style="top:75.5%;left:47%;width:17%;">
            <div class="info-inner">
              IRS Form 943 Employer's Annual Federal Tax Return for Agricultural Employees.
            </div>
          </div>
        </div>
      </div>

      <div class="screen" data-screen="tax-info-no-agricultural" :data-active="currentScreen=='tax-info-no-agricultural'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-agricultural-no.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38%" top="103.5%" width="4%" height="3.5%" dot-class="dot-left-center" data-tgt="tax-info-less-10k" data-tgt-mode="easysetup"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="42%" top="103.5%" width="5.5%" height="3.5%" dot-class="dot-right-center" data-tgt="form-941"  @click.native="showInfo"></screen-link>
          <screen-link left="42%" top="107.5%" width="5.5%" height="3.5%" dot-class="dot-right-center" data-tgt="form-944"  @click.native="showInfo"></screen-link>

          <div v-if="info === 'form-941'" class="info-bubble" @click="hideInfo" style="top:93.5%;left:36%;width:17%;">
            <div class="info-inner">
              IRS Form 941 Employer's Quarterly Federal Tax Return.
            </div>
          </div>
          <div v-if="info === 'form-944'" class="info-bubble" @click="hideInfo" style="top:98.5%;left:36%;width:17%;">
            <div class="info-inner">
              IRS Form 944 Employer's Quarterly Federal Tax Return.
            </div>
          </div>
        </div>
      </div>
      <div class="screen" data-screen="tax-info-less-10k" :data-active="currentScreen=='tax-info-less-10k'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-agricultural-no-status-no.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38%" top="180%" width="4%" height="3.5%" dot-class="dot-left-center" data-tgt="tax-info-other-details" data-tgt-mode="easysetup"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="67%" top="176.5%" width="6.5%" height="3.5%" dot-class="dot-right-center" data-tgt="tax-status"  @click.native="$screens.showHelp"></screen-link>

          <div v-if="info === 'form-943'" class="info-bubble" @click="hideInfo" style="top:75.5%;left:47%;width:17%;">
            <div class="info-inner">
              IRS Form 943 Employer's Annual Federal Tax Return for Agricultural Employees.
            </div>
          </div>
        </div>
      </div>
      <div class="screen" data-screen="tax-info-other-details" :data-active="currentScreen=='tax-info-other-details'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-agricultural-no-threshold-no-status-no.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="70%" top="182%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="tax-info-federal" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="67%" top="168.5%" width="6.5%" height="3.5%" dot-class="dot-right-center" data-tgt="tax-status"  @click.native="$screens.showHelp"></screen-link>

          <div v-if="info === 'form-943'" class="info-bubble" @click="hideInfo" style="top:75.5%;left:47%;width:17%;">
            <div class="info-inner">
              IRS Form 943 Employer's Annual Federal Tax Return for Agricultural Employees.
            </div>
          </div>
        </div>
      </div>

      <div class="screen" data-screen="tax-info-federal" :data-active="currentScreen=='tax-info-federal'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-federal.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="36.5%" top="147%" width="6.5%" height="5%" dot-class="dot-left-center" data-tgt="tax-info-other-details" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="70%" top="147%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="tax-info-state" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="tax-info-state" :data-active="currentScreen=='tax-info-state'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-tax-info-state.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="36.5%" top="88%" width="6.5%" height="5%" dot-class="dot-left-center" data-tgt="tax-info-federal" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="70.5%" top="88%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="tax-info-state" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="completeTaxInfo"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="documents" :data-active="currentScreen=='documents'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38.5%" top="42.5%" width="18.5%" height="12%" dot-class="dot-top-center" data-tgt="documents-authorized-signatory" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>


        </div>
      </div>
      <div class="screen" data-screen="documents-authorized-signatory" :data-active="currentScreen=='documents-authorized-signatory'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-authorized.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="71%" top="58%" width="7%" height="4%" dot-class="dot-right-center" data-tgt="documents-authorized-title" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>

        </div>
      </div>
      <div class="screen" data-screen="documents-authorized-title" :data-active="currentScreen=='documents-authorized-title'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-authorized-title.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="38%" top="42%" width="20%" height="6%" dot-class="dot-right-center" data-tgt="documents-authorized-title-options" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="37%" top="56%" width="6%" height="5%" dot-class="" data-tgt="documents-authorized-signatory" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>

        </div>
      </div>
      <div class="screen" data-screen="documents-authorized-title-options" :data-active="currentScreen=='documents-authorized-title-options'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-authorized-title-options.png?v=10122022" />
        <div class="links">
          <screen-link left="33%" top="25%" width="9%" height="5%" dot-class="dot-right-center" data-tgt="documents-authorized-title-manager" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>
      <div class="screen" data-screen="documents-authorized-title-manager" :data-active="currentScreen=='documents-authorized-title-manager'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-authorized-title-manager.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="37%" top="56%" width="6%" height="5%" dot-class="" data-tgt="documents-authorized-signatory" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="70.5%" top="56%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="documents-to-sign" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>


      <div class="screen" data-screen="documents-to-sign" :data-active="currentScreen=='documents-to-sign'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-to-sign.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="69%" top="40.75%" width="7%" height="5%" dot-class="" data-tgt="documents-signed" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="37%" top="66%" width="6%" height="5%" dot-class="" data-tgt="documents-authorized-title-manager" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="$screens.onLinkClick"></screen-link>
        </div>
      </div>

      <div class="screen" data-screen="documents-signed" :data-active="currentScreen=='documents-signed'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-documents-signed.png?v=10122022" />
        <div class="links">
          <screen-link left="14.7%" top="14.2%" width="10%" height="3.5%" dot-class="dot-left-center" data-tgt-mode="easysetup" data-tgt="easy-setup-start"  data-tgt-scrollTop="true" @click.native="$screens.onLinkClick"></screen-link>
          <screen-link left="71%" top="57.5%" width="7%" height="5%" dot-class="" data-tgt="" data-tgt-mode="easysetup" data-tgt-scrollTop="true"  @click.native="completeDocuments"></screen-link>

        </div>
      </div>

      <div class="screen" data-screen="finished" :data-active="currentScreen=='finished'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-finished.png?v=10122022" />
        <div class="links">

        </div>
      </div>

      <div class="screen" data-screen="easy-setup-help" :data-active="currentScreen=='easy-setup-help'">
        <img class="w-full max-w-100" src="/img/screens/easy-setup/easy-setup-help-center.png?v=10122022" />
        <div class="links">

        </div>
      </div>

    </div>

    <div class="flexlike-pullouts" v-if="$store.state.pulloutPanel">
      <get-started-pullout v-if="pulloutPanel === 'get-started'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <prior-payroll-pullout  v-if="pulloutPanel === 'prior-payroll'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <business-info-pullout  v-if="pulloutPanel === 'business-info'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <banking-info-pullout v-if="pulloutPanel === 'banking-info'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <pay-schedule-pullout v-if="pulloutPanel === 'pay-schedule'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <tax-info-pullout v-if="pulloutPanel === 'tax-info'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <add-employees-pullout v-if="pulloutPanel === 'add-employees'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <easy-setup-docs-pullout v-if="pulloutPanel === 'documents'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
      <easy-setup-submit-pullout v-if="pulloutPanel === 'submit-info'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
    </div>

<!--    <notifications-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'notifications'" @close="$screens.setOverlayPanel('')"></notifications-pullout>-->
<!--    <news-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'news'" @close="$screens.setOverlayPanel('')"></news-pullout>-->
    <easy-setup-people-pullout class="nav-panel" v-if="$store.state.overlayPanel == 'people'" @close="$screens.setOverlayPanel('')"></easy-setup-people-pullout>

      <FlexLikeNavTopBar :offset="!!$store.state.helpPanel"></FlexLikeNavTopBar>

<FlexEasySetupSideNav v-if="environment === 'admin'" ></FlexEasySetupSideNav>
<!--    <FlexEmployeeSideNav v-if="environment === 'employee'" :lang="lang"></FlexEmployeeSideNav>-->


    <help-pullout v-if="$store.state.helpPanel" @close="$screens.closeHelp"></help-pullout>
  </div>
  </div>
</template>

<script>
// import ScreenLink from "../components/ScreenLink";

import GetStartedPullout from "@/components/GetStartedPullout";
import EasySetupPeoplePullout from "@/components/EasySetupPeoplePullout";
// import NotificationsPullout from "@/components/NotificationsPullout";
// import NewsPullout from "@/components/NewsPullout";
import HelpPullout from "@/components/HelpPullout";
import FlexLikeNavTopBar from "@/components/FlexLikeNavTopBar";
import FlexEasySetupSideNav from "@/components/FlexEasySetupSideNav";
import PriorPayrollPullout from "@/components/PriorPayrollPullout";
import PaySchedulePullout from "@/components/PaySchedulePullout";
import BusinessInfoPullout from "@/components/BusinessInfoPullout";
import BankingInfoPullout from "@/components/BankingInfoPullout";
import EasySetupDocsPullout from "@/components/EasySetupDocsPullout";
import EasySetupSubmitPullout from "@/components/EasySetupSubmitPullout";
import AddEmployeesPullout from "@/components/AddEmployeesPullout";
import TaxInfoPullout from "@/components/TaxInfoPullout";
import OnboardingTaskButton from "@/components/OnboardingTaskButton";
//import EasySetupPeoplePullout from "@/components/EasySetupPeoplePullout";
// import FlexNavTopBar from "@/components/FlexNavTopBar";
// import FlexAdminSideNav from "@/components/FlexAdminSideNav";
// import FlexEmployeeSideNav from "@/components/FlexEmployeeSideNav";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";

// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "EasySetupScreens",
  components: {
    OnboardingTaskButton,
    EasySetupPeoplePullout,
    EasySetupSubmitPullout,
    EasySetupDocsPullout,
    BankingInfoPullout,
    BusinessInfoPullout,
    TaxInfoPullout,
    AddEmployeesPullout,
    PriorPayrollPullout, FlexEasySetupSideNav, FlexLikeNavTopBar, HelpPullout, GetStartedPullout, PaySchedulePullout },
  //  FlexNavTopBar, FlexAdminSideNav, FlexEmployeeSideNav },
  props: {
    environment: {
      type: String,
      default: "admin"
    },
    lang: {
      type: String,
      default: "en"
    }
  },
  mounted() {
    this.$store.state.screenScrollRef = this.$refs["screens"];
    if(this.$route.query){
      this.$store.state.pulloutPanel = this.$route.query.pullout?this.$route.query.pullout:"";
      this.$store.state.overlayPanel = this.$route.query.tab?this.$route.query.tab:"";
    }
  },
  data: function(){
    return {
      info: ""
    }
  },
  methods: {
      showInfo: function(e){
        let tgt = e.target.dataset.tgt;
        if(this.info === tgt){
          this.info = ""
        }else{
          this.info = tgt;
        }
      },
      hideInfo: function() {
        this.info = ""
      },
      completeTaxInfo: function(){
        this.$store.state.easySetup.taxInfo.completed = true;
        this.$screens.onLinkClick({
          target: {
            dataset: {
              tgt: "easy-setup-start",
              tgtScrollTop: true,
              tgtMode: "easysetup"
            }
          }
        })
      },
    completeDocuments: function(){
      this.$store.state.easySetup.documents.completed = true;
      this.$screens.onLinkClick({
        target: {
          dataset: {
            tgt: "easy-setup-start",
            tgtScrollTop: true,
            tgtMode: "easysetup"
          }
        }
      })
    }
  },

  computed: {
    currentModule: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    },

    pulloutPanel: function(){
      return this.$store.state.pulloutPanel;
    },

    overlayPanel: function() {
      return this.$store.state.overlayPanel;
    },

    panelInitInfo: function() {
      return this.$store.state.panelInitInfo;
    },
  }
}
</script>

<style scoped>



.flexlike-layout {
  width: 100%;
  position: absolute;
  height: 0;
  overflow: hidden;
  padding-bottom: calc((920/1580) * 100%);

  background-size: 100% 10%;
  font-size: 1em;
  box-sizing: border-box;
  max-width: 1580px;
}



.flexlike-sidenav {
  background-color: white;
}


.flexlike-screens {
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  overflow-x: hidden;
}




.flexlike-pullouts {
  position: absolute;
  width: 100%;
  height: 100%;

}


[data-offset = "true"] .flexlike-screens {
  transform: translateX(calc((-180 / 1580) * 100%));
}

[data-offset = "true"] .flexlike-pullouts {
  width: 100%;
  transform: translateX(calc((-320 / 1580) * 100%));
}

[data-offset = "true"] .nav-panel {
  transform: translateX(calc((-320 / 1580) * 100%));
}



[data-noscroll="true"]{
  overflow-y: hidden;
}



@media(min-width:1580px){

}

@media(min-width: 1920px){


}

.info-bubble {
   background: none;
  font-size: 0.88em;
  position: absolute;
  line-height: 1.5em;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 0 0 0 1px #EEE;
  z-index: 1;
  border-radius: 0.5em;
  pointer-events: none;


}

.info-bubble .info-inner {
  background-color: white;
  padding: 1.1em;
  border-radius: 0.5em;
  color: #444;


}

.info-bubble:after {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 1px #EEE;
  z-index: -1;
  bottom: -10px;
  left: calc(50% - 10px);
}


</style>

<style>
html {

}
</style>
