<template>
  <div>
    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="company" :data-active="currentScreen=='company'">
      <img class="w-full max-w-100" src="/img/screens/company/admin-company-details-polarson.png?v=10122022" />

      <div class="links">
        <screen-link left="37.5%" top="17%" width="5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="company-positions" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="company" :active-companies="{enterprise:true,pro:true}"/>
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Client Company Detail houses data for the organization and is a place where admins can house notes.</p>
        </info-box>
      </div>
    </div>
    <div v-else class="screen" data-screen="company" :data-active="currentScreen=='company'">
      <img class="w-full max-w-100" src="/img/screens/company/admin-company-details.png?v=10122022" />

      <div class="links">

        <screen-link left="37.5%" top="17%" width="5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="company-positions" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="company" :active-companies="{enterprise:true,pro:true}"/>



        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Client Company Detail houses data for the organization and is a place where admins can house notes.</p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="company-positions" :data-active="currentScreen=='company-positions'">
      <img class="w-full max-w-100" src="/img/screens/company/admin-company-positions.png?v=10122022" />

      <div class="links">
        <screen-link left="15%" top="17%" width="3.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="company" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="69%" top="30.2%" width="18%" height="12%" dot-class="dot-right-center" data-tgt="pullout-company-details" data-init-info="pay-ranges" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="27%" top="77.5%" width="41%" height="6%" dot-class="dot-right-center" data-tgt="pullout-company-details" data-init-info="fulfillment-associate" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="company" :active-companies="{enterprise:true,pro:true}"/>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTab from "@/components/CompanyTab";
export default {
  name: "AdminCompanyDetails",
  components: {CompanyTab},
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>