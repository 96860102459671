<template>
  <div class="demo-frame">
    <PhoneLayout data-tgt="dashboard" data-tgt-mode="employee" @close="closeWindow">
      <template #screens>
        <div class="screen" data-screen="expense-home" :data-active="currentScreen=='expense-home'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile.png?v=11172023" />
          <div class="links">

            <screen-link left="79%" top="61%" width="13%" height="10%" dot-class="dot-left-center" data-tgt="add-report" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="5%" top="75%" width="90%" height="10%" dot-class="dot-right-center" data-tgt="view-reports" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="5%" top="93%" width="90%" height="10%" dot-class="dot-right-center" data-tgt="credit-cards" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="add-report" :data-active="currentScreen=='add-report'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-report.png?v=11212023" />
          <div class="links">
            <screen-link left="2%" top="12.5%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="32%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-details" :data-active="currentScreen=='add-report-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-report-details.png?v=11212023" />
          <div class="links">
            <screen-link left="2%" top="12.5%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="184%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="add-report-expense" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-expense" :data-active="currentScreen=='add-report-expense'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="12.5%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="80%" width="93%" height="28%" dot-class="dot-left-center" data-tgt="add-report-expense-details"  data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="2%" top="134%" width="93%" height="8%" dot-class="dot-left-center" data-tgt="add-report-expense-details"  data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="152%" width="93%" height="8%" dot-class="dot-left-center" data-tgt="add-report-expense-details"  data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="170%" width="93%" height="8%" dot-class="dot-left-center" data-tgt="add-report-expense-details" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="add-report-expense-details" :data-active="currentScreen=='add-report-expense-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense-details.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="12.5%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="243%" width="93%" height="9%" dot-class="dot-left-center" data-tgt="add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="257%" width="93%" height="9%" dot-class="dot-left-center" data-tgt="add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="add-report-receipt" :data-active="currentScreen=='add-report-receipt'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense-receipt.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="12.5%" width="93%" height="10%" dot-class="dot-left-center" data-tgt="add-report-expense-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="32%" top="51.5%" width="65%" height="9%" dot-class="dot-left-center" data-tgt="add-report-receipt-upload" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-receipt-upload" :data-active="currentScreen=='add-report-receipt-upload'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense-receipt-upload.png?v=11172023" />
          <div class="links">
            <screen-link left="1%" top="16%" width="20%" height="9%" dot-class="dot-left-center" data-tgt="add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="81%" top="16%" width="18%" height="9%" dot-class="dot-left-center" data-tgt="add-report-receipt-selected" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-receipt-selected" :data-active="currentScreen=='add-report-receipt-selected'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense-receipt-selected.png?v=11172023" />
          <div class="links">
            <screen-link left="1%" top="13%" width="20%" height="9%" dot-class="dot-left-center" data-tgt="add-report-expense-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3%" top="98%" width="92%" height="9.5%" dot-class="dot-left-center" data-tgt="add-report-receipt-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-receipt-added" :data-active="currentScreen=='add-report-receipt-added'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-add-expense-receipt-added.png?v=11172023" />
          <div class="links">
            <screen-link left="3%" top="288%" width="92%" height="9.5%" dot-class="dot-left-center" data-tgt="add-report-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="add-report-added" :data-active="currentScreen=='add-report-added'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-report-added.png?v=11212023" />
          <div class="links">
            <screen-link left="83%" top="24%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="reports-menu-options" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="reports-menu-options" :data-active="currentScreen=='reports-menu-options'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-reports-menu.png?v=11172023" />
          <div class="links">
            <screen-link left="83%" top="24%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="add-report-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="29%" top="46.5%" width="42%" height="10%" dot-class="dot-left-center" data-tgt="report-submit" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="report-submit" :data-active="currentScreen=='report-submit'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-reports-submit.png?v=11212023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="add-report-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="154%" width="46%" height="10%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="view-reports" :data-active="currentScreen=='view-reports'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-view-reports.png?v=11202023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="62%" width="95%" height="19%" dot-class="dot-right-center" data-tgt="view-reports-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="view-reports-details" :data-active="currentScreen=='view-reports-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-view-reports-oct.png?v=11202023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="24%" width="15%" height="13%" dot-class="dot-left-center" data-tgt="view-reports-details-menu" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="view-reports-details-menu" :data-active="currentScreen=='view-reports-details-menu'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-view-reports-oct-menu.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="24%" width="15%" height="13%" dot-class="dot-left-center" data-tgt="view-reports-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="26%" top="37%" width="45%" height="7%" dot-class="dot-left-center" data-tgt="view-reports-approve" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="view-reports-approve" :data-active="currentScreen=='view-reports-approve'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-view-reports-confirm.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="85%" top="26%" width="15%" height="13%" dot-class="dot-left-center" data-tgt="view-reports-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="10%" top="118%" width="38%" height="12%" dot-class="dot-left-center" data-tgt="view-reports-approved" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="view-reports-approved" :data-active="currentScreen=='view-reports-approved'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-view-reports-approved.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen" data-screen="credit-cards'" :data-active="currentScreen=='credit-cards'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="48%" width="95%" height="19%" dot-class="dot-right-center" data-tgt="credit-card-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen" data-screen="credit-card-details'" :data-active="currentScreen=='credit-card-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-select.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="credit-cards" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="25%" width="95%" height="11%" dot-class="dot-bottom-center" data-tgt="credit-card-new-report" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen" data-screen="credit-card-new-report'" :data-active="currentScreen=='credit-card-new-report'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-transactions.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="98%" width="12%" height="11%" dot-class="dot-bottom-center" data-tgt="credit-card-new-report-selections" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-new-report-selections'" :data-active="currentScreen=='credit-card-new-report-selections'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-transactions-selected.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="13%" top="198%" width="25%" height="13%" dot-class="dot-left-center" data-tgt="credit-card-add-report" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report'" :data-active="currentScreen=='credit-card-add-report'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-add-report.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-new-report-selections" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="4%" top="32%" width="92%" height="11%" dot-class="dot-left-center" data-tgt="credit-card-add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-details'" :data-active="currentScreen=='credit-card-add-report-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-add-report-details.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="16%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-new-report-selections" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="168.5%" width="45%" height="13%" dot-class="dot-left-center" data-tgt="credit-card-add-report-unsubmitted" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-unsubmitted'" :data-active="currentScreen=='credit-card-add-report-unsubmitted'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-add-report-unsubmitted.png?v=11172023" />
          <div class="links">
            <screen-link left="14%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-details" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="70%" top="72%" width="10%" height="13%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-receipt'" :data-active="currentScreen=='credit-card-add-report-receipt'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-report-receipt.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-unsubmitted" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="5%" top="168%" width="90%" height="11%" dot-class="dot-top-center" data-tgt="credit-card-add-report-receipt-add" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-receipt-add'" :data-active="currentScreen=='credit-card-add-report-receipt-add'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-report-receipt-add.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="5%" top="97%" width="90%" height="11%" dot-class="dot-top-center" data-tgt="credit-card-add-report-receipt-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-receipt-added'" :data-active="currentScreen=='credit-card-add-report-receipt-added'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-report-receipt-added.png?v=11172023" />
          <div class="links">
            <screen-link left="13%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="24%" width="16%" height="13%" dot-class="dot-left-center" data-tgt="credit-card-add-report-added-menu" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-added-menu'" :data-active="currentScreen=='credit-card-add-report-added-menu'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-report-receipt-added-menu.png?v=11172023" />
          <div class="links">
            <screen-link left="13%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="24%" width="16%" height="13%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="29%" top="47%" width="45%" height="10%" dot-class="dot-left-center" data-tgt="credit-card-add-report-submit" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="credit-card-add-report-submit'" :data-active="currentScreen=='credit-card-add-report-submit'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/mobile/expense-mobile-credit-cards-report-receipt-submit.png?v=11172023" />
          <div class="links">
            <screen-link left="2%" top="11%" width="12%" height="12%" dot-class="dot-left-center" data-tgt="credit-card-add-report-receipt-added" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="5%" top="152%" width="41%" height="14%" dot-class="dot-top-center" data-tgt="expense-home" data-tgt-scrolltop="true" data-tgt-mode="expense-wire-mobile" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>




      </template>
    </PhoneLayout>
  </div>
</template>

<script>
import PhoneLayout from "@/views/PhoneLayout";
export default {
  name: "EmployeeExpenseWireExperience",
  components: {PhoneLayout},
  computed: {
    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    }
  },
  methods: {
    closeWindow: function(){
      window.close();
    },
  }
}
</script>

<style scoped>

</style>