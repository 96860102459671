<template>
    <div class="options-panel text-black" :style="panelStyle">
      <div v-if="level === 0">
        <div class="menu-items">
          <div v-for="(item, index) in items" :key="index" class="menu-item" :data-active="item.value === selection" @click="onItemClick(item)" >
            <div :class="item.screenLink?'app-link dot-left-center':''" data-show-dot="true">
              <span style="user-select: none;">{{ item.label }}</span>
            </div>

          </div>
        </div>
        <footer class="menu-items">
          <div v-for="(item, index) in footerItems" :key="index" class="menu-item" :data-active="item.value === selection" @click="onItemClick(item)" >
            <div :class="item.screenLink?'app-link dot-left-center':''" data-show-dot="true">
              <span :style="`user-select: none;color:${item.color?item.color:'black'};`">{{ item.label }}</span>
            </div>

          </div>
        </footer>
      </div>

    </div>
</template>

<script>


    export default {
        name: "hiring-profile-options-panel",
        components: {},
      mounted: function(){

      },
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          items: {
              default: function(){
                return [
                  { label: "New" },
                  { label: "Screening", screenLink: "screening" },
                  { label: "Evaluation", screenLink: "evaluation" },
                  { label: "Background Reports", screenLink: "background"  },
                  { label: "Offer", screenLink: "offer" },
                  { label: "Hire" , screenLink: "hire"}
                ]
              }
          },
          footerItems: {
            default: function(){
              return [
                { label: "Move to position filled" },
                { label: "Reject Candidate", color: "red" },
              ]
            }
          },

          onSelect: {},

          selection: {
              default: ""
          },
          header: {},
        },
        data: function () {
            return {
              level: 0
            }
        },
        methods: {

          onItemClick: function(item){
            console.log(item)

            if(this.onSelect && item.screenLink){
              this.onSelect(item.screenLink);
            }

          },

            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e);
            this.$emit('close');
          },

          onShowPullout: function(e){
              console.log("emiting pullout",e);
            this.$emit('pullout',e);
            this.$emit('close');
          },

          setLevel: function(level){
              this.currentLevel = level;
          },




        },
        computed: {
          panelStyle: function(){
            return {
              width: this.width,
              top: this.top,
              left: this.left
            }
          },
        }
    }
</script>

<style scoped>


    .options-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }



    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

    .menu-items {
      margin-bottom: 1em;
      position: relative;
    }

    .menu-heading {
      opacity: 0.6;
    }

    .menu-item {
      display: flex;
      align-items: center;
      padding: 1em;
      position: relative;

    }

    .menu-item:hover {
      background-color: #f8f8f8;
    }

    .menu-item[data-active="true"]{
      background-color: #e5f0f7;
    }

    .menu-item .icon {
      width: 2em;
      height: 2em;
      margin-right: 1em;
    }

    .muted {
      opacity: 0.25;
    }

    .tag {
      font-size: 0.8em;
      padding: 0.2em 0.5em;
      opacity: 0.6;
      border: 1px solid black;
      border-radius: 1em;
      margin-left: 0.5em;
    }



    .drop-down-arrow::after {
      display: block;
      content: '\25BC';
      position: relative;
      top: 2px;
      speak: none;
      font-size: 1em;
      transform: scaleY(.5) scaleX(1);
    }

    footer {
      border-top: 1px solid #d8d8d8;
    }




    .v-center {
      display: flex;
      align-items: center;
    }

</style>
