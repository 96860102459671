<template>
  <div class="screen-container" style="margin: 0 auto;">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <!-- DASHBOARD -->

        <div class="screen z-max" data-screen="home" :data-active="currentScreen=='home'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/my-perks-corestream-get-started.png?v=05132024" />

          <div class="links">
            <screen-link left="0%" top="1%" width="11.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="5%" top="46%" width="16%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="enroll" @click.native="onLinkClick"></screen-link>
            <screen-link left="5%" top="127%" width="16%" height="6.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>

          </div>
        </div>
        <div class="screen z-max" data-screen="enroll" :data-active="currentScreen=='enroll'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/my-perks-corestream-enroll.png?v=05132024" />

          <div class="links">
            <screen-link left="0%" top="1%" width="11.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <div class="close-button" @click="closeAdminTab">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>
          </div>
        </div>

    </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";
// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "corestream-screens",
  components: { ScreenLink, DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      if(this.$route.query.guided){

        let value = (this.$route.query.guided.trim() === "true");
        console.log("guided param",this.$route.query.guided, value )
        this.$store.commit("guidedMode", value);
      }else{
        this.$store.commit("guidedMode", false);
      }

      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'dashboard',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,
      salesAdded: false,
      commentAdded: false,
      reviewAdded: false,
      reviewTab: "reviews"

    }
  },
  methods: {

    closeTab: function(){
        this.onLinkClick({
          target: {
            dataset: {
              tgtMode: "employee",
              tgt: "dashboard"
            }
          }
        })
    },

    saveComment: function(e){
      this.commentAdded = true;
      this.onLinkClick(e)
    },

    setReviewTab: function(tab){
      this.reviewTab = tab
    },

    closeAdminTab: function(){
      window.close()
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{
        console.log("scroll top", e.target.dataset.tgtScrolltop)
        this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "corestream"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          console.log("scrolling", this.$refs)
          //this.$refs['screens'].scrollTo(0,0);
          window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "hr"){

          this.$router.push({name: "training", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}




.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

.review-goals {
  position: absolute;
  width: calc(390/1580 * 100%);
  margin: 0;
}

.engage-side-nav {
  position: absolute;
  left: 0;
  top: 0;
  width: calc((225/1580) * 100%);
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em 1.5em;
  z-index: 100;


}

.close-button svg {
  fill:white;
  padding: 0.5em;
  background-color: var(--color-green);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 100%;
  border: 2px solid white;
  pointer-events: none;

}

</style>

<style>
  html {
    background: white;
  }
</style>
