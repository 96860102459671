


<template>
<div class="payroll-labor-cost-panel">
  <header class="header">
    <span style="-webkit-font-smoothing: subpixel-antialiased;">Payroll Labor Cost</span>
    <svg viewBox="0 0 4 20" class="editIcon" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><title>tileEdit</title><circle cx="2" cy="2" r="2" fill="#898A8C"></circle><circle cx="2" cy="10" r="2" fill="#898A8C"></circle><circle cx="2" cy="18" r="2" fill="#898A8C"></circle></svg>
    <screen-link left="90.5%" top="1%" width="7%" height="8%" dot-class="dot-right-center" data-tgt="dashboard-manage-tiles" @click.native="showOptions"></screen-link>
  </header>
  <div class="details">
    <p>{{ title }}</p>
    <p class="dates">May 1, 2021 - Apr 30, 2022</p>
    <p class="legend"><span class="blue-dot"></span>My Company</p>
    <p class="amount">{{ total }}</p>
  </div>
  <div style="position:relative;">
    <div class="chart-container" ref="container">
      <svg v-if="chartKey == 'overtimeAmount'" class="chartSVG" width="100%" height="100%" viewBox="0 0 260 260" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><defs><clipPath id="kdef5"><path d="M24 31 L 259 31 259 227 24 227Z" stroke="#000" stroke-linejoin="miter" fill="none"></path></clipPath><clipPath id="kdef6"><path d="M0 0 L 260 0 260 256 0 256Z" stroke="#000" stroke-linejoin="miter" fill="none"></path></clipPath></defs><g>
        <path d="M0 0 L 260 0 260 256 0 256Z" stroke="none" fill="#fff"></path><path d="M24 31 L 259 31 259 227 24 227Z" stroke="none" fill="#fff" fill-opacity="0"></path><g><g></g><g></g><g><g><g>
        <path d="M24.5 227.5 L 259.5 227.5" stroke="#d8d8d8" stroke-width="1" fill="none"></path></g></g></g><g clip-path="url(#kdef5)"><g clip-path="url(#kdef6)">
        <path d="M50 112 L 67 112 87 164 103 110 121 112 140 62 158 106 176 106 194 102 212 105 230 93 247 40 " stroke="#4b6caa" stroke-width="2" fill="none"></path>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="0" cx="50" cy="112" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="1" cx="67" cy="112" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="2" cx="87" cy="164" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="3" cx="103" cy="110" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="4" cx="121" cy="112" r="3.999999999999993" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="5" cx="140" cy="62" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="6" cx="158" cy="106" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="7" cx="176" cy="106" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="8" cx="194" cy="102" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="9" cx="212" cy="105" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="10" cx="230" cy="93" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="11" cx="247" cy="40" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
      </g></g>
        <g>
          <text style="font:12px sans-serif;white-space:pre;" x="34" y="253" stroke="none" fill="#707070" fill-opacity="1">May</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="69.5" y="253" stroke="none" fill="#707070" fill-opacity="1">Jul</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="104" y="253" stroke="none" fill="#707070" fill-opacity="1">Sep</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="142.79149999999998" y="253" stroke="none" fill="#707070" fill-opacity="1">Nov</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="181.95850000000002" y="253" stroke="none" fill="#707070" fill-opacity="1">Jan</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="220.125" y="253" stroke="none" fill="#707070" fill-opacity="1">Mar</text></g><g></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="16" y="231" stroke="none" fill="#707070" fill-opacity="1">$0</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="0" y="187" stroke="none" fill="#707070" fill-opacity="1">$100k</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="0" y="143" stroke="none" fill="#707070" fill-opacity="1">$200k</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="0" y="99" stroke="none" fill="#707070" fill-opacity="1">$300k</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="0" y="55" stroke="none" fill="#707070" fill-opacity="1">$400k</text></g></g><g><g>
        <text style="font:14px sans-serif;white-space:pre;" x="0" y="13" stroke="none" fill="#707070" fill-opacity="1">Check Date</text></g></g><g></g></g></svg>

      <svg v-if="chartKey == 'regHours'" class="chartSVG" width="100%" height="100%" viewBox="0 0 260 260" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><defs><clipPath id="kdef1"><path d="M18 38 L 259 38 259 227 18 227Z" stroke="#000" stroke-linejoin="miter" fill="none"></path></clipPath><clipPath id="kdef2"><path d="M0 0 L 260 0 260 256 0 256Z" stroke="#000" stroke-linejoin="miter" fill="none"></path></clipPath></defs><g><path d="M0 0 L 260 0 260 256 0 256Z" stroke="none" fill="#fff"></path><path d="M18 38 L 259 38 259 227 18 227Z" stroke="none" fill="#fff" fill-opacity="0"></path><g><g></g><g></g><g><g><g><path d="M18.5 227.5 L 259.5 227.5" stroke="#d8d8d8" stroke-width="1" fill="none"></path></g></g></g><g clip-path="url(#kdef1)"><g>
        <path d="M37 108 L 58 109 75 160 95 104 114 105 134 56 153 109 172 110 191 105 210 111 229 100 248 48" stroke="#4b6caa" stroke-width="2" fill="none"></path>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="0" cx="37" cy="108" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="1" cx="58" cy="109" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="2" cx="75" cy="160" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="3" cx="95" cy="104" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="4" cx="114" cy="105" r="3.999999999999993" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="5" cx="134" cy="56" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="6" cx="153" cy="109" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="7" cx="172" cy="110" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="8" cx="191" cy="105" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="9" cx="210" cy="111" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="10" cx="229" cy="100" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>
        <circle @mouseover="onCircleOver" @mouseout="onCircleOut" data-index="11" cx="248" cy="48" r="4" stroke="#4b6caa" stroke-width="2" stroke-opacity="1" fill="#fff" fill-opacity="1"></circle>

      </g></g>
        <g>
          <text style="font:12px sans-serif;white-space:pre;" x="34" y="253" stroke="none" fill="#707070" fill-opacity="1">May</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="69.5" y="253" stroke="none" fill="#707070" fill-opacity="1">Jul</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="104" y="253" stroke="none" fill="#707070" fill-opacity="1">Sep</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="142.79149999999998" y="253" stroke="none" fill="#707070" fill-opacity="1">Nov</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="181.95850000000002" y="253" stroke="none" fill="#707070" fill-opacity="1">Jan</text></g><g>
          <text style="font:12px sans-serif;white-space:pre;" x="220.125" y="253" stroke="none" fill="#707070" fill-opacity="1">Mar</text></g><g></g><g>
        <text style="font:12px sans-serif;white-space:pre;" x="16" y="231" stroke="none" fill="#707070" fill-opacity="1">0</text></g><g>
        <text style="font:12px sans-serif;white-space:pre;" x="10" y="164" stroke="none" fill="#707070" fill-opacity="1">4k</text></g><g>
        <text style="font:12px sans-serif;white-space:pre;" x="10" y="110" stroke="none" fill="#707070" fill-opacity="1">8k</text></g><g>
        <text style="font:12px sans-serif;white-space:pre;" x="6" y="56" stroke="none" fill="#707070" fill-opacity="1">12k</text></g></g><g><g>
        <text style="font:14px sans-serif;white-space:pre;" x="0" y="13" stroke="none" fill="#707070" fill-opacity="1">Check Date</text></g></g><g></g></g></svg>
      <div class="hover-info" ref="hover">
        <span class="hover-date">{{ hoverInfo.date }}</span>
        <span class="hover-amount">{{ hoverInfo.amount }}</span>
      </div>
    </div>
  </div>
  <footer class="footer">
    <span>View Report</span>
  </footer>

  <div class="options" v-if="optionsPanel">
    <header>
      <span>Options</span>
      <svg @click="closeOptions" class="x-icon" viewBox="0 0 14.01 13.91" id="close" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><title>close</title><path d="M8.45 6.95l5.2-5.2a1 1 0 0 0 .1-1.4 1 1 0 0 0-1.4-.1l-.1.1-5.2 5.2-5.2-5.2a1 1 0 0 0-1.4.1 1.05 1.05 0 0 0 0 1.3l5.2 5.2-5.3 5.2a1 1 0 0 0-.1 1.4 1 1 0 0 0 1.4.1l.1-.1 5.3-5.2 5.2 5.2a1 1 0 0 0 1.5-1.3l-.1-.1z" fill="#404040"></path></svg>
    </header>
    <div class="categories" v-if="optionsPanel == 'categories'">
      <div class="option-link">
        <span class="description">Date Range</span>
        <span class="link"><span class="link-label">Previous 12 Months</span> <svg class="chevron-icon" viewBox="0 0 7 18" id="next-arrow" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path fill="#404040" d="M1.792 3.18a.534.534 0 0 0-.798 0l-.796.859a.636.636 0 0 0 0 .858l3.844 4.086-3.876 4.121a.64.64 0 0 0 0 .858l.797.858c.22.238.577.238.799 0l5.073-5.408a.643.643 0 0 0 0-.86L1.792 3.18z"></path></svg></span>
      </div>
      <div class="option-link">
        <span class="description">Measure</span>
        <span class="link" @click="optionsPanel = 'selections'"><span class="link-label">{{ title }}</span> <svg class="chevron-icon" viewBox="0 0 7 18" id="next-arrow" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path fill="#404040" d="M1.792 3.18a.534.534 0 0 0-.798 0l-.796.859a.636.636 0 0 0 0 .858l3.844 4.086-3.876 4.121a.64.64 0 0 0 0 .858l.797.858c.22.238.577.238.799 0l5.073-5.408a.643.643 0 0 0 0-.86L1.792 3.18z"></path></svg></span>
        <screen-link class="pointer-events-none" left="1%" top="45%" width="98%" height="20%" dot-class="dot-right-center" data-tgt="dashboard-manage-tiles"></screen-link>
      </div>
      <div class="option-link">
        <span class="description">Group By</span>
        <span class="link"><span class="link-label">Check Date</span> <svg class="chevron-icon" viewBox="0 0 7 18" id="next-arrow" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path fill="#404040" d="M1.792 3.18a.534.534 0 0 0-.798 0l-.796.859a.636.636 0 0 0 0 .858l3.844 4.086-3.876 4.121a.64.64 0 0 0 0 .858l.797.858c.22.238.577.238.799 0l5.073-5.408a.643.643 0 0 0 0-.86L1.792 3.18z"></path></svg></span>
      </div>
    </div>
    <div class="selections" v-if="optionsPanel == 'selections'">
      <div class="selection">Overtime Hours</div>
      <div class="selection" >Overtime Amount</div>
      <div class="selection" @click="makeSelection('regHours')" :data-selected="title === 'Regular Hours'">Regular Hours</div>
      <div class="selection">Regular Amount</div>
      <div class="selection" @click="makeSelection('overtimeAmount')" :data-selected="title === 'Labor Cost'">Labor Cost</div>
      <div class="selection">Earnings & Reimbursements</div>
      <div class="selection">ER Benefits Cost</div>

      <screen-link class="pointer-events-none" left="1%" top="61%" width="98%" height="11%" dot-class="dot-right-center" data-tgt="dashboard-manage-tiles"></screen-link>
      <screen-link class="pointer-events-none" left="1%" top="36%" width="98%" height="11%" dot-class="dot-right-center" data-tgt="dashboard-manage-tiles"></screen-link>

    </div>
  </div>

</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "payroll-labor-cost-panel",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          optionsPanel: false,
          currentCircleIndex: -1,
          startMonth: 4,
          chartKey: "regHours",
          chartData: {
            months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct","Nov", "Dec"],
            regHours: {
              total: "99,650 hrs",
              title: "Regular Hours",
              legend: ["3k", "2k", "1k", "0"],
              amounts: ["8,010","7,728","8,328","12,373","7,972","7,899","3,948","8,022","7,970","11,748","7,866","7,786"],
              amounts2: ["1,733","2,622","1,324","2,542","1,956","1,306","2,418","1,194","1,773","1,812"]
            },
            overtimeAmount: {
              total: "$3,251,813.45",
              title: "Labor Cost",
              legend: ["$400k","$300k", "$200k", "$100k", "$0"],
              amounts: ["$268,175.82","$265,975.18","$289,909.32","$418,196.08","$249,331.93","$249,155.44","$126,467,63","$250,418.04","$248,052.80","$363,337.90","$262,085.87","$260,712.27"],
            },
          }
        }
        },
    methods: {

      closeOptions: function(){

        this.optionsPanel = false;

      },

      onCircleOver: function(e){
        let chartBox = this.$refs.container.querySelector('.chartSVG').getBoundingClientRect();
        let circleBox = e.target.getBoundingClientRect();
        let hoverEl = this.$refs.hover;
        this.$refs.hover.style.display = "block";
        this.currentCircleIndex = parseInt(e.target.dataset.index);

        hoverEl.style.bottom = 100 - 0.9 * ((((circleBox.y - chartBox.y) + circleBox.height )/ chartBox.height) * 100) +"%";
        hoverEl.style.left = 0.9 * ((((circleBox.x - chartBox.x) + circleBox.width )/ chartBox.width) * 100) +"%";

      },

      onCircleOut: function(){
        this.$refs.hover.style.display = "none";
      },

      showOptions: function(){
        this.optionsPanel = 'categories';
      },

      makeSelection: function(key){
        this.chartKey = key;
        this.closeOptions();
      }
    },
    computed: {

      total: function(){
        return this.chartData[this.chartKey].total;
      },

      title: function(){
        return this.chartData[this.chartKey].title;
      },

      hoverInfo: function(){
        let today = new Date();
        let startYear = today.getFullYear();
        if(this.startMonth > 0){
          startYear = startYear - 1;
        }
        let monthTarget = this.startMonth + this.currentCircleIndex;
        let month = this.chartData.months[monthTarget % 12];
        let amount = this.chartData[this.chartKey].amounts[monthTarget % 12];
        let year = startYear + parseInt(monthTarget/12);


        return {
          date: (month + " " + year),
          amount: amount
        }
      }
    }
}
</script>

<style scoped>


.payroll-labor-cost-panel {
  position: absolute;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.2em;
  padding-bottom: 1.0em;
  border-bottom: 1px solid rgb(226,226,226);
  color: #444444;
  font-size: 1em;
}

.details {
  padding: 1.3em 0;
  margin: 0 1.2em;
  padding-bottom: 1.1em;
  border-bottom: 1px solid rgb(226,226,226);
}

.details p {
  font-size: 0.875em;
  margin: 0;
  margin-bottom: 0.325em;
  color: #777777;
}

.details .blue-dot {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-radius: 100%;
  background-color: #4b6caa;
  margin-right: 1em;
}

.details p.dates {
  margin-bottom: 0.25em;
}

.details p.amount {
  margin-bottom: 0;
  font-size: 1.5em;
  color: #444444;
}

.details p.legend {
  margin-bottom: 0.20em;
}

.chart-container {
  position: relative;
  padding: 1em;
  padding-bottom: 0.1em;
}

.chart-container circle:hover {
  fill: #4b6caa;
}


.footer {
  padding: 0.375em 1.2em;
  padding-top: 1.3em;
  border-top: 1px solid rgb(226,226,226);
  padding-bottom: 1.2em;
  text-align: center;
  font-size: 0.875em;
  color: #4b6caa;
}

.hover-info {
  padding: 0.5em;
  background-color: #4b6caa;
  color: white;
  position: absolute;
  text-align: center;
  margin: 0em 1em;
  display: none;
}

.hover-date {
  display: block;
  white-space: nowrap;

}

.hover-amount {
  display: block;
  white-space: nowrap;


}

.editIcon {
  height: 1.3em;
  width: 0.25em;
  display: inline-block;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 0;
  left: 75%;
  margin-left: -2.25em;
  margin-top: -0.1em;
  background: white;
  width: 19.75em;
  box-sizing: border-box;
  padding: 0.7em 0.9em;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
}

.options header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.1em;
}

.options header .x-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  cursor: pointer;
}

.chevron-icon {
  width: 0.5em;
}

.options .option-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.0em;
}

.options .option-link:last-child {
  padding-bottom: 1.2em;
}

.options .option-link .description {
  font-size: 0.9em;
}

.options .option-link .link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options .option-link .link:hover {
  text-decoration: none;
}

.options .option-link .link-label {
  color: #0369b1;
  font-size: 0.9em;
  margin-right: 0.75em;
}

.selections {
  margin-top: 0.9em;
}

.selection {
  margin: 0 -0.9em;
  padding: 0.9em;
  cursor: pointer;
}

.selection:hover {
 background-color: rgba(0,0,0,.03);
}

.selection[data-selected='true'] {
   color: #0369b1;
   background-color: #e5f0f7;
}

.selection[data-selected='true']:hover {
  background-color: #e5f0f7;
}

.pointer-events-none {
  pointer-events: none;
}

</style>
