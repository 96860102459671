<template>

  <div class="demo-frame">
    <FlexLayout default-screen="dashboard">
      <template #screens>
        <AdminDashboard v-if="currentModule === 'dashboard'" :current-screen="currentScreen"></AdminDashboard>
        <AdminReports v-if="currentModule === 'reports'" :current-screen="currentScreen"></AdminReports>
        <AdminPayroll v-if="currentModule === 'payroll'" :current-screen="currentScreen"></AdminPayroll>
        <AdminHr v-if="currentModule === 'hr'" :current-screen="currentScreen"></AdminHr>
        <AdminDirectory v-if="currentModule === 'people'" :current-screen="currentScreen"></AdminDirectory>
        <AdminAccess v-if="currentModule === 'access'" :current-screen="currentScreen"></AdminAccess>
        <AdminCompanyDetails v-if="currentModule === 'company'" :current-screen="currentScreen"></AdminCompanyDetails>
        <AdminTimeAttendance v-if="currentModule === 'time'" :current-screen="currentScreen"></AdminTimeAttendance>
        <AdminDocuments v-if="currentModule === 'documents'" :current-screen="currentScreen"></AdminDocuments>

        <AdminHiring v-if="currentModule === 'hiring'" :current-screen="currentScreen"></AdminHiring>
        <AdminLedger v-if="currentModule === 'ledger'" :current-screen="currentScreen"></AdminLedger>
        <AdminRetirement v-if="currentModule === 'retirement'" :current-screen="currentScreen"></AdminRetirement>
        <AdminFlock v-if="currentModule === 'flock'" :current-screen="currentScreen" />
        <AdminHealthBenefits v-if="currentModule === 'hb'" :current-screen="currentScreen"></AdminHealthBenefits>

        <AdminSettings v-if="currentModule === 'settings'" :current-screen="currentScreen"></AdminSettings>
        <AdminHelpCenter v-if="currentModule === 'help'" :current-screen="currentScreen"></AdminHelpCenter>

      </template>
      <template #pullouts>



        <live-reports-pullout class="" v-if="$store.state.pulloutPanel=='pullout-live-reports'" @close="$screens.closePullout" @link="$screens.onLinkClick"></live-reports-pullout>
        <quick-reports-pullout class="" v-if="$store.state.pulloutPanel=='pullout-quick-reports'" :init-info="$store.state.panelInitInfo" @close="$screens.closePullout" @link="$screens.onLinkClick"></quick-reports-pullout>
        <labor-cost-threshold-pullout class="" v-if="$store.state.pulloutPanel=='pullout-labor-cost-threshold'" @close="$screens.closePullout" @link="$screens.onLinkClick"></labor-cost-threshold-pullout>
        <analytics-labor-cost-edit-pullout v-if="$store.state.pulloutPanel=='pullout-analytics-labor-edit'" @close="$screens.closePullout" @link="$screens.onLinkClick"></analytics-labor-cost-edit-pullout>

        <reports-share-pullout class="" v-if="$store.state.pulloutPanel=='pullout-reports-share'" @close="$screens.closePullout" @link="$screens.onLinkClick"></reports-share-pullout>
        <reports-compare-pullout class="" v-if="$store.state.pulloutPanel=='pullout-reports-compare'" :settings="adminState" @close="$screens.closePullout" @link="$screens.onLinkClick"></reports-compare-pullout>
        <reports-columns-pullout class="" v-if="$store.state.pulloutPanel=='pullout-reports-columns'" :settings="adminState" @close="$screens.closePullout" @link="$screens.onLinkClick" :currentScreen="currentScreen"></reports-columns-pullout>

        <submit-issue-pullout class="" v-if="$store.state.pulloutPanel=='pullout-submit-issue'" @close="$screens.closePullout" @link="$screens.onLinkClick"></submit-issue-pullout>
        <review-issue-chair-pullout class="" v-if="$store.state.pulloutPanel=='pullout-review-issue-chair'" @close="$screens.closePullout" @link="$screens.onLinkClick"></review-issue-chair-pullout>
        <review-issue-spill-pullout class="" v-if="$store.state.pulloutPanel=='pullout-review-issue-spill'" @close="$screens.closePullout" @link="$screens.onLinkClick"></review-issue-spill-pullout>
        <review-issue-deduction-pullout class="" v-if="$store.state.pulloutPanel=='pullout-review-issue-deduction'" @close="$screens.closePullout" @link="$screens.onLinkClick"></review-issue-deduction-pullout>
        <review-issues-pullout class="" v-if="$store.state.pulloutPanel=='pullout-review-issues'" @close="$screens.closePullout" @link="$screens.onLinkClick" />

        <manage-tiles-pullout class="" v-if="$store.state.pulloutPanel=='pullout-manage-tiles'" @close="$screens.closePullout" @link="$screens.onLinkClick"></manage-tiles-pullout>

        <tools-pullout v-if="$store.state.pulloutPanel == 'pullout-tools'" @external="$screens.showExternalPage" @close="$screens.closePullout"></tools-pullout>
        <documents-pullout v-if="$store.state.pulloutPanel == 'pullout-documents'" @external="$screens.showExternalPage" @close="$screens.closePullout"></documents-pullout>

        <payroll-options-pullout class="" v-if="$store.state.pulloutPanel=='pullout-payroll-center-options'" @close="$screens.closePullout" @link="$screens.onLinkClick"></payroll-options-pullout>
        <edit-check-pullout v-if="$store.state.pulloutPanel=='pullout-edit-check'" @close="$screens.closePullout"></edit-check-pullout>
        <without-checks-pullout v-if="$store.state.pulloutPanel=='pullout-without-checks'" @close="$screens.closePullout"></without-checks-pullout>
        <check-preview-pullout v-if="$store.state.pulloutPanel=='pullout-check-preview'" @close="$screens.closePullout"></check-preview-pullout>
        <post-job-pullout v-if="$store.state.pulloutPanel=='pullout-post-job'" @close="$screens.closePullout"></post-job-pullout>
        <access-role-pullout v-if="$store.state.pulloutPanel == 'pullout-access-role'" :user="$store.state.panelInitInfo" @close="$screens.closePullout" @link="$screens.onLinkClick"></access-role-pullout>
        <role-editor-pullout v-if="$store.state.pulloutPanel == 'pullout-role-editor'" :role="$store.state.panelInitInfo" @close="$screens.closePullout" @link="$screens.onLinkClick"></role-editor-pullout>
        <people-overview-pullout v-if="$store.state.pulloutPanel == 'pullout-people-overview'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
        <retirement-summary-pullout v-if="$store.state.pulloutPanel == 'pullout-retirement-summary'" @close="$screens.closePullout" @link="$screens.onLinkClick"/>
        <worker-final-review-pullout v-if="$store.state.pulloutPanel == 'pullout-worker-final-review'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
        <taa-pullout v-if="$store.state.pulloutPanel == 'pullout-taa'" @close="$screens.closePullout" @link="$screens.onLinkClick" :current-screen="currentScreen" />
        <candidate-pullout v-if="$store.state.pulloutPanel == 'pullout-candidate'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
        <company-details-pullout v-if="$store.state.pulloutPanel == 'pullout-company-details'" @close="$screens.closePullout" @link="$screens.onLinkClick" />

        <ledger-manage-pullout v-if="$store.state.pulloutPanel == 'pullout-manage-ledger'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
        <settings-editor-pullout v-if="$store.state.pulloutPanel == 'pullout-settings-editor'" @close="$screens.closePullout" @link="$screens.onLinkClick" />
        <calendar-event-pullout v-if="$store.state.pulloutPanel == 'pullout-calendar-event'" @close="$screens.closePullout" @link="$screens.onLinkClick"/>
        <people-pullout :show-list="false" v-if="$store.state.pulloutPanel == 'pullout-profile'" @close="$screens.closePullout"></people-pullout>



      </template>
      <template #menus>
        <FlockNavigation />
      </template>

    </FlexLayout>
  </div>


</template>

<script>

import FlexLayout from "@/views/FlexLayout";
import AdminDashboard from "@/components/admin/AdminDashboard";
import AdminSettings from "@/components/admin/AdminSettings";
import AdminCompanyDetails from "@/components/admin/AdminCompanyDetails";
import AdminDocuments from "@/components/admin/AdminDocuments";
import AdminAccess from "@/components/admin/AdminAccess";
import AdminHiring from "@/components/admin/AdminHiring";
import AdminHr from "@/components/admin/AdminHr";
import AdminLedger from "@/components/admin/AdminLedger";
import AdminHelpCenter from "@/components/admin/AdminHelpCenter";
import AdminRetirement from "@/components/admin/AdminRetirement";
import AdminDirectory from "@/components/admin/AdminDirectory";
import AdminHealthBenefits from "@/components/admin/AdminHealthBenefits";
import AdminReports from "@/components/admin/AdminReports";
import AdminPayroll from "@/components/admin/AdminPayroll";
import AdminTimeAttendance from "@/components/admin/AdminTimeAttendance";

import LiveReportsPullout from "@/components/LiveReportsPullout";
import QuickReportsPullout from "@/components/QuickReportsPullout";
import LaborCostThresholdPullout from "@/components/LaborCostThresholdPullout";
import AnalyticsLaborCostEditPullout from "@/components/AnalyticsLaborCostEditPullout";
import ReportsSharePullout from "@/components/ReportsSharePullout";
import ReportsColumnsPullout from "@/components/ReportsColumnsPullout";
import ReportsComparePullout from "@/components/ReportsComparePullout";
import SubmitIssuePullout from "@/components/SubmitIssuePullout";
import ReviewIssueChairPullout from "@/components/ReviewIssueChairPullout";
import ReviewIssueSpillPullout from "@/components/ReviewIssueSpillPullout";
import ReviewIssueDeductionPullout from "@/components/ReviewIssueDeductionPullout";
import ManageTilesPullout from "@/components/ManageTilesPullout";
import ToolsPullout from "@/components/ToolsPullout";
import EditCheckPullout from "@/components/EditCheckPullout";
import WithoutChecksPullout from "@/components/WithoutChecksPullout";
import CheckPreviewPullout from "@/components/CheckPreviewPullout";
import PostJobPullout from "@/components/PostJobPullout";
import AccessRolePullout from "@/components/AccessRolePullout";
import RoleEditorPullout from "@/components/RoleEditorPullout";
// import ProfilePullout from "@/components/ProfilePullout";
import PayrollOptionsPullout from "@/components/PayrollOptionsPullout";
import PeopleOverviewPullout from "@/components/PeopleOverviewPullout";
import RetirementSummaryPullout from "@/components/RetirementSummaryPullout";
import WorkerFinalReviewPullout from "@/components/WorkerFinalReviewPullout";
import AdminFlock from "@/components/admin/AdminFlock";
import FlockNavigation from "@/components/flock/FlockNavigation";
import PeoplePullout from "@/components/PeoplePullout";
import CalendarEventPullout from "@/components/CalendarEventPullout";
import LedgerManagePullout from "@/components/LedgerManagePullout";
import SettingsEditorPullout from "@/components/SettingsEditorPullout";
import ReviewIssuesPullout from "@/components/ReviewIssuesPullout";
import TaaPullout from "@/components/TaaPullout";
import CandidatePullout from "@/components/CandidatePullout";
import DocumentsPullout from "@/components/DocumentsPullout";
import CompanyDetailsPullout from "@/components/CompanyDetailsPullout";





export default {
  name: "AdminExperience",
  props: {
  },
  mounted() {
    if(!this.$store.state.hasShownMenuOnce && (this.currentScreen === this.defaultScreen)){
      this.$store.state.showMenu = true;
      this.$store.state.hasShownMenuOnce = true;
    }
  },
  data:function(){
    return {
      //used in reports-compare-pullout && reports-columns-pullout
      adminState: {
        turnoverCompare: false,
        turnoverColumns: false
      },
      defaultScreen: "dashboard"
    }
  },
  components: {
    CompanyDetailsPullout,
    DocumentsPullout,

    CandidatePullout,
    TaaPullout,
    ReviewIssuesPullout,
    SettingsEditorPullout,
    LedgerManagePullout,
    CalendarEventPullout,
    PeoplePullout,
    FlockNavigation,
    AdminFlock,
    WorkerFinalReviewPullout,
    RetirementSummaryPullout,
    PeopleOverviewPullout,
    PayrollOptionsPullout,
    // ProfilePullout,
    RoleEditorPullout,
    AccessRolePullout,
    PostJobPullout,
    CheckPreviewPullout,
    WithoutChecksPullout,
    EditCheckPullout,
    ToolsPullout,
    ManageTilesPullout,
    ReviewIssueDeductionPullout,
    ReviewIssueSpillPullout,
    ReviewIssueChairPullout,
    SubmitIssuePullout,
    ReportsComparePullout,
    ReportsColumnsPullout,
    ReportsSharePullout,
    AnalyticsLaborCostEditPullout,
    LaborCostThresholdPullout,
    QuickReportsPullout,
    LiveReportsPullout,
    AdminTimeAttendance,
    AdminPayroll,
    AdminReports,
    AdminHealthBenefits,
    AdminDirectory,
    AdminRetirement,
    AdminHelpCenter,
    AdminLedger,
    AdminHr,
    AdminHiring, AdminAccess, AdminDocuments, AdminCompanyDetails, AdminSettings, AdminDashboard, FlexLayout},
  methods: {

  },
  computed: {
    currentModule: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    },

    pulloutPanel: function(){
      return this.$store.state.pulloutPanel;
    },

    overlayPanel: function() {
      return this.$store.state.overlayPanel;
    },

    panelInitInfo: function() {
      return this.$store.state.panelInitInfo;
    },

  }

}
</script>

<style scoped>



</style>