<template>
  <div>
    <div class="screen" data-screen="hb-overview" :data-active="currentScreen=='hb-overview'">
      <img class="w-full max-w-100" src="/img/screens/admin-hb-overview.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-overview" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="5.4%" height="3%" data-tgt="hb-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="25.9%" top="18.5%" width="3.3%" height="3%" data-tgt="hb-plans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="18.5%" width="3.9%" height="3%" data-tgt="hb-history" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hb-overview" :active-companies="{enterprise: true, pro: true}" />

        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Health & Benefits allows a seamless integration of benefits with payroll and the benefit of increased data accuracy, less risk of reporting or calculation errors, reduced administrative activities for administrators, and a single employee record for data storage.</p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="hb-plans" :data-active="currentScreen=='hb-plans'">
      <img class="w-full max-w-100" src="/img/screens/admin-hb-plans.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-overview" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="5.4%" height="3%" data-tgt="hb-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="25.9%" top="18.5%" width="3.3%" height="3%" data-tgt="hb-plans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="18.5%" width="3.9%" height="3%" data-tgt="hb-history" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hb-overview" :active-companies="{enterprise: true, pro: true}" />
      </div>
    </div>

    <div class="screen" data-screen="hb-employees-all" :data-active="currentScreen=='hb-employees-all'">
      <img class="w-full max-w-100" src="/img/screens/admin-hb-employees.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-overview" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="5.4%" height="3%" data-tgt="hb-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="25.9%" top="18.5%" width="3.3%" height="3%" data-tgt="hb-plans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="18.5%" width="3.9%" height="3%" data-tgt="hb-history" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hb-overview" :active-companies="{enterprise: true, pro: true}" />
      </div>
    </div>

    <div class="screen" data-screen="hb-history" :data-active="currentScreen=='hb-history'">
      <img class="w-full max-w-100" src="/img/screens/admin-hb-history.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-overview" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="5.4%" height="3%" data-tgt="hb-employees-all" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="25.9%" top="18.5%" width="3.3%" height="3%" data-tgt="hb-plans" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="18.5%" width="3.9%" height="3%" data-tgt="hb-history" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hb-overview" :active-companies="{enterprise: true, pro: true}" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHealthBenefits",
  props: {
    currentScreen: {}
  }
}
</script>

<style scoped>

</style>