<template>
  <div class="demo-frame">
    <TabletLayout data-tgt="hiring-requisitions-details" data-tgt-mode="admin" @close="$screens.onLinkClick">
      <template #screens>
        <div class="screen" data-screen="applicant-posting" :data-active="currentScreen=='applicant-posting'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-decorator.png?v=10122022" />
          <div class="links">
            <screen-link left="76%" top="1%" width="22%" height="5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-info" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="76%" top="93%" width="22%" height="5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-info" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="applicant-posting-apply-info" :data-active="currentScreen=='applicant-posting-apply-info'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-info.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="21%" width="16%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-login" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>
        <div class="screen" data-screen="applicant-posting-apply-login" :data-active="currentScreen=='applicant-posting-apply-login'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-login.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-info" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="1%" top="19.5%" width="21%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-login-email" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="applicant-posting-apply-login-email" :data-active="currentScreen=='applicant-posting-apply-login-email'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-login-email.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-info" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="1%" top="19.5%" width="21%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-securityq" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-securityq" :data-active="currentScreen=='applicant-posting-apply-securityq'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-securityq.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-login-email" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="44%" top="35%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-register" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-register" :data-active="currentScreen=='applicant-posting-apply-register'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-register.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-securityq" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="44%" top="60%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-resume" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-resume" :data-active="currentScreen=='applicant-posting-apply-resume'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-resume.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-register" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="1%" top="18%" width="17%" height="5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-resume-upload" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-resume-upload" :data-active="currentScreen=='applicant-posting-apply-resume-upload'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-resume-upload.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-resume" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="9%" top="78.5%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-about-you" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-about-you" :data-active="currentScreen=='applicant-posting-apply-about-you'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-about-you.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-resume" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="93%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-how" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="applicant-posting-apply-how" :data-active="currentScreen=='applicant-posting-apply-how'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-how.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-about-you" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="58.5%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-where" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-where" :data-active="currentScreen=='applicant-posting-apply-where'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-where.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-how" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="21%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-data" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-data" :data-active="currentScreen=='applicant-posting-apply-data'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-data.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-where" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="17.5%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-gender" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-gender" :data-active="currentScreen=='applicant-posting-apply-gender'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-gender.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-data" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="37.5%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-race" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-race" :data-active="currentScreen=='applicant-posting-apply-race'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-race.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-gender" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="63.25%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-authorized" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-authorized" :data-active="currentScreen=='applicant-posting-apply-authorized'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-authorized.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-race" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="27.5%" width="16%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-is18" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-is18" :data-active="currentScreen=='applicant-posting-apply-is18'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-is18.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-authorized" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="26%" width="16%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-education" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-education" :data-active="currentScreen=='applicant-posting-apply-education'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-education.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-is18" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="56%" width="16%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-experience" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-experience" :data-active="currentScreen=='applicant-posting-apply-experience'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-experience.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-education" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="42.5%" width="16%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-why" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-why" :data-active="currentScreen=='applicant-posting-apply-why'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-why.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-experience" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="41%" width="16%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-submit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-submit" :data-active="currentScreen=='applicant-posting-apply-submit'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-submit.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-why" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="44.5%" top="30%" width="12%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-video" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-video" :data-active="currentScreen=='applicant-posting-apply-video'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-video.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-submit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3%" top="58%" width="17%" height="6%" dot-class="dot-bottom-center" data-tgt="applicant-posting-apply-education-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-education-details" :data-active="currentScreen=='applicant-posting-apply-education-details'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-education-details.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-video" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="90%" top="17%" width="6%" height="5%"  dot-class="dot-top-center" data-tgt="applicant-posting-apply-education-details-edit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2.5%" top="34%" width="28%" height="5.5%" dot-class="dot-bottom-center" data-tgt="applicant-posting-apply-experience-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-education-details-edit" :data-active="currentScreen=='applicant-posting-apply-education-details-edit'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-education-details-edit.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-education-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3.5%" top="74%" width="20%" height="5.5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-education-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-experience-details" :data-active="currentScreen=='applicant-posting-apply-experience-details'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-experience-details.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-education-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="90%" top="21%" width="6%" height="5%"  dot-class="dot-bottom-center" data-tgt="applicant-posting-apply-experience-details-edit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="2.5%" top="38.5%" width="28%" height="5.5%" dot-class="dot-bottom-center" data-tgt="applicant-posting-apply-skills" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-experience-details-edit" :data-active="currentScreen=='applicant-posting-apply-experience-details-edit'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-experience-details-edit.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-experience-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3.5%" top="118%" width="20%" height="5.5%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-experience-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-skills" :data-active="currentScreen=='applicant-posting-apply-skills'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-skills.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-experience-details-edit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3.5%" top="22%" width="28%" height="4%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-skills-office" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-skills-office" :data-active="currentScreen=='applicant-posting-apply-skills-office'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-skills-office.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-experience-details" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="70%" top="61%" width="12%" height="4.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-certifications" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-certifications" :data-active="currentScreen=='applicant-posting-apply-certifications'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-certifications.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-skills-office" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="67%" top="64.5%" width="16%" height="4.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-schedule" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="applicant-posting-apply-schedule" :data-active="currentScreen=='applicant-posting-apply-schedule'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-schedule.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-certifications" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="21%" top="34%" width="58%" height="26%" dot-class="dot-left-center" data-tgt="applicant-posting-apply-schedule-edit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-schedule-edit" :data-active="currentScreen=='applicant-posting-apply-schedule-edit'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-schedule-edit.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-certifications" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="67%" top="62.5%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-schedule-filled" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-schedule-filled" :data-active="currentScreen=='applicant-posting-apply-schedule-filled'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-schedule-filled.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-schedule-edit" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="67%" top="43%" width="14%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-review" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-review" :data-active="currentScreen=='applicant-posting-apply-review'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-review.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-schedule-filled" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="3%" top="41%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-additional" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-additional" :data-active="currentScreen=='applicant-posting-apply-additional'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-additional.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-review" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="75%" top="40.5%" width="14%" height="6%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-accept" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-accept" :data-active="currentScreen=='applicant-posting-apply-accept'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-accept.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="1%" width="11%" height="5%"  dot-class="dot-left-center" data-tgt="applicant-posting-apply-additional" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="21%" top="85.75%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-thankyou" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-thankyou" :data-active="currentScreen=='applicant-posting-apply-thankyou'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-thankyou.png?v=10122022" />
          <div class="links">
            <screen-link left="3%" top="23%" width="11%" height="4.5%" dot-class="dot-right-center" data-tgt="applicant-posting-apply-success" data-tgt-scrolltop="true" data-tgt-mode="applicant" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="applicant-posting-apply-success" :data-active="currentScreen=='applicant-posting-apply-success'">
          <img class="w-full max-w-100" src="/img/screens/applicant/applicant-posting-apply-success.png?v=10122022" />
          <div class="links">
          </div>
        </div>

      </template>
    </TabletLayout>
  </div>
</template>

<script>
import TabletLayout from "@/views/TabletLayout";
export default {
  name: "ApplicantExperience",
  components: {TabletLayout},
  computed: {
    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    }
  }
}
</script>

<style scoped>

</style>