<template>
  <div class="input-field">
    <input ref="inputRef" class="input" :class="`${inputVal?'':'no-value'}`" :type="inputType?inputType:'text'" :name="id" :id="id" v-model="inputVal" style=""/>
    <label class="input-label" :class="`${inputVal?'has-value':''}`">{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  name: "LoginInput",
  props: ['value','placeholder','id','inputType'],
  data: function(){
    return {}
  },
  mounted() {
    setTimeout(()=>{
      if(this.$refs.inputRef.value){
        this.inputVal = this.$refs.inputRef.value
      }
      this.$refs.inputRef.classList.add("visible")
    },1000)
  },
  methods: {
    onFocus: function(){

    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val){
        this.$emit('input',val)
      }
    }
  }
}
</script>

<style scoped>

 .input-field {
   margin: 16px 0 8px 0;
   position: relative;
   height: 48px;
   display: inline-flex;
   align-items: flex-end;
   width: 100%;
 }

  .input {
    -webkit-font-smoothing: antialiased;
    display: block;
    width: 100%;
    font-size: 14px;
    padding-bottom: 8px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    outline: none;


  }

 input:-webkit-autofill,
 input:-webkit-autofill:focus {
   transition: background-color 600000s 0s, color 600000s 0s;
 }

  .input-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    position: absolute;
    bottom: 8px;
    transition: 180ms all;
    transform-origin: 0 0;
    pointer-events: none;
  }

  .input-field:focus-within .input-label  {
    transform: translateY(-100%) scale(0.75);

    color: rgb(0, 86, 160)

  }



 .input-field .input-label.has-value  {
   transform: translateY(-100%) scale(0.75);

   color: rgb(0, 86, 160)

 }

 .input:-webkit-autofill ~ label {
   transform: translateY(-100%) scale(0.75);
   color: rgb(0, 86, 160)
 }





</style>