


<template>
<div class="reports-share-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="reports-share-list">


    <div class="reports-share-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/hr/pullouts/admin-human-resources-calendar-add-event.png?v=10122022" />
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link v-if="!admins" left="4.5%" top="18.5%" width="90%" height="4%" data-tgt="details" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="reports-share-scroll" v-if="detailPanel === 'details'">
      <img class="w-full" src="/img/screens/hr/pullouts/admin-human-resources-calendar-add-event-filled.png?v=10122022" />
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="hr-calendar-event-added" dot-class="dot-bottom-center"  @click.native="onLinkClick"></screen-link>
      </div>
    </div>



  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "calendar-event-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null,
          admins: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.reports-share-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.threshold-30 {
  position: absolute;
}

.threshold-30 label {
  font-size: 0.9em;
  opacity: 0.4;
}

.threshold-30 .amount {
  display: block;
  font-size: 1.2em;
}

.reports-share-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.reports-share-pullout img {
  display: block;
}

.reports-share-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.reports-share-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.reports-share-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

.save-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.admins-img {
  display: inline-block;
  width: calc((532/1580) * 100%);
  position: absolute;
  left: 25%;
  top: 5%;
  background:white;
  padding: 1em;
  border-radius: 0.3em;
  box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

</style>
