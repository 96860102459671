<template>
    <div class="filters-panel" :style="panelStyle">
      <img class="w-full" src="/img/screens/documents/overlays/documents-add-options.png" />
      <screen-link left="3%" top="9%" width="90%" height="40%" dot-class="dot-left-center" data-tgt="pullout-documents" data-init-info="add-document" @click.native="showPullout"></screen-link>
      <screen-link left="3%" top="51%" width="90%" height="40%" dot-class="dot-left-center" data-tgt="documents-add-form" data-init-info="" @click.native="onLinkClick"></screen-link>
    </div>
</template>

<script>

    //import ScreenLink from "@/components/ScreenLink";
    export default {
        name: "document-add-options-panel",
        components: {},
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

          selection: {
            default: ""
          }
        },
        data: function () {
            return {

            }
        },
        methods: {
            showPullout: function(e){
              console.log("select",this.onSelect)
              this.$emit("select")
              this.$screens.showPullout(e)
            },
          onLinkClick: function(e){

              console.log("select",this.onSelect)
              this.$emit("select")
            this.$screens.onLinkClick(e)
          }
        },
        computed: {
            panelStyle: function(){
                return {
                    top: this.top,
                    left: this.left
                }
            },


        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        width: calc(209/1580 * 100%);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
      user-select: none;
    }

    .v-center {
      display: flex;
      align-items: center;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    span {
      user-select: none;
    }


    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

</style>
