


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='time-summary'" >
          <img class="w-full" src="/img/screens/taa/pullover-timesummary.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="4%" top="52%" width="92%" height="5%" data-tgt="time-summary-expanded" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>

    </div>
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='time-summary-expanded'" >
          <img class="w-full" src="/img/screens/taa/pullover-timesummary-expand.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="4%" top="52%" width="92%" height="5%" data-tgt="time-summary" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>

      </div>
      <div class="detail-panel" v-if="detailPanel">
      <div class="detail-content" :data-active="detailPanel=='customize-graph'" >
          <img class="w-full" src="/img/screens/taa/pullover-overview-graph.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="4%" top="27%" width="92%" height="5%" data-tgt="customize-graph-options" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
      </div>
        <div class="detail-content" :data-active="detailPanel=='customize-graph-options'" >
          <img class="w-full" src="/img/screens/taa/pullover-overview-graph-drop.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="87%" top="27%" width="6%" height="5%" data-tgt="customize-graph" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='status-options'" >
          <img class="w-full" src="/img/screens/taa/pullover-time-more-options.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="3%" top="9%" width="94%" height="9%" data-tgt="display-prefs" dot-class="dot-right-center" data-is-overlay="true" data-tgt-scrolltop="true" @click.native="showDetails"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='display-prefs'" >
          <img class="w-full" src="/img/screens/taa/pullover-time-more-display.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='batch-actions'" >
          <img class="w-full" src="/img/screens/taa/pullover-time-attendance-timeoff-bulk.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='week-editshift'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-week-editshift.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="16%" width="13%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-left-center"  @click.native="showDuplicateOptions = !showDuplicateOptions"></screen-link>
            <basic-options-panel v-if="showDuplicateOptions" :items="[
              {label:'Duplicate shift on different days', value:'week-duplicate', screenLink: true},
                {label:'Duplicate shift to other employees', value:'toOther'}
            ]" :on-select="onDuplicateShift" width="45%" top="15%" left="51%" />

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='week-duplicate'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-week-duplicate.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="4%" top="38%" width="45%" height="5%" data-tgt="week-duplicate-ready" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='week-duplicate-ready'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-week-duplicate-ready.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="82%" top="1%" width="15%" height="5%" data-tgt="time-attendance-schedules-week" dot-class="dot-bottom-center"  @click.native="onShiftDuplicated"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='add-shift'" >
          <img class="w-full" src="/img/screens/taa/pullover-addshift.png?v=08092023" />
          <div class="links">

            <screen-link left="82%" top="1%" width="15%" height="5%" data-tgt="time-attendance-schedules" dot-class="dot-bottom-center"  @click.native="onShiftAdded"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='day-overlap'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-day-overlap.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="73%" top="37%" width="14%" height="5%" data-tgt="day-overlap-time" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='day-overlap-time'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-day-overlap-time.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="82%" top="1%" width="15%" height="5%" data-tgt-scrolltop="true"  data-tgt="time-attendance-schedules-add-overlap-warning" dot-class="dot-bottom-center"  @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='day-overlap-delete'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-day-overlap-delete.png?v=08092023" />
          <div class="links">
            <screen-link left="69.5%" top="1%" width="13%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="54%" top="1%" width="15%" height="4%" data-tgt="time-attendance-schedules" dot-class="dot-left-center"  @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='view-timecard-summary'" >
          <img v-if="$store.state.tasksCompleted.fixTimePunch" class="w-full" src="/img/screens/taa/pullouts/pullover-view-timecard-summary.png?v=08092023" />
          <img v-else class="w-full" src="/img/screens/taa/pullouts/pullover-view-timecard-summary-missing-punch.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="3%" top="8%" width="13%" height="5%" data-tgt="view-timecard-summary" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="16%" top="8%" width="13%" height="5%" data-tgt="view-timecard-approvals" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link v-if="!$store.state.tasksCompleted.fixTimePunch" left="3%" top="58%" width="92%" height="15%" data-tgt="fix-timepunch" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
          </div>
        </div>


        <div class="detail-content" :data-active="detailPanel=='view-timecard-approvals'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-view-timecard-approvals.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="3%" top="8%" width="13%" height="5%" data-tgt="view-timecard-summary" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="16%" top="8%" width="13%" height="5%" data-tgt="view-timecard-approvals" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='view-timecard-summary-baley'" >
          <img v-if="$store.state.taa.baleyRangeApproved" class="w-full" src="/img/screens/taa/pullouts/pullover-view-timecard-summary-baley-approved.png?v=08092023" />
          <img v-else class="w-full" src="/img/screens/taa/pullouts/pullover-view-timecard-summary-baley.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="time-attendance-schedules-addshift-success" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="70%" top="1%" width="22%" height="5%" data-tgt="view-timecard-summary-baley-approved" dot-class="dot-left-center"  @click.native="approveBaleyRange"></screen-link>
          </div>
        </div>


        <div class="detail-content" :data-active="detailPanel=='change-approval-role'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-change-role.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='add-template'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-add-template.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="3%" top="19%" width="95%" height="15%" data-tgt="add-template-save" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='add-template-save'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullover-add-template-save.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>
            <screen-link left="82%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='approve-time-off'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullout-time-approve-time-off.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="76%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="approveTimeOff"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='fix-timepunch'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullout-time-fix-timepunch.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="6%" top="91%" width="90%" height="5%" data-tgt="fix-timepunch-note" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='fix-timepunch-note'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullout-time-fix-timepunch-note.png?v=08092023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="82.5%" top="1%" width="15%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="fixTimePunch"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='review-exception'" >
          <img class="w-full" src="/img/screens/taa/pullouts/pullout-time-review-exception.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="71%" top="1%" width="20%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="reviewException"></screen-link>

          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='time-off-day'" >
          <img class="w-full" src="/img/screens/taa/pullover-individual-day.png?v=08092023" />
          <div class="links">
            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>
    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import BasicOptionsPanel from "@/components/BasicOptionsPanel";


export default {
    name: "taa-pullout",
  components: {BasicOptionsPanel, ScreenLink},
  props: {
      employeeState: {},
      currentScreen: {},
  },
    data: function(){
        return {
          detailPanel: "loan-terms",
          showOptions: false,
          showDuplicateOptions: false
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      onShiftAdded: function(e){
        this.$store.state.taa.shiftAdded = true;
        this.$screens.onLinkClick(e)
      },

      onShiftDuplicated: function(e){
        this.$store.state.taa.shiftDuplicated = true;
        this.$screens.onLinkClick(e)
      },

      onDuplicateShift: function(val){
        console.log('duplicate-shift',val);
        this.showDuplicateOptions = false;
        if(val.screenLink){
          this.showDetails({
            target: {
              dataset: {
                tgt: val.value,
                tgtScrolltop: true
              }
            }
          })
        }
      },

      approveTimeOff: function() {
        this.$store.commit("approveTimeOff",true);
        this.$emit('close');
      },

      fixTimePunch: function() {
        this.$store.commit("fixTimePunch",true);
        this.$store.commit("reviewException",true);
        this.$emit('close');
        if(this.currentScreen === 'time-attendance-time-cards-filtered'){
            this.$screens.onLinkClick({
              target: {
                dataset: {
                  tgt: "time-attendance-time-cards",
                  tgtMode: "admin"
                }
              }
            })
        }
      },

      reviewException: function() {
        this.$store.commit("reviewException",true);
        this.$emit('close');
      },

      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },

      approveBaleyRange: function(){
        this.$store.state.taa.baleyRangeApproved = true;
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        if(e.target.dataset.tgtScrolltop){
          this.$refs['basicScroll'].scrollTo(0,0);
        }



      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
