


<template>
<div class="pullout-panel">
  <div class="bkg"></div>
  <div class="list">


    <div class="scroll" :data-active="!detailPanel">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="16%" width="14%" height="6%" data-tgt="edit" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edit'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="4%" top="84%" width="92%" height="6%" data-tgt="edit-documents" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="83%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>


    <div class="scroll" :data-active="detailPanel == 'edit-documents'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-documents.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="70%" top="92%" width="25%" height="5%" data-tgt="edit-admin-options" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="scroll" :data-active="detailPanel == 'edit-admin-options'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-admin-options.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="72%" top="109%" width="13%" height="5%" data-tgt="edit-admin-full" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edit-admin-full'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-admin-full.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="68%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="82%" top="101.7%" width="13%" height="5%" data-tgt="edit-restricted" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edit-restricted'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-restricted.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="68%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="82%" top="106.7%" width="13%" height="5%" data-tgt="edit-forms" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edit-forms'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-forms.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="68%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="74%" top="98%" width="21%" height="5%" data-tgt="edit-people-options" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edit-people-options'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edit-people-options.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="68%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="74%" top="109%" width="13%" height="5%" data-tgt="edited" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'edited'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-edited.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="68%" top="16%" width="14%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="83%" top="16%" width="14%" height="6%" data-tgt="updated" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'updated'">
      <img class="w-full" src="/img/screens/pullout-admin-user-access-role-updated.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "role-editor-pullout",
  components: {ScreenLink},
  props: {
      role:{
        type: String,
        default: ""
      }
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {

      if(this.role){
        this.detailPanel = this.role
      }else{
        if(this.$route.query.level !== undefined){
          this.detailPanel = this.$route.query.level;
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.pullout-panel {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);
}

.pullout-panel .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.pullout-panel img {
  display: block;
}

.links {

}


.list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  overflow-y: scroll;


}



.scroll {
  min-height: 100%;
  display: none;
}

.scroll[data-active="true"]{
  display: block;
}


.w-full {
  width: 100%;
}

.links {

}

</style>
