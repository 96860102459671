<template>
  <div class="demo-frame">
    <TabletLayout data-tgt="hiring-v2-candidates" data-tgt-mode="admin" @close="exitExperience">

      <template #screens>
        <div class="screen" data-screen="candidate-posting" :data-active="currentScreen=='candidate-posting'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-posting.png?v=09142023" />
          <div class="links">
            <screen-link left="76%" top="20.75%" width="18%" height="5%" dot-class="dot-left-center" data-tgt="candidate-resume" data-tgt-mode="candidate" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="2%" top="14.5%" width="18%" height="4%" dot-class="dot-left-center" data-tgt="candidate-job-portal" data-tgt-mode="candidate" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="candidate-resume" :data-active="currentScreen=='candidate-resume'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-resume.png?v=09142023" />
          <div class="links">
            <screen-link left="11%" top="54%" width="78%" height="14%" dot-class="dot-bottom-center" data-tgt="candidate-resume-uploaded" data-tgt-mode="candidate" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="candidate-resume-uploaded" :data-active="currentScreen=='candidate-resume-uploaded'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-resume-uploaded.png?v=09142023" />
          <div class="links">
            <screen-link left="78%" top="114%" width="14%" height="6%" dot-class="dot-top-center" data-tgt="candidate-personal-info" data-tgt-mode="candidate" data-tgt-scrolltop="true"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="candidate-personal-info" :data-active="currentScreen=='candidate-personal-info'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-personal-info.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="97%" width="12%" height="6%" dot-class="dot-top-center" data-tgt="candidate-resume-uploaded" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="78%" top="97%" width="14%" height="6%" dot-class="dot-top-center" data-tgt="candidate-education" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-education" :data-active="currentScreen=='candidate-education'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-education.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="204%" width="12%" height="6%" dot-class="dot-top-center" data-tgt="candidate-personal-info" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="10%" top="145%" width="80%" height="6%" dot-class="dot-left-center" data-tgt="candidate-reference" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-reference" :data-active="currentScreen=='candidate-reference'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-reference.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="204%" width="12%" height="6%" dot-class="dot-top-center" data-tgt="candidate-personal-info" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="71%" top="204%" width="20%" height="6%" dot-class="dot-top-center" data-tgt="candidate-submitted" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-submitted" :data-active="currentScreen=='candidate-submitted'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-submitted.png?v=09142023" />
          <div class="links">
            <screen-link left="39%" top="40.5%" width="22.5%" height="6%" dot-class="dot-top-center" data-tgt="candidate-job-portal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-job-portal" :data-active="currentScreen=='candidate-job-portal'">
          <img class="w-full max-w-100" src="/img/screens/candidate/candidate-job-portal.png?v=09142023" />
          <div class="links">
            <screen-link left="4%" top="40%" width="91.5%" height="9%" dot-class="dot-right-center" data-tgt="candidate-posting" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div v-if="$store.state.onboardingType === 'peo'" class="screen" data-screen="candidate-onboarding-start" :data-active="currentScreen=='candidate-onboarding-start'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-start-peo.png?v=02192024" />
          <div class="links">
            <div v-if="onboardingTasks.profile" class="review-button" style="left:83.5%; top:42.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.employment" class="review-button" style="left:83.5%; top:51%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.wotc" class="review-button" style="left:83.5%; top:59.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.i9" class="review-button" style="left:83.5%; top:68%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.banking" class="review-button" style="left:83.5%; top:76.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.federal" class="review-button" style="left:83.5%; top:84.9%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.state" class="review-button" style="left:83.5%; top:93.25%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.selfid" class="review-button" style="left:83.5%; top:101.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.contacts" class="review-button" style="left:83.5%; top:110.4%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>

            <div v-if="onboardingTasks.handbook && onboardingTasks.parking" class="review-button" style="left:83.5%; top:118.7%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>

            <screen-link left="83%" top="42%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-profile" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="50.5%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-employment" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="59%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="67.5%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="76%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="84.4%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-federal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="92.75%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-state" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="101%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-selfid" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="109.7%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-contacts" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="118.3%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="126.5%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="dashboard" data-tgt-mode="employee" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div v-else class="screen" data-screen="candidate-onboarding-start" :data-active="currentScreen=='candidate-onboarding-start'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-start.png?v=02192024" />
          <div class="links">
            <div v-if="onboardingTasks.profile" class="review-button" style="left:83.5%; top:42.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.wotc" class="review-button" style="left:83.5%; top:51%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.i9" class="review-button" style="left:83.5%; top:59.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.banking" class="review-button" style="left:83.5%; top:68%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.handbook && onboardingTasks.parking" class="review-button" style="left:83.5%; top:76.5%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.federal" class="review-button" style="left:83.5%; top:84.9%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.state" class="review-button" style="left:83.5%; top:93.25%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.selfid" class="review-button" style="left:83.5%; top:101.9%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>
            <div v-if="onboardingTasks.contacts" class="review-button" style="left:83.5%; top:110.4%;">
              <img src="/img/screens/candidate/onboarding/cutouts/review-button.png?v=09142023" />
            </div>

            <div v-if="$store.state.overlayPanel === 'profile-menu'" class="profile-menu"  style="left:70%; top:8%;">
              <img src="/img/screens/candidate/onboarding/cutouts/profile-menu.png?v=09142023" />
            </div>

            <screen-link left="91%" top="1%" width="7%" height="6%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-profile" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="toggleMenu"></screen-link>
            <screen-link v-if="$store.state.overlayPanel === 'profile-menu'" left="71%" top="20.5%" width="20%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-prefs" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="83%" top="42%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-profile" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="50.5%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="59%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="67.5%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="76%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="84.4%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-federal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="92.75%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-state" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="101.2%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-selfid" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="109.7%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-contacts" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="118.2%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="dashboard" data-tgt-mode="employee" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs" :data-active="currentScreen=='candidate-onboarding-prefs'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-user-prefs.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-sidebar" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="6%" top="38.5%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-languages" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs-sidebar" :data-active="currentScreen=='candidate-onboarding-prefs-sidebar'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-sidebar.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="12.5%" width="16%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs-languages" :data-active="currentScreen=='candidate-onboarding-prefs-languages'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-user-prefs-languages.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-sidebar" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="6%" top="44.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-languages-spanish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs-languages-spanish" :data-active="currentScreen=='candidate-onboarding-prefs-languages-spanish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-user-prefs-languages-spanish.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-sidebar" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="84%" top="19.2%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-spanish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs-spanish" :data-active="currentScreen=='candidate-onboarding-prefs-spanish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-user-prefs-spanish.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-spanish-sidebar" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="6%" top="38.5%" width="15%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-spanish-prefs-to-english" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-spanish-prefs-to-english" :data-active="currentScreen=='candidate-onboarding-spanish-prefs-to-english'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-spanish-user-prefs-to-english.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="1%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs-spanish-sidebar" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="84%" top="19.2%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-prefs" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-prefs-spanish-sidebar" :data-active="currentScreen=='candidate-onboarding-prefs-spanish-sidebar'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-user-prefs-spanish-sidebar.png?v=09142023" />
          <div class="links">
            <screen-link left="1%" top="12.5%" width="16%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-start-spanish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-start-spanish" :data-active="currentScreen=='candidate-onboarding-start-spanish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-start-spanish.png?v=09142023" />

          <div class="back-to-english" style="top: 5%; left: 40%;" >
            <span>Back to English</span>
          </div>

          <div class="links">
            <div v-if="$store.state.overlayPanel === 'profile-menu'" class="profile-menu"  style="left:70%; top:8%;">
              <img src="/img/screens/candidate/onboarding/cutouts/profile-menu-spanish.png?v=09142023" />
            </div>



            <screen-link left="40%" top="5%" width="23%" height="5.5%" dot-class="dot-right-center" show-dot="false" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="91%" top="1%" width="7%" height="6%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-profile" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="toggleMenu"></screen-link>
            <screen-link v-if="$store.state.overlayPanel === 'profile-menu'" left="71%" top="20.5%" width="20%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-prefs-spanish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-profile" :data-active="currentScreen=='candidate-onboarding-profile'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-profile.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="25%" top="50.5%" width="70%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-profile-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-profile-details" :data-active="currentScreen=='candidate-onboarding-profile-details'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-profile-details.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="86%" top="8%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-profile-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-profile-confirmation" :data-active="currentScreen=='candidate-onboarding-profile-confirmation'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-profile-confirmation.png?v=09142023" />
          <div class="links">
            <screen-link left="65%" top="27%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="profile" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment" :data-active="currentScreen=='candidate-onboarding-employment'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-employment-disclosure-drawer-1.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="82%" top="42.5%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-employment-disclosure" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-disclosure" :data-active="currentScreen=='candidate-onboarding-employment-disclosure'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-employment-disclosure-stage-page1.png?v=09142023" />
          <div class="links">
            <screen-link left="55%" top="29%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-2" data-tgt-mode="candidate" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="55%" top="101%" width="14%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-employment" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-disclosure-2" :data-active="currentScreen=='candidate-onboarding-employment-disclosure-2'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-employment-disclosure-stage-page2.png?v=09142023" />
          <div class="links">
            <screen-link left="37%" top="29%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="59%" top="101%" width="13%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72.5%" top="101%" width="19%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-disclosure-sign" :data-active="currentScreen=='candidate-onboarding-employment-disclosure-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-employment-disclosure-stage-sign-modal.png?v=09142023" />
          <div class="links">
            <screen-link left="88%" top="31%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-2" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72.5%" top="59%" width="21%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-signed" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-disclosure-signed" :data-active="currentScreen=='candidate-onboarding-employment-disclosure-signed'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-employment-disclosure-drawer-2.png?v=09142023" />
          <div class="links">
            <screen-link left="92%" top="8%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-2" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="81%" top="51%" width="16%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-aca" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-aca" :data-active="currentScreen=='candidate-onboarding-employment-aca'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-aca-exchange-notice-stage-page1.png?v=09142023" />
          <div class="links">
            <screen-link left="61%" top="110%" width="12%" height="7%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-disclosure-signed" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="73%" top="110%" width="18%" height="7%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-aca-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-employment-aca-sign" :data-active="currentScreen=='candidate-onboarding-employment-aca-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-aca-exchange-notice-stage-sign-modal.png?v=09142023" />
          <div class="links">
            <screen-link left="88.5%" top="31%" width="6%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-employment-aca" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="73%" top="58%" width="20%" height="7%" dot-class="dot-top-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" data-task-id="employment" @click.native="completeTask"></screen-link>
          </div>
        </div>


        <div class="screen" data-screen="candidate-onboarding-wotc" :data-active="currentScreen=='candidate-onboarding-wotc'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="60%" top="59%" width="20%" height="5.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-1" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-1" :data-active="currentScreen=='candidate-onboarding-wotc-survey-1'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-1.png?v=09142023" />
          <div class="links">
            <screen-link left="50%" top="42.5%" width="25%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-2" :data-active="currentScreen=='candidate-onboarding-wotc-survey-2'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-2.png?v=09142023" />
          <div class="links">
            <screen-link left="25%" top="27.25%" width="25%" height="4.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-wotc-survey-1" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="27.25%" width="25%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-3" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-3" :data-active="currentScreen=='candidate-onboarding-wotc-survey-3'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-3a.png?v=09142023" />
          <div class="links">
            <screen-link left="25%" top="31.75%" width="25%" height="4.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-wotc-survey-2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="11%" width="34%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-3b" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-3b" :data-active="currentScreen=='candidate-onboarding-wotc-survey-3b'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-3b.png?v=09142023" />
          <div class="links">
            <screen-link left="25%" top="31.75%" width="25%" height="4.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-wotc-survey-2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="31.75%" width="25%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-4" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-4" :data-active="currentScreen=='candidate-onboarding-wotc-survey-4'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-4.png?v=09142023" />
          <div class="links">
            <screen-link left="25%" top="62%" width="25%" height="4.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-wotc-survey-3" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="62%" width="25%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-5" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-5" :data-active="currentScreen=='candidate-onboarding-wotc-survey-5'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-5.png?v=09142023" />
          <div class="links">
            <screen-link left="25%" top="49.75%" width="25%" height="4.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-wotc-survey-4" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="49.75%" width="25%" height="4.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-survey-6" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-survey-6" :data-active="currentScreen=='candidate-onboarding-wotc-survey-6'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-survey-6.png?v=09142023" />
          <div class="links">
            <screen-link left="28%" top="26%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="candidate-onboarding-wotc-completed" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-wotc-completed" :data-active="currentScreen=='candidate-onboarding-wotc-completed'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-wotc-completed.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="wotc" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
          </div>
        </div>


        <div class="screen" data-screen="candidate-onboarding-i9" :data-active="currentScreen=='candidate-onboarding-i9'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-i9.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="53%" top="46%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-section1" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>



        <div class="screen" data-screen="candidate-onboarding-i9-section1" :data-active="currentScreen=='candidate-onboarding-i9-section1'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-onboarding-i9-section1.png?v=09142023" />
          <div class="links">
            <screen-link left="79%" top="107%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-citizenship" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-citizenship" :data-active="currentScreen=='candidate-onboarding-i9-citizenship'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-citizenship.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="57%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-section1" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="79%" top="57%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-preparor" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-preparor" :data-active="currentScreen=='candidate-onboarding-i9-preparor'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-preparor.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="52.5%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-citizenship" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="80%" top="52.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-confirmation" :data-active="currentScreen=='candidate-onboarding-i9-confirmation'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-confirmation.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="121%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-preparor" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="79%" top="121%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-signature" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-signature" :data-active="currentScreen=='candidate-onboarding-i9-signature'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-signature.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="56.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="24%" top="45%" width="60%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-signature-finish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-signature-finish" :data-active="currentScreen=='candidate-onboarding-i9-signature-finish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-signature-finish.png?v=09142023" />
          <div class="links">
            <screen-link left="48%" top="34.5%" width="17%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-i9-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="65%" top="34.5%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-submission-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-submission-confirmation" :data-active="currentScreen=='candidate-onboarding-i9-submission-confirmation'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-submission-confirmation.png?v=09142023" />
          <div class="links">
            <screen-link left="79%" top="82%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-manager" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>



        <div class="screen" data-screen="candidate-onboarding-i9-manager" :data-active="currentScreen=='candidate-onboarding-i9-manager'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-manager.png?v=09142023" />
          <div class="links">
            <screen-link left="38%" top="45.5%" width="24%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-i9-complete" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-i9-complete" :data-active="currentScreen=='candidate-onboarding-i9-complete'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-i9-complete.png?v=09142023" />
          <div class="links">
            <screen-link left="94%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="i9" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking" :data-active="currentScreen=='candidate-onboarding-banking'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="25%" top="83%" width="70%" height="30%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-details" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-details" :data-active="currentScreen=='candidate-onboarding-banking-details'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-details.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="86.5%" top="8%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking-confirmation" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-confirmation" :data-active="currentScreen=='candidate-onboarding-banking-confirmation'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-confirmation.png?v=09142023" />
          <div class="links">
            <screen-link left="53%" top="31.5%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="65.75%" top="31.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking-form" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-form" :data-active="currentScreen=='candidate-onboarding-banking-form'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-form.png?v=09142023" />
          <div class="links">
            <screen-link left="81%" top="18%" width="5%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="59%" top="24.5%" width="17%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking-form-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-form-sign" :data-active="currentScreen=='candidate-onboarding-banking-form-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-form-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="81%" top="18%" width="5%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="37%" width="20%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking-form-signature" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="69%" top="103%" width="18%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-banking-form-signature" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-form-signature" :data-active="currentScreen=='candidate-onboarding-banking-form-signature'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-form-signature.png?v=09142023" />
          <div class="links">
            <screen-link left="94%" top="41%" width="5%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-form-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="1%" top="58%" width="90%" height="10%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-banking-form-signature-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-form-signature-sign" :data-active="currentScreen=='candidate-onboarding-banking-form-signature-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-form-signature-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="94%" top="41%" width="5%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-banking-form-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="78%" top="73%" width="20%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-banking-form-signed" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-banking-form-signed" :data-active="currentScreen=='candidate-onboarding-banking-form-signed'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-banking-form-signed.png?v=09142023" />
          <div class="links">
            <screen-link left="86.5%" top="8%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="banking" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents" :data-active="currentScreen=='candidate-onboarding-documents'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents.png?v=09142023" />
          <div v-if="onboardingTasks.handbook" class="doc-signed" style="left:26.3%;top:39.5%;">
            <img src="/img/screens/candidate/onboarding/handbook-signed.png?v=09142023" />
          </div>

          <div v-if="onboardingTasks.parking" class="doc-signed" style="left:26.3%;top:47%;">
            <img src="/img/screens/candidate/onboarding/parking-permit-signed.png?v=09142023" />
          </div>

          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="81%" top="40.2%" width="13%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents-handbook" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="81%" top="48%" width="13%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents-parking" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-handbook" :data-active="currentScreen=='candidate-onboarding-documents-handbook'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-handbook.png?v=09142023" />
          <div class="links">
            <screen-link left="62%" top="27%" width="3%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="65%" top="27%" width="4%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="63%" top="155.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-handbook-2" :data-active="currentScreen=='candidate-onboarding-documents-handbook-2'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-handbook-2.png?v=09142023" />
          <div class="links">
            <screen-link left="42%" top="27%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="65%" top="27%" width="4%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="63%" top="155.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-handbook-sign" :data-active="currentScreen=='candidate-onboarding-documents-handbook-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-handbook-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="42%" top="27%" width="7%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="65%" top="27%" width="4%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="66%" top="145%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="78.5%" top="145%" width="17%" height="5.5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-sign-modal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen" data-screen="candidate-onboarding-documents-handbook-sign-modal" :data-active="currentScreen=='candidate-onboarding-documents-handbook-sign-modal'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-handbook-signature.png?v=09142023" />
          <div class="links">

            <screen-link left="94.5%" top="41%" width="4%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-handbook-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="78.5%" top="72.5%" width="19%" height="5.5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" data-task-id="handbook" @click.native="completeTask"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-parking" :data-active="currentScreen=='candidate-onboarding-documents-parking'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-parking.png?v=09142023" />
          <div class="links">
            <screen-link left="68.5%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="10%" top="44%" width="45%" height="45%" dot-class="dot-top-center" data-tgt="candidate-onboarding-documents-parking-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-parking-details" :data-active="currentScreen=='candidate-onboarding-documents-parking-details'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-parking-details.png?v=09142023" />
          <div class="links">
            <screen-link left="68.5%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="81%" top="8%" width="17%" height="5%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-documents-parking-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="10%" top="76%" width="35%" height="2.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents-parking-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-documents-parking-sign" :data-active="currentScreen=='candidate-onboarding-documents-parking-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-documents-parking-sign.png?v=09142023" />
          <div class="links">

            <screen-link left="89.5%" top="44.5%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-documents-parking-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="72%" width="22%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-documents" data-tgt-mode="candidate" data-tgt-scrolltop="true" data-task-id="parking" @click.native="completeTask"></screen-link>

          </div>
        </div>


        <div class="screen" data-screen="candidate-onboarding-federal" :data-active="currentScreen=='candidate-onboarding-federal'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="30%" top="23%" width="12%" height="4%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal-confirmed" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="83%" top="31%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal-edit" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-edit" :data-active="currentScreen=='candidate-onboarding-federal-edit'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-edit.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="16.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="25%" top="64%" width="72%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal-edited" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-confirmed" :data-active="currentScreen=='candidate-onboarding-federal-confirmed'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-confirmed.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="federal" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
            <screen-link left="83%" top="16.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal-edit" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-edited" :data-active="currentScreen=='candidate-onboarding-federal-edited'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-edit-change.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="72%" top="27%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="84%" top="27%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-federal-edit-change-notice" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-edit-change-notice" :data-active="currentScreen=='candidate-onboarding-federal-edit-change-notice'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-edit-change-notice.png?v=09142023" />
          <div class="links">

            <screen-link left="54%" top="58%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-federal-edited" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="66%" top="58%" width="12%" height="5.5%" dot-class="dot-right-center" data-tgt="candidate-onboarding-federal-w4" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-w4" :data-active="currentScreen=='candidate-onboarding-federal-w4'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-w4.png?v=09142023" />
          <div class="links">

            <screen-link left="62%" top="102%" width="17%" height="5.5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-federal-w4-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-w4-sign" :data-active="currentScreen=='candidate-onboarding-federal-w4-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-w4-sign.png?v=09142023" />
          <div class="links">

            <screen-link left="74%" top="68.5%" width="18.5%" height="5.5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-federal-w4-signed" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-federal-w4-signed" :data-active="currentScreen=='candidate-onboarding-federal-w4-signed'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-federal-w4-signed.png?v=09142023" />
          <div class="links">

            <screen-link left="86%" top="8%" width="12.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" data-task-id="federal" @click.native="completeTask"></screen-link>

          </div>
        </div>


        <div class="screen" data-screen="candidate-onboarding-state" :data-active="currentScreen=='candidate-onboarding-state'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="79%" top="147.5%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-state-verify" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-verify" :data-active="currentScreen=='candidate-onboarding-state-verify'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-verify.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="85%" top="100%" width="13%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-state-forms" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-forms" :data-active="currentScreen=='candidate-onboarding-state-forms'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-forms.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="84%" top="47%" width="13%" height="6%" dot-class="dot-right-center" data-tgt="candidate-onboarding-state-IT2104" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-IT2104" :data-active="currentScreen=='candidate-onboarding-state-IT2104'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-IT2104.png?v=09142023" />
          <div class="links">
            <screen-link left="67%" top="95.5%" width="12%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-state-forms" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="79%" top="95.5%" width="18%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-state-form-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-form-sign" :data-active="currentScreen=='candidate-onboarding-state-form-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="81%" top="76.5%" width="12%" height="6%" dot-class="dot-left-center" data-tgt="candidate-onboarding-state-IT2104" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="93%" top="76.5%" width="7%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-state-docs2" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-docs2" :data-active="currentScreen=='candidate-onboarding-state-docs2'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-docs2.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="86%" top="99%" width="12%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-state-finish" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-state-finish" :data-active="currentScreen=='candidate-onboarding-state-finish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/candidate-state-tax-finish.png?v=09142023" />
          <div class="links">
            <screen-link left="93%" top="8%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="86%" top="70%" width="12%" height="6%" dot-class="dot-top-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="state" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
          </div>
        </div>



        <div class="screen" data-screen="candidate-onboarding-selfid" :data-active="currentScreen=='candidate-onboarding-selfid'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-selfid.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="25%" top="55.5%" width="70%" height="25%" dot-class="dot-left-center" data-tgt="candidate-onboarding-selfid-details" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-selfid-details" :data-active="currentScreen=='candidate-onboarding-selfid-details'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-selfid-details.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="86.5%" top="8%" width="12%" height="5%" dot-class="dot-bottom-center" data-tgt="candidate-onboarding-selfid-details-confirm" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-selfid-details-confirm" :data-active="currentScreen=='candidate-onboarding-selfid-details-confirm'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-selfid-details-confirm.png?v=09142023" />
          <div class="links">
            <screen-link left="54%" top="67%" width="16%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-selfid-details" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="70.5%" top="67%" width="16%" height="5%" dot-class="dot-top-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="selfid" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
          </div>
        </div>


        <div class="screen" data-screen="candidate-onboarding-contacts" :data-active="currentScreen=='candidate-onboarding-contacts'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-contacts.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="50%" top="74%" width="21%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-contacts-type" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-contacts-type" :data-active="currentScreen=='candidate-onboarding-contacts-type'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-contacts-type.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="contacts" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
            <screen-link left="26%" top="24%" width="35%" height="18%" dot-class="dot-top-center" data-tgt="candidate-onboarding-contacts-person" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-contacts-person" :data-active="currentScreen=='candidate-onboarding-contacts-person'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-contacts-person.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="contacts" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
            <screen-link left="61%" top="24%" width="35%" height="18%" dot-class="dot-top-center" data-tgt="candidate-onboarding-contacts-entity" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-onboarding-contacts-entity" :data-active="currentScreen=='candidate-onboarding-contacts-entity'">
          <img class="w-full max-w-100" src="/img/screens/candidate/onboarding/employee-onboarding-flex-contacts-entity.png?v=09142023" />
          <div class="links">
            <screen-link left="74%" top="8%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="candidate-onboarding-start" data-tgt-mode="candidate" data-task-id="contacts" data-tgt-scrolltop="true" @click.native="completeTask"></screen-link>
            <screen-link left="26%" top="24%" width="35%" height="18%" dot-class="dot-top-center" data-tgt="candidate-onboarding-contacts-person" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-email" :data-active="currentScreen=='candidate-offer-review-email'">
          <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-review-email.png?v=09152023" />
          <div class="links">
            <div class="email-body" style="top:21%; left: 10.2%;">
              {{ $store.state.offerEmailBody }}
            </div>
            <screen-link left="10%" top="43%" width="25%" height="6%" dot-class="dot-top-center" data-tgt="candidate-offer-review" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review" :data-active="currentScreen=='candidate-offer-review'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign.png?v=09142023" />
          <div class="links">
            <screen-link left="9%" top="2%" width="5%" height="6%" dot-class="dot-bottom-center" data-tgt="candidate-offer-review-disclosure" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-disclosure" :data-active="currentScreen=='candidate-offer-review-disclosure'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-agree.png?v=09142023" />
          <div class="links">
            <screen-link left="55%" top="4%" width="18%" height="6%" dot-class="dot-bottom-center" data-tgt="candidate-offer-review-sign" data-tgt-mode="candidate" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-sign" :data-active="currentScreen=='candidate-offer-review-sign'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-sign.png?v=09142023" />
          <div class="links">
            <screen-link left="10%" top="115%" width="35%" height="6%" dot-class="dot-left-center" data-tgt="candidate-offer-review-sign-modal" data-tgt-mode="candidate" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-sign-modal" :data-active="currentScreen=='candidate-offer-review-sign-modal'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-sign-modal.png?v=09142023" />
          <div class="links">
            <screen-link left="8%" top="42%" width="80%" height="10%" dot-class="dot-left-center" data-tgt="candidate-offer-review-sign-modal-signature" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-sign-modal-signature" :data-active="currentScreen=='candidate-offer-review-sign-modal-signature'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-sign-modal-signature.png?v=09142023" />
          <div class="links">
            <screen-link left="6%" top="63%" width="20%" height="6%" dot-class="dot-top-center" data-tgt="candidate-offer-review-sign-modal-finish" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-sign-modal-finish" :data-active="currentScreen=='candidate-offer-review-sign-modal-finish'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-sign-modal-finish.png?v=09142023" />
          <div class="links">
            <screen-link left="80%" top="90%" width="13%" height="5%" dot-class="dot-top-center" data-tgt="candidate-offer-review-sign-done" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="59%" top="0.5%" width="13%" height="5%" dot-class="dot-bottom-center" data-tgt="candidate-offer-review-sign-done" data-tgt-mode="candidate"  @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="candidate-offer-review-sign-done" :data-active="currentScreen=='candidate-offer-review-sign-done'">
          <img class="w-full max-w-100" src="/img/screens/candidate/offer/employee-offer-docusign-sign-done.png?v=09142023" />
          <div class="links">

          </div>
        </div>


      </template>
    </TabletLayout>
  </div>
</template>

<script>
import TabletLayout from "@/views/TabletLayout";
export default {
  name: "candidateExperience",
  components: {TabletLayout},
  mounted: function(){

  },

  data: function(){
    return {
      exitRoute: null,

      onboardingTasks: {
        profile: false,
        employment: false,
        i9: false,
        state: false,
        federal: false,
        parking: false,
        handbook: false,
        selfid: false,
        contacts: false,
        banking: false,
        wotc: false
      }
    }
  },
  computed: {
    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    }
  },
  methods: {
    exitExperience: function(){
      //this.$screens.onLinkClick(this.$store.state.candidateExitRoute)



      if(this.currentScreen?.startsWith("candidate-offer")){
        this.$screens.onLinkClick({
          target: {
            dataset: {
              tgt: "dashboard",
              tgtMode: "admin"
            }
          }
        })
        this.$screens.showPullout({
          target: {
            dataset: {
              tgt: "pullout-candidate",
              initInfo: "offer-signed"
            }
          }
        })
      }

      this.$screens.onLinkClick({
        target: {
          dataset: {
            tgt: "dashboard",
            tgtMode: "admin"
          }
        }
      })
    },
    completeTask: function(e){
      if(e.target.dataset.taskId){
        this.onboardingTasks[e.target.dataset.taskId] = true;
      }
      this.$screens.onLinkClick(e);
    },

    toggleMenu: function(){
      if(this.$store.state.overlayPanel){
        this.$store.state.overlayPanel = ""
      }else{
        this.$store.state.overlayPanel = "profile-menu"
      }

    }
  }
}
</script>

<style scoped>
  .screen-info {
    position: fixed;
    top: 0;
    width: 100%;
    height: 2em;
    background: black;
    z-index: 100;
    color: white;
  }

  .review-button {
    position: absolute;
    width: calc((90/768) * 100%);
  }

  .review-button img {
    display: block;
    width: 100%;
  }

  .doc-signed {
    position: absolute;
    width: calc(530/768 * 100%);
  }

  .doc-signed img {
    display: block;
    width: 100%;
  }

  .email-body {
    position: absolute;
    width: 80%;
    background-color: white;
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: Helvetica, Arial, sans-serif;
    white-space: pre-wrap;

  }

  .email-body pre {
    font-family: Helvetica, Arial, sans-serif;
  }

  .profile-menu {
    position: absolute;
    width: calc((220 / 768) * 100%);

  }

  .profile-menu img {
    display: block;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 0.5em;
    width: 100%;
  }


  @keyframes blink {
    0% {
      opacity: 0.5;
    }

    40% {
      opacity: 1.0;
    }

    60% {
      opacity: 1.0;
    }

    100% {
      opacity: 0.5;
    }
  }

  .back-to-english {
    padding: 0.75em 2em;
    background: var(--color-green);
    display: inline-block;
    border-radius: 0.5em;
    color: white;


    user-select: none;
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;



  }

  .back-to-english:hover {
    text-decoration: underline;

  }


</style>