


<template>
<div class="tools-pullout pullout-panel">
  <div class="bkg"></div>

  <div class="help-list">


    <div class="help-scroll">
      <div>
        <img  class="w-full" :src="imageURL" />
        <div class="links">
          <screen-link left="90%" top="1%" width="8%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          <screen-link v-if="detailPanel === 'medical'" left="51%" top="235%" width="6%" height="4%" dot-class="dot-left-center" data-tgt="" @click.native="showDetails"></screen-link>
          <screen-link v-else left="4%" top="233%" width="40%" height="8%" dot-class="dot-left-center" data-tgt="medical" @click.native="showDetails"></screen-link>

        </div>
      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "edit-check-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },
      showExternalPage: function(e){
        this.$emit("external", e)
      }

    },
    computed: {
      imageURL: function(){
        if(this.detailPanel === 'medical'){
          return "/img/screens/payroll/pullouts/admin-payroll-center-resume-edit-full-check-misc.png"
        }
        return "/img/screens/payroll/pullouts/admin-payroll-center-resume-edit-full-check.png"
      }
    }
}
</script>

<style scoped>


.tools-pullout {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;


}

.tools-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.tools-pullout img {
  display: block;
}

.tools-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.help-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  top: calc((60/920) * 100%);
  bottom: 0;

  right: 0;
  display: flex;
  flex-direction: column;

}



.help-scroll {
  position: relative;

  overflow-y: scroll;
  height: 100%;
}

.help-scroll .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
