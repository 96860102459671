import { render, staticRenderFns } from "./ReportTurnoverOptionsPanel.vue?vue&type=template&id=70f82c24&scoped=true&"
import script from "./ReportTurnoverOptionsPanel.vue?vue&type=script&lang=js&"
export * from "./ReportTurnoverOptionsPanel.vue?vue&type=script&lang=js&"
import style0 from "./ReportTurnoverOptionsPanel.vue?vue&type=style&index=0&id=70f82c24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f82c24",
  null
  
)

export default component.exports