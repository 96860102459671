<template>
  <div class="screen-container" style="margin: 0 auto;">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <!-- DASHBOARD -->


        <div v-if="commentAdded" class="screen z-max" data-screen="dashboard" :data-active="currentScreen=='dashboard'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-high-five-comment-saved.png?v=05132024" />
          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="63.5%" top="13%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="high-five" @click.native="onLinkClick"></screen-link>
            <screen-link left="44%" top="122.5%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="review" @click.native="onLinkClick"></screen-link>
            <screen-link left="44%" top="177%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="survey" @click.native="onLinkClick"></screen-link>
          </div>
        </div>
        <div v-else class="screen z-max" data-screen="dashboard" :data-active="currentScreen=='dashboard'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage.png?v=05132024" />

          <div class="links">
            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="63.5%" top="13%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="high-five" @click.native="onLinkClick"></screen-link>
            <screen-link left="44%" top="122.5%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="review" @click.native="onLinkClick"></screen-link>
            <screen-link left="44%" top="177%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="survey" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="high-five" :data-active="currentScreen=='high-five'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-high-five.png?v=05132024" />
          <div class="links">
            <screen-link left="66%" top="5%" width="2.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="14.5%" width="36%" height="14%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="high-five-comment" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="45%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
          </div>
        </div>
        <div class="screen z-max" data-screen="high-five-comment" :data-active="currentScreen=='high-five-comment'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-high-five-comment.png?v=05132024" />
          <div class="links">
            <screen-link left="66%" top="5%" width="2.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="49.5%" top="63%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="saveComment"></screen-link>
            <screen-link left="43%" top="63%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="review" :data-active="currentScreen=='review'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-review.png?v=05132024" />

          <div class="links">
            <img class="review-goals" v-if="reviewTab === 'goals'" style="top:19.35%; left: 72.95%;" src="/img/screens/engage/flex-engage-review-goals-panel.png?v=05022024" />
            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="73%" top="19%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('reviews')"></screen-link>
            <screen-link left="79%" top="19%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('goals')"></screen-link>
            <screen-link left="17%" top="39%" width="53%" height="32%" dot-class="dot-left-center" data-tgt="review-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="17%" top="92%" width="53%" height="32%" dot-class="dot-left-center" data-tgt="review-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="17%" top="145%" width="53%" height="32%" dot-class="dot-left-center" data-tgt="review-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="17%" top="199%" width="53%" height="32%" dot-class="dot-left-center" data-tgt="review-filled" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="review-filled" :data-active="currentScreen=='review-filled'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-review-filled.png?v=05132024" />
          <div class="links">
            <img class="review-goals" v-if="reviewTab === 'goals'" style="top:19.35%; left: 72.95%;" src="/img/screens/engage/flex-engage-review-goals-panel.png?v=05022024" />
            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="73%" top="19%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('reviews')"></screen-link>
            <screen-link left="79%" top="19%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('goals')"></screen-link>
            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="23%" top="261.5%" width="7%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="review-saved" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="review-saved" :data-active="currentScreen=='review-saved'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-review-saved.png?v=05132024" />
          <div class="links">
            <img class="review-goals" v-if="reviewTab === 'goals'" style="top:19.35%; left: 72.95%;" src="/img/screens/engage/flex-engage-review-goals-panel.png?v=05022024" />
            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="73%" top="19%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('reviews')"></screen-link>
            <screen-link left="79%" top="19%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="setReviewTab('goals')"></screen-link>
            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="survey" :data-active="currentScreen=='survey'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-survey.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="74.75%" top="26%" width="3%" height="8%" dot-class="dot-bottom-center" data-tgt="survey-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="72.25%" top="42%" width="3%" height="8%" dot-class="dot-bottom-center" data-tgt="survey-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="72.25%" top="58%" width="3%" height="8%" dot-class="dot-bottom-center" data-tgt="survey-filled" @click.native="onLinkClick"></screen-link>
            <screen-link left="71.5%" top="74%" width="3%" height="8%" dot-class="dot-bottom-center" data-tgt="survey-filled" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="survey-filled" :data-active="currentScreen=='survey-filled'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-survey-filled.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="23%" top="83%" width="7%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="survey-saved" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="survey-saved" :data-active="currentScreen=='survey-saved'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-survey-saved.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="0%" top="36%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="127.75%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="manage-surveys" :data-active="currentScreen=='manage-surveys'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-surveys.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="16%" top="26.5%" width="82%" height="8%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="manage-surveys-engagement" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="manage-surveys-engagement" :data-active="currentScreen=='manage-surveys-engagement'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-surveys-engagement.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="16%" top="26.5%" width="82%" height="8%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>
            <screen-link left="83.5%" top="35%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="manage-surveys-results" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="manage-surveys-results" :data-active="currentScreen=='manage-surveys-results'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-surveys-results.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="21%" top="6.5%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-surveys-engagement" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="reviews" :data-active="currentScreen=='reviews'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-reviews.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="89%" top="32%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="review" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="skills" :data-active="currentScreen=='skills'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-skills.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="18%" width="13.5%" height="5.5%" dot-class="dot-right-center" data-tgt="skills-select" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="skills-select" :data-active="currentScreen=='skills-select'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-skills-select.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="18%" width="13.5%" height="5.5%" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="42%" width="10.5%" height="5.5%" dot-class="dot-right-center" data-tgt="skills-select-change" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="skills-select-change" :data-active="currentScreen=='skills-select-change'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-skills-select-change.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="24.25%" width="6%" height="5.5%" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>

            <screen-link left="43%" top="18%" width="13.5%" height="5.5%" dot-class="dot-right-center" data-tgt="skills-location" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="skills-location" :data-active="currentScreen=='skills-location'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-skills-location.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="24.25%" width="6%" height="5.5%" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>

            <screen-link left="43%" top="18%" width="13.5%" height="5.5%" dot-class="dot-right-center" data-tgt="skills-select-change" @click.native="onLinkClick"></screen-link>

            <screen-link left="43.5%" top="36.25%" width="13.5%" height="5.5%" dot-class="dot-left-center" data-tgt="skills-location-rochester" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="skills-location-rochester" :data-active="currentScreen=='skills-location-rochester'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-skills-location-rochester.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15.5%" top="24.25%" width="6%" height="5.5%" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>




          </div>
        </div>

        <div class="screen z-max" data-screen="goals" :data-active="currentScreen=='goals'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15%" top="15%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="31%" top="15%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-department" @click.native="onLinkClick"></screen-link>
            <screen-link left="41%" top="15%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-company" @click.native="onLinkClick"></screen-link>

            <screen-link left="88%" top="27%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-add" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add" :data-active="currentScreen=='goals-add'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="15%" width="36%" height="4%"  dot-class="dot-left-center" data-tgt="goals-add-title" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-title" :data-active="currentScreen=='goals-add-title'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-title.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="24%" width="36%" height="4%" dot-class="dot-left-center" data-tgt="goals-add-type" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-type" :data-active="currentScreen=='goals-add-type'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-types.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="32%" width="36%" height="4%" dot-class="dot-left-center" data-tgt="goals-add-department" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-department" :data-active="currentScreen=='goals-add-department'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-department.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="30%" width="36%" height="4%" dot-class="dot-left-center" data-tgt="goals-add-department-goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-department-goals" :data-active="currentScreen=='goals-add-department-goals'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-department-goals.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="37.5%" width="36%" height="4%" dot-class="dot-left-center" data-tgt="goals-add-department-rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-department-rewards" :data-active="currentScreen=='goals-add-department-rewards'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-department-rewards.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="34%" top="39%" width="11%" height="4%" dot-class="dot-top-center" data-tgt="goals-add-weight" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-weight" :data-active="currentScreen=='goals-add-weight'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-weight.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="47%" width="17.5%" height="4%" dot-class="dot-middle-center" data-tgt="goals-add-date" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-date" :data-active="currentScreen=='goals-add-date'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-date.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="62%" width="17.5%" height="4%" dot-class="dot-left-center" data-tgt="goals-add-date-year" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-date-year" :data-active="currentScreen=='goals-add-date-year'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-date-year.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="32%" top="66%" width="36%" height="10%" dot-class="dot-left-center" data-tgt="goals-add-description" @click.native="onLinkClick"></screen-link>

            <screen-link left="56%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-add-description" :data-active="currentScreen=='goals-add-description'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-add-description.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>


            <screen-link left="56%" top="89%" width="5.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="61.5%" top="89%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals-added" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-added" :data-active="currentScreen=='goals-added'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-added.png?v=05132024" />

          <div class="links">



            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15%" top="15%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="31%" top="15%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-department" @click.native="onLinkClick"></screen-link>
            <screen-link left="41%" top="15%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-company" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="goals-company" :data-active="currentScreen=='goals-company'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-company.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15%" top="15%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="31%" top="15%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-department" @click.native="onLinkClick"></screen-link>
            <screen-link left="41%" top="15%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-company" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="goals-department" :data-active="currentScreen=='goals-department'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-goals-department.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15%" top="15%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>
            <screen-link left="31%" top="15%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-department" @click.native="onLinkClick"></screen-link>
            <screen-link left="41%" top="15%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="goals-company" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="suggestion" :data-active="currentScreen=='suggestion'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-suggestion.png?v=05132024" />

          <div class="links">

            <screen-link left="75%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>



            <screen-link left="22.5%" top="15%" width="55%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="suggestion-details" @click.native="onLinkClick"></screen-link>
            <screen-link left="71%" top="58%" width="5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="suggestion-details" :data-active="currentScreen=='suggestion-details'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-suggestion-details.png?v=05132024" />

          <div class="links">

            <screen-link left="75%" top="5.25%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="65.5%" top="58%" width="5.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="71%" top="58%" width="5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="manage-suggestions" :data-active="currentScreen=='manage-suggestions'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-suggestions.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="90%" top="45%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-suggestions-read" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="manage-suggestions-read" :data-active="currentScreen=='manage-suggestions-read'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-suggestions-read.png?v=05132024" />

          <div class="links">



            <screen-link left="66%" top="5%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>

            <screen-link left="62%" top="45%" width="6%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="manage-reviews" :data-active="currentScreen=='manage-reviews'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-reviews.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="87%" top="42%" width="2%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-reviews-audit" @click.native="onLinkClick"></screen-link>

          </div>
        </div>
        <div class="screen z-max" data-screen="manage-reviews-audit" :data-active="currentScreen=='manage-reviews-audit'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-reviews-audit.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="26%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-reviews-result" @click.native="onLinkClick"></screen-link>
            <screen-link left="19.5%" top="26%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-reviews-audit" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="manage-reviews-result" :data-active="currentScreen=='manage-reviews-result'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-manage-reviews-results.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
<screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="26%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-reviews-result" @click.native="onLinkClick"></screen-link>
            <screen-link left="19.5%" top="26%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="manage-reviews-audit" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="team" :data-active="currentScreen=='team'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-my-team.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="19%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="37%" top="19%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team-one-on-ones" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="team-one-on-ones" :data-active="currentScreen=='team-one-on-ones'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-my-team-one-on-ones.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="19%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="37%" top="19%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team-one-on-ones" @click.native="onLinkClick"></screen-link>
            <screen-link left="69.5%" top="35%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team-one-on-one" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="team-one-on-one" :data-active="currentScreen=='team-one-on-one'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-my-team-one-on-one.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="20.5%" top="6.5%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="25.5%" top="6.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="team-one-on-ones" @click.native="onLinkClick"></screen-link>
          </div>
        </div>


        <div class="screen z-max" data-screen="compensation" :data-active="currentScreen=='compensation'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="70%" top="33%" width="14.5%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="compensation-recommendations" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-recommendations" :data-active="currentScreen=='compensation-recommendations'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-recommendations.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="62%" top="47%" width="4%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="compensation-recommendations-3pct" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-recommendations-3pct" :data-active="currentScreen=='compensation-recommendations-3pct'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-recommendations-3pct-scroll.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="84.5%" top="44.75%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-recommendations-bonus" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-recommendations-bonus" :data-active="currentScreen=='compensation-recommendations-bonus'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-recommendations-3pct-bonus-scroll.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="92.5%" top="11.75%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-recommendations-submit" @click.native="onLinkClick"></screen-link>
            <screen-link left="91.5%" top="86.5%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-recommendations-submit" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-recommendations-submit" :data-active="currentScreen=='compensation-recommendations-submit'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-recommendations-submit.png?v=05132024" />

          <div class="links">


            <screen-link left="48%" top="52.25%" width="4%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-recommendations-submitted" @click.native="onLinkClick"></screen-link>

          </div>
        </div>
        <div class="screen z-max" data-screen="compensation-recommendations-submitted" :data-active="currentScreen=='compensation-recommendations-submitted'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-recommendations-submitted.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="85%" top="34%" width="12%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-approvals" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-approvals" :data-active="currentScreen=='compensation-approvals'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-approvals.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="18%" top="34%" width="9.5%" height="4.5%" dot-class="dot-right-center" data-tgt="compensation-approvals-recommendations" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-approvals-recommendations" :data-active="currentScreen=='compensation-approvals-recommendations'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-approvals-recommendations.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="89.75%" top="49%" width="4.25%" height="4.5%" dot-class="dot-top-center" data-tgt="compensation-approvals-approved" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-approvals-approved" :data-active="currentScreen=='compensation-approvals-approved'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-approvals-recommendations-approve.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="90.5%" top="60%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="compensation-approvals-submitted" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="compensation-approvals-submitted" :data-active="currentScreen=='compensation-approvals-submitted'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-compensation-approvals-recommendations-approved.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="82.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="rewards" :data-active="currentScreen=='rewards'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-rewards.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="83.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="20%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="20%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-custom-items" @click.native="onLinkClick"></screen-link>
            <screen-link left="29.5%" top="20%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-marketplace" @click.native="onLinkClick"></screen-link>

            <screen-link left="90.5%" top="19%" width="9.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-my-rewards" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="rewards-my-rewards" :data-active="currentScreen=='rewards-my-rewards'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-rewards-my-rewards.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="83.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>



          </div>
        </div>

        <div class="screen z-max" data-screen="rewards-custom-items" :data-active="currentScreen=='rewards-custom-items'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-rewards-custom.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="83.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="20%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="20%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-custom-items" @click.native="onLinkClick"></screen-link>
            <screen-link left="29.5%" top="20%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-marketplace" @click.native="onLinkClick"></screen-link>

            <screen-link left="90.5%" top="19%" width="9.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-my-rewards" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="rewards-marketplace" :data-active="currentScreen=='rewards-marketplace'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-rewards-amazon.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="83.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="20%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="20%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-custom-items" @click.native="onLinkClick"></screen-link>
            <screen-link left="29.5%" top="20%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-marketplace" @click.native="onLinkClick"></screen-link>

            <screen-link left="15.5%" top="27.5%" width="60%" height="4%" dot-class="dot-left-center" data-tgt="rewards-marketplace-search" @click.native="onLinkClick"></screen-link>

            <screen-link left="90.5%" top="19%" width="9.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-my-rewards" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="rewards-marketplace-search" :data-active="currentScreen=='rewards-marketplace-search'">
          <img class="w-full max-w-100" src="/img/screens/engage/flex-engage-rewards-amazon-search.png?v=05132024" />

          <div class="links">

            <screen-link left="0%" top="0%" width="10%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="closeAdminTab"></screen-link>
            <screen-link left="83.5%" top="0%" width="3%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="suggestion" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="36%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="40.75%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="team" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="50.5%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="goals" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="60.25%" width="9.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="65.25%" width="8.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="skills" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="80%" width="13.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="89.75%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>

            <screen-link left="0%" top="121%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-suggestions" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="126%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-reviews" @click.native="onLinkClick"></screen-link>
            <screen-link left="0%" top="142.5%" width="10%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="manage-surveys" @click.native="onLinkClick"></screen-link>

            <screen-link left="14%" top="6.5%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>

            <screen-link left="15%" top="20%" width="7%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="20%" width="7.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-custom-items" @click.native="onLinkClick"></screen-link>
            <screen-link left="29.5%" top="20%" width="11.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-marketplace" @click.native="onLinkClick"></screen-link>

            <screen-link left="90.5%" top="19%" width="9.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="rewards-my-rewards" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

    </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";
// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "engage-screens",
  components: { ScreenLink, DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      if(this.$route.query.guided){

        let value = (this.$route.query.guided.trim() === "true");
        console.log("guided param",this.$route.query.guided, value )
        this.$store.commit("guidedMode", value);
      }else{
        this.$store.commit("guidedMode", false);
      }

      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'dashboard',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,
      salesAdded: false,
      commentAdded: false,
      reviewAdded: false,
      reviewTab: "reviews"

    }
  },
  methods: {

    closeTab: function(){
        this.onLinkClick({
          target: {
            dataset: {
              tgtMode: "employee",
              tgt: "dashboard"
            }
          }
        })
    },

    saveComment: function(e){
      this.commentAdded = true;
      this.onLinkClick(e)
    },

    setReviewTab: function(tab){
      this.reviewTab = tab
    },

    closeAdminTab: function(){
      window.close()
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{
        console.log("scroll top", e.target.dataset.tgtScrolltop)
        this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "engage"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          console.log("scrolling", this.$refs)
          //this.$refs['screens'].scrollTo(0,0);
          window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "hr"){

          this.$router.push({name: "training", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}




.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

.review-goals {
  position: absolute;
  width: calc(390/1580 * 100%);
  margin: 0;
}

.engage-side-nav {
  position: absolute;
  left: 0;
  top: 0;
  width: calc((225/1580) * 100%);
}

</style>

<style>
  html {
    background: white;
  }
</style>
