<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <!-- DASHBOARD -->


        <div class="screen z-max" data-screen="dashboard" :data-active="currentScreen=='dashboard'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="13%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="34%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="45%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="expenses" :data-active="currentScreen=='expenses'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="13%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="34%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="45%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="3.2%" top="28%" width="2.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-details" @click.native="onLinkClick"></screen-link>
            <screen-link left="72.1%" top="28%" width="2%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-check-approve" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="expenses-check-approve" :data-active="currentScreen=='expenses-check-approve'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-approve-check.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="34%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="45%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="60.5%" top="18%" width="15.5%" height="4.25%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expense-approve" @click.native="onLinkClick"></screen-link>
            <screen-link left="3.2%" top="28%" width="2.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-details" @click.native="onLinkClick"></screen-link>
            <screen-link left="72.1%" top="28%" width="2%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="expenses-details" :data-active="currentScreen=='expenses-details'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-expense-details.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="67.25%" top="53.25%" width="2.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-details-receipt" @click.native="onLinkClick"></screen-link>
            <screen-link left="78%" top="43%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="expense-approve" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="expenses-details-receipt" :data-active="currentScreen=='expenses-details-receipt'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-expense-receipt.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="96.75%" top="13%" width="2%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-details" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="expense-approve" :data-active="currentScreen=='expense-approve'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-expense-approve.png?v=11162023" />
          <div class="links">


            <screen-link left="66.75%" top="12%" width="2.5%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expenses-details" @click.native="onLinkClick"></screen-link>
            <screen-link left="62.5%" top="42.75%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="expenses-approved" @click.native="onLinkClick"></screen-link>
          </div>
        </div>



        <div class="screen z-max" data-screen="expenses-approved" :data-active="currentScreen=='expenses-approved'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-expense-submitted.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="expense-approve" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="pay" :data-active="currentScreen=='pay'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-pay.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="93%" top="14%" width="5.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay-process" @click.native="onLinkClick"></screen-link>
            <screen-link left="93.25%" top="40%" width="2.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay-payroll" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="pay-payroll" :data-active="currentScreen=='pay-payroll'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-pay-payroll-check.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="93%" top="14%" width="5.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay-process" @click.native="onLinkClick"></screen-link>
            <screen-link left="93.25%" top="40%" width="2.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="pay-process" :data-active="currentScreen=='pay-process'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-pay-process.png?v=11162023" />
          <div class="links">

            <screen-link left="62%" top="62%" width="5.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay-processed" @click.native="onLinkClick"></screen-link>
            <screen-link left="66.5%" top="21.5%" width="2.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay-payroll" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="pay-processed" :data-active="currentScreen=='pay-processed'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-expenses-pay-processed.png?v=11162023" />
          <div class="links">

            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="analytics" :data-active="currentScreen=='analytics'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="25%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="analytics-by-type" @click.native="onLinkClick"></screen-link>

            <screen-link left="80%" top="31%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="analytics-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="84%" top="31%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="analytics-run" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-download" :data-active="currentScreen=='analytics-download'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-download.png?v=11162023" />
          <div class="links">

            <screen-link left="1%" top="1%" width="98%" height="100%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="0.5%" top="1%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="analytics" @click.native="onLinkClick"></screen-link>
          </div>
        </div>


        <div class="screen z-max" data-screen="analytics-by-type" :data-active="currentScreen=='analytics-by-type'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="2%" top="30%" width="15%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="analytics-by-type-by-month" @click.native="onLinkClick"></screen-link>
            <screen-link left="80%" top="31%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="analytics-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="84%" top="31%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="analytics-run" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-by-type-by-month" :data-active="currentScreen=='analytics-by-type-by-month'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="90.5%" top="40%" width="7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="analytics-start-report" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-start-report" :data-active="currentScreen=='analytics-start-report'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-start.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="28%" top="20%" width="7%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="analytics-start-report-all" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-start-report-all" :data-active="currentScreen=='analytics-start-report-all'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-start-all.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="22.5%" top="67.5%" width="7.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="analytics-run" @click.native="onLinkClick"></screen-link>
          </div>
        </div>


        <div class="screen z-max" data-screen="analytics-run" :data-active="currentScreen=='analytics-run'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-report-done.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="20%" top="33%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics-report" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-report" :data-active="currentScreen=='analytics-report'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-report-view.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="84%" top="22%" width="14%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="analytics-report-options" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-report-options" :data-active="currentScreen=='analytics-report-options'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-report-view-options.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="84%" top="38%" width="8%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="analytics-report-stacked" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="analytics-report-stacked" :data-active="currentScreen=='analytics-report-stacked'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-analytics-by-type-month-report-view-stacked.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards" :data-active="currentScreen=='credit-cards'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>

            <screen-link left="55%" top="18%" width="9%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="credit-cards-transactions" @click.native="onLinkClick"></screen-link>
            <screen-link left="61%" top="23.5%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="credit-cards-add" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-add" :data-active="currentScreen=='credit-cards-add'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-add.png?v=11162023" />
          <div class="links">


            <screen-link left="80.5%" top="6%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="69%" top="70.5%" width="11.5%" height="7%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="credit-cards-select" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-select" :data-active="currentScreen=='credit-cards-select'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-add-select.png?v=11162023" />
          <div class="links">


            <screen-link left="80.5%" top="6%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="57%" top="40.5%" width="12.5%" height="10%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="credit-cards-verify" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-verify" :data-active="currentScreen=='credit-cards-verify'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-verify-credentials.png?v=11162023" />
          <div class="links">


            <screen-link left="80.5%" top="6%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="68%" top="67%" width="8.5%" height="7%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="credit-cards-finish" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-finish" :data-active="currentScreen=='credit-cards-finish'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-finish.png?v=11162023" />
          <div class="links">


            <screen-link left="80.5%" top="6%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="75%" top="55.5%" width="5%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center"  data-tgt="credit-cards-added" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-finish" :data-active="currentScreen=='credit-cards-added'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-added.png?v=11162023" />
          <div class="links">


            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>
            <screen-link left="55%" top="18%" width="9%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="credit-cards-transactions" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen z-max" data-screen="credit-cards-transactions" :data-active="currentScreen=='credit-cards-transactions'">
          <img class="w-full max-w-100" src="/img/screens/expense-wire/expense-wire-credit-cards-transactions.png?v=11162023" />
          <div class="links">
            <screen-link left="80%" top="1.5%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="" @click.native="closeTab"></screen-link>
            <screen-link left="1%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="6%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="expenses" @click.native="onLinkClick"></screen-link>
            <screen-link left="22%" top="7%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="credit-cards" @click.native="onLinkClick"></screen-link>
            <screen-link left="43%" top="7%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="analytics" @click.native="onLinkClick"></screen-link>
            <screen-link left="54%" top="7%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center"  data-tgt="pay" @click.native="onLinkClick"></screen-link>


            <screen-link left="61%" top="23.5%" width="3%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center"  data-tgt="credit-cards-add" @click.native="onLinkClick"></screen-link>
          </div>
        </div>




    </div>
      <demo-menu v-if="showMenu"></demo-menu>
    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";
import DemoMenu from "@/components/DemoMenu";
// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "expense-wire-screens",
  components: { ScreenLink, DemoMenu /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      if(this.$route.query.guided){

        let value = (this.$route.query.guided.trim() === "true");
        console.log("guided param",this.$route.query.guided, value )
        this.$store.commit("guidedMode", value);
      }else{
        this.$store.commit("guidedMode", false);
      }

      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'hr-training',
      visibility: {
        topnav: true
      },
      overlayPanel: null,
      pulloutPanel: null,
      disableScreenScoll: false,
      salesAdded: false

    }
  },
  methods: {

    closeTab: function(){
      window.close()
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;



      if(e.target.dataset.commit){
        this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      }

      if(!e.target.dataset.tgt && this.pulloutPanel) {
        this.pulloutPanel = null;
      }else if(e.target.dataset.isOverlay){
        this.overlayPanel = e.target.dataset.tgt
      }else{
        console.log("scroll top", e.target.dataset.tgtScrolltop)
        this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },

    setScreen: function(id, mode, scrollToTop){
      this.overlayPanel = null;
      this.pulloutPanel = null;
      console.log(id);

      if(!mode){
        mode = "expense-wire"
      }

      if(id == this.$route.params.screen){
        //this.$router.go(0);
      }else{
        this.$router.push({name: mode, params: { screen: id}});
        if(scrollToTop){
          console.log("scrolling", this.$refs)
          //this.$refs['screens'].scrollTo(0,0);
          window.scrollTo(0,0);
        }
      }

    },


    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "hr"){

          this.$router.push({name: "training", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}




.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
  html {
    background: white;
  }
</style>
