<template>
  <div>

    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="settings" :data-active="currentScreen=='settings'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="28%" top="27.5%"  width="58.5%" height="6%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28%" top="96%" width="58.5%" height="7%" dot-class="dot-left-center" data-tgt-scrolltop="true"  data-tgt="pullout-settings-editor" data-init-info="approval-rules" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>


        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Company settings houses all important documents, and lets administrations manage approval rules, to ensure team members that need specific permissions are properly setup.</p>
        </info-box>
      </div>
    </div>

    <div v-else class="screen" data-screen="settings" :data-active="currentScreen=='settings'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings.png?v=10122022" />
      <div class="links">
        <screen-link left="28%" top="27.5%"  width="58.5%" height="6%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28%" top="141.5%" width="58.5%" height="7%" dot-class="dot-left-center" data-tgt-scrolltop="true"  data-tgt="pullout-settings-editor" data-init-info="approval-rules" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Company settings houses all important documents, and lets administrations manage approval rules, to ensure team members that need specific permissions are properly setup.</p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="settings-roles" :data-active="currentScreen=='settings-roles'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="28%" top="65%" width="58.5%" height="6%" dot-class="dot-right-center" data-tgt="settings-roles-hr" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>


      </div>
    </div>

    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="settings-roles-hr" :data-active="currentScreen=='settings-roles-hr'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-expanded-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="81%" top="50.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edit" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>


      </div>
    </div>

    <div v-else class="screen" data-screen="settings-roles-hr" :data-active="currentScreen=='settings-roles-hr'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-expanded.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="81%" top="50.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edit" @click.native="$screens.onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>


      </div>
    </div>

    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="settings-roles-hr-edit" :data-active="currentScreen=='settings-roles-hr-edit'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-expanded-edit-pro.png?v=10122022" />
      <div class="links">
        <div class="cutout comp-cutout" style="left:65.8%; top:118%;" :data-active="compFullAccess">
          <img src="/img/screens/settings/cutouts/comp-full-access-edit.png?v=10122022" />
        </div>

        <div class="cutout save-cutout" style="left:80.75%; top:50.5%;" :data-active="compFullAccess">
          <img src="/img/screens/settings/cutouts/save-button.png?v=10122022" />
        </div>
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74.75%" top="50.5%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="settings-roles-hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link v-if="compFullAccess" left="81%" top="50.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edited" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link v-if="!compFullAccess" left="76%" top="118%" width="11%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edit" @click.native="$screens.setOverlayPanel('comp-options')"></screen-link>
        <basic-options-panel v-if="$store.state.overlayPanel === 'comp-options'" left="75.5%" top="118%" width="12%"
            :selection="(compFullAccess?'View':'No Access')"
            :on-select="setCompensation"
            :items="[
                {label:'No Access', value:'No Access'},
                {label:'View', value:'View', screenLink: true},
                {label:'Edit', value:'Edit'},
            ]"
        />

        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

      </div>
    </div>
    <div v-else class="screen" data-screen="settings-roles-hr-edit" :data-active="currentScreen=='settings-roles-hr-edit'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-expanded-edit.png?v=10122022" />
      <div class="links">
        <div class="cutout comp-cutout" style="left:65.8%; top:118%;" :data-active="compFullAccess">
          <img src="/img/screens/settings/cutouts/comp-full-access-edit.png?v=10122022" />
        </div>

        <div class="cutout docs-cutout" style="left:27.532%; top:223.19%;" :data-active="!docsFullAccess">
          <img src="/img/screens/settings/cutouts/docs-full-access-edit.png?v=10122022" />
        </div>

        <div class="cutout save-cutout" style="left:80.75%; top:50.5%;" :data-active="compFullAccess && !docsFullAccess">
          <img src="/img/screens/settings/cutouts/save-button.png?v=10122022" />
        </div>
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="74.75%" top="50.5%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="settings-roles-hr" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link v-if="compFullAccess && !docsFullAccess" left="81%" top="50.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edited" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link v-if="!compFullAccess" left="76%" top="118%" width="11%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edit" @click.native="$screens.setOverlayPanel('comp-options')"></screen-link>
        <basic-options-panel v-if="$store.state.overlayPanel === 'comp-options'" left="75.5%" top="118%" width="12%"
                             :selection="(compFullAccess?'View':'No Access')"
                             :on-select="setCompensation"
                             :items="[
                {label:'No Access', value:'No Access'},
                {label:'View', value:'View', screenLink: true},
                {label:'Edit', value:'Edit'},
            ]"
        />
        <screen-link v-if="docsFullAccess" left="76%" top="229%" width="11%" height="22%" dot-class="dot-right-center" data-tgt="settings-roles-hr-edit" @click.native="$screens.setOverlayPanel('docs-options')"></screen-link>
        <basic-options-panel v-if="$store.state.overlayPanel === 'docs-options'" left="75.5%" top="229%" width="12%"
                             :selection="(docsFullAccess?'Full Access':'No Access')"
                             :on-select="setDocs"
                             :items="[
                {label:'No Access', value:'No Access', screenLink: true},
                {label:'View', value:'View'},
                {label:'Edit', value:'Edit'},
                {label:'Full Access', value:'Full Access'},
            ]"
        />
        <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

      </div>
    </div>

    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="settings-roles-hr-edited" :data-active="currentScreen=='settings-roles-hr-edited'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-edited-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
      </div>
    </div>
    <div v-else class="screen" data-screen="settings-roles-hr-edited" :data-active="currentScreen=='settings-roles-hr-edited'">
      <img class="w-full max-w-100" src="/img/screens/settings/company-settings-access-roles-modadmin-edited.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13.5%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="settings-roles" @click.native="$screens.onLinkClick"></screen-link>
      </div>
    </div>

    
  </div>
</template>

<script>
import BasicOptionsPanel from "@/components/BasicOptionsPanel";
export default {
  name: "AdminSettings",
  components: {BasicOptionsPanel},
  props: {
    currentScreen: {}
  },
  data: function(){
    return {
      compFullAccess: false,
      docsFullAccess: true,
      companyStartScreen: "settings"
    }
  },
  methods: {
    setCompensation: function(val){

      this.compFullAccess = (val?.value === 'View');

      this.$screens.setOverlayPanel("")
    },
    setDocs: function(val){

      this.docsFullAccess = (val?.value === 'Full Access');

      this.$screens.setOverlayPanel("")
    }
  }
}
</script>

<style scoped>

  .cutout {
    display: none;
  }

  .comp-cutout {
    position: absolute;

    width: 20%;

  }

  .docs-cutout {
    position: absolute;

    width: calc((935/1580) * 100%);

  }

  .save-cutout {
    position: absolute;

    width: calc((94/1580) * 100%);

  }

  .cutout img {
    width: 100%;
    display: block;
  }

  .cutout[data-active="true"]{
    display: block;
  }

</style>