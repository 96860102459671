


<template>
<div class="">

  <div class="screen" data-screen="payroll-center" :data-active="currentScreen=='payroll-center'">
    <img class="w-full max-w-100" src="/img/screens/payroll/admin-payroll-center.png?v=05072024" />
    <div class="links">

      <screen-link left="91%" top="13.5%" width="8%" height="5%" dot-class="dot-left-center" data-init-info="payroll-center-options" data-tgt="pullout-payroll-center-options" @click.native="$screens.showPullout"></screen-link>

      <screen-link left="76%" top="38.2%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center-resume" @click.native="startPayroll('enterprise')"></screen-link>
      <screen-link left="76%" top="45.5%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center-resume" @click.native="startPayroll('pro')"></screen-link>

      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>Payroll Center makes running payroll online simple. With built-in safeguards that alert of potential payroll issues, administrators can process payroll in just a few clicks.</p>
      </info-box>
    </div>
  </div>

  <div v-if="$store.state.company === 'pro'" class="screen" data-screen="payroll-center-resume" :data-active="currentScreen=='payroll-center-resume'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-pro-resume.png?v=05072024" />
    <div class="links">

      <div v-if="$store.state.printOnsite" class="onsite-label" style="top: 46.4%; right: 60.1%;">
        <span>Print On-site</span>
      </div>

      <screen-link left="29.8%" top="43.5%" width="3.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-edit-options" @click.native="onOverlayClick"></screen-link>
      <screen-link left="56%" top="43.5%" width="3.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" data-hours="1" @click.native="onLinkClick"></screen-link>
      <screen-link left="78.7%" top="43.5%" width="7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" data-bonus="1" @click.native="onLinkClick"></screen-link>
      <screen-link left="90%" top="16%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-review-submit" @click.native="onLinkClick"></screen-link>
      <screen-link left="82%" top="16%" width="7.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-payroll-center-options" @click.native="showPullout"></screen-link>
      <screen-link left="16%" top="43%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="showPullout"></screen-link>


      <screen-link left="96%" top="226%" width="4%" height="6%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="payroll" @click.native="$screens.showHelp"></screen-link>





      <payroll-edit-panel v-if="$store.state.overlayPanel === 'payroll-edit-options'" left="29%" top="46%" width="16%" @close="closeOverlay" @link="onLinkClick" @pullout="showPullout"></payroll-edit-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div v-else class="screen" data-screen="payroll-center-resume" :data-active="currentScreen=='payroll-center-resume'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-enterprise-resume.png?v=05072024" />
    <div class="links">

      <div v-if="$store.state.printOnsite" class="onsite-label" style="top: 50.8%; right: 61.2%;">
        <span>Print On-site</span>
      </div>

      <screen-link left="15%" top="14.5%" width="9%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="48%" width="3.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-edit-options" @click.native="onOverlayClick"></screen-link>
      <screen-link left="70.5%" top="48%" width="3.5%" height="5%"  dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" data-hours="1" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.5%" top="48%" width="7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" data-bonus="1" @click.native="onLinkClick"></screen-link>
      <screen-link left="90%" top="20%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-review-submit" @click.native="onLinkClick"></screen-link>
      <screen-link left="82%" top="20%" width="7.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-payroll-center-options" @click.native="showPullout"></screen-link>
      <screen-link left="16%" top="48%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="showPullout"></screen-link>


      <screen-link left="96%" top="226%" width="4%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll" @click.native="$screens.showHelp"></screen-link>





      <payroll-edit-panel v-if="$store.state.overlayPanel === 'payroll-edit-options'" left="29%" top="46%" width="16%" @close="closeOverlay" @link="onLinkClick" @pullout="showPullout"></payroll-edit-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>



  <div v-if="$store.state.company === 'pro'" class="screen" data-screen="payroll-center-resume-edit" :data-active="currentScreen=='payroll-center-resume-edit'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-pro-resume-edit.png?v=05072024" />
    <div class="links">

      <div class="hours-input-pro" style="left:53.75%; top:43.1%;">
        <img v-if="hours===1" class="w-full" src="/img/screens/payroll/payroll-edit-hours-80-focus-pro.png?v=10122022" />
        <img v-if="hours===2" class="w-full" src="/img/screens/payroll/payroll-edit-hours-80-pro.png?v=10122022" />
      </div>

      <div class="bonus-input-pro" style="left:78.9%; top:43.3%;">
        <img v-if="bonus===1" class="w-full" src="/img/screens/payroll/payroll-edit-bonus-100-focus-pro.png?v=10122022" />
        <img v-if="bonus===2" class="w-full" src="/img/screens/payroll/payroll-edit-bonus-100-pro.png?v=10122022" />
      </div>

      <div class="total-input" style="right:60.1%; top:44%;font-size:0.9em;font-weight: bold;">${{ checkTotal }}</div>
      <div class="total-input" style="right:13.25%; top:27.5%;font-size:1.5em;">{{ totalHours }}</div>
      <div class="total-input" style="right:1.25%; top:27.5%;font-size:1.5em;">${{ totalAmount }}</div>

      <div v-if="$store.state.printOnsite" class="onsite-label" style="top: 46.4%; right: 60.1%; background-color: #D3E5F8;">
        <span>Print On-site</span>
      </div>


      <screen-link left="29.8%" top="43.5%" width="3.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-edit-options" @click.native="onOverlayClick"></screen-link>
      <screen-link left="53.7%" top="43.5%" width="6%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" @click.native="toggleHours"></screen-link>
      <screen-link left="78.7%" top="43.5%" width="7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" @click.native="toggleBonus"></screen-link>
      <screen-link left="90%" top="16%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-review-submit" @click.native="onLinkClick"></screen-link>
      <screen-link left="82%" top="16%" width="7.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-payroll-center-options" @click.native="showPullout"></screen-link>
      <screen-link left="16%" top="43%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="showPullout"></screen-link>


      <screen-link left="96%" top="226%" width="4%" height="6%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll" @click.native="$screens.showHelp"></screen-link>
<!--      <payroll-input class="screen-link" style="top: 47.9%;left: 68.2%;" v-model="hours"></payroll-input>-->
      <payroll-edit-panel v-if="$store.state.overlayPanel === 'payroll-edit-options'" left="29%" top="46%" width="16%" @close="closeOverlay" @link="onLinkClick" @pullout="showPullout"></payroll-edit-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div v-else class="screen" data-screen="payroll-center-resume-edit" :data-active="currentScreen=='payroll-center-resume-edit'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-enterprise-resume-edit.png?v=05072024" />
    <div class="links">

      <div class="hours-input" style="left:69.7%; top:47.7%;">
        <img v-if="hours===1" class="w-full" src="/img/screens/payroll/payroll-edit-hours-80-focus.png?v=10122022" />
        <img v-if="hours===2" class="w-full" src="/img/screens/payroll/payroll-edit-hours-80.png?v=10122022" />
      </div>

      <div class="bonus-input" style="left:90.8%; top:47.9%;">
        <img v-if="bonus===1" class="w-full" src="/img/screens/payroll/payroll-edit-bonus-100-focus-pro.png?v=10122022" />
        <img v-if="bonus===2" class="w-full" src="/img/screens/payroll/payroll-edit-bonus-100-pro.png?v=10122022" />
      </div>

      <div class="total-input" style="right:61.2%; top:48.5%;font-size:0.9em;font-weight: bold;">${{ checkTotal }}</div>
      <div class="total-input" style="right:12.35%; top:32.2%;font-size:1.5em;">{{ totalHours }}</div>
      <div class="total-input" style="right:1.25%; top:32.2%;font-size:1.5em;">${{ totalAmount }}</div>

      <div v-if="$store.state.printOnsite" class="onsite-label" style="top: 50.8%; right: 61.2%;background-color: #D3E5F8;">
        <span>Print On-site</span>
      </div>

      <screen-link left="15%" top="14.5%" width="9%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="48%" width="3.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-edit-options" @click.native="onOverlayClick"></screen-link>
      <screen-link left="69.5%" top="48%" width="4.7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" @click.native="toggleHours"></screen-link>
      <screen-link left="90.6%" top="48%" width="7%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-resume-edit" @click.native="toggleBonus"></screen-link>
      <screen-link left="90%" top="20%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="payroll-center-review-submit" @click.native="onLinkClick"></screen-link>
      <screen-link left="82%" top="20%" width="7.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-payroll-center-options" @click.native="showPullout"></screen-link>
      <screen-link left="16%" top="48%" width="11%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="showPullout"></screen-link>


      <screen-link left="96%" top="226%" width="4%" height="6%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="payroll" @click.native="$screens.showHelp"></screen-link>
      <!--      <payroll-input class="screen-link" style="top: 47.9%;left: 68.2%;" v-model="hours"></payroll-input>-->
      <payroll-edit-panel v-if="$store.state.overlayPanel === 'payroll-edit-options'" left="29%" top="46%" width="16%" @close="closeOverlay" @link="onLinkClick" @pullout="showPullout"></payroll-edit-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>


  <div v-if="$store.state.company == 'pro'" class="screen" data-screen="payroll-center-review-submit" :data-active="currentScreen=='payroll-center-review-submit'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-review-submit-pro.png?v=05072024" />
    <div class="links">

      <screen-link left="15%" top="14.5%" width="9%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="39%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="45%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.xlsx?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="51%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/cash-requirements-report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="72%" top="36.25%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="payroll-center-review-submit-pre-check" @click.native="onLinkClick"></screen-link>

      <screen-link left="83%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="onLinkClick"></screen-link>
      <screen-link left="91%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-submitted" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="84.7%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-without-checks" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div v-else class="screen" data-screen="payroll-center-review-submit" :data-active="currentScreen=='payroll-center-review-submit'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-review-submit.png?v=05072024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="9%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="39%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="45%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.xlsx?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="51%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/cash-requirements-report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>

      <screen-link left="72%" top="36.25%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="payroll-center-review-submit-pre-check" @click.native="onLinkClick"></screen-link>

      <screen-link left="83%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="onLinkClick"></screen-link>
      <screen-link left="91%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-submitted" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="96%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-without-checks" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div v-if="$store.state.company == 'pro'" class="screen" data-screen="payroll-center-review-submit-pre-check" :data-active="currentScreen=='payroll-center-review-submit-pre-check'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-review-submit-pro-precheck.png?v=05072024" />
    <div class="links">

      <screen-link left="29%" top="39%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="45%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.xlsx?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="51%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/cash-requirements-report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>

      <screen-link left="83%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="onLinkClick"></screen-link>
      <screen-link left="91%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-submitted" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="84.7%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-without-checks" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div v-else class="screen" data-screen="payroll-center-review-submit-pre-check" :data-active="currentScreen=='payroll-center-review-submit-pre-check'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-review-submit-precheck.png?v=05072024" />
    <div class="links">

      <screen-link left="29%" top="39%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="45%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/payroll_journal_report.xlsx?v=2022" @click.native="$screens.showExternalPage"></screen-link>
      <screen-link left="29%" top="51%" width="13%" height="5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="/documents/cash-requirements-report.pdf?v=2022" @click.native="$screens.showExternalPage"></screen-link>


      <screen-link left="83%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-resume" @click.native="onLinkClick"></screen-link>
      <screen-link left="91%" top="20%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="payroll-center-submitted" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="96%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-without-checks" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="payroll-center-submitted" :data-active="currentScreen=='payroll-center-submitted'">
    <img class="w-full max-w-100" src="/img/screens/payroll/payroll-center-review-submitted.png?v=05082024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="9%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="payroll-center" @click.native="onLinkClick"></screen-link>
      <screen-link left="90%" top="20%" width="9%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="65.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-without-checks" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import InfoBox from "@/components/InfoBox";
import PayrollEditPanel from "@/components/PayrollEditPanel";
import CompanyTab from "@/components/CompanyTab";
//import PayrollInput from "@/components/PayrollInput";



export default {
    name: "admin-payroll",
  components: {
    CompanyTab,
    // PayrollInput,
    PayrollEditPanel,

    InfoBox,
   ScreenLink},
  props: {
      currentScreen: {
        type: String,
        default: ""
      },

  },
    data: function(){
        return {
          rate: 16.00,
          hours: 0,
          bonus: 0,
          companyStartScreen: "payroll-center-resume"
        }
        },
    methods: {

      startPayroll: function(company){
        this.$store.commit("setCompany", company);
        this.$screens.onLinkClick({
          target:{
            dataset:{
              tgt: this.companyStartScreen,
              tgtScrolltop: true
            }
          }
        })
      },

      toggleHours: function(){
        this.hours = (this.hours > 1)?0:(this.hours + 1);
      },

      toggleBonus: function(){
        this.bonus = (this.bonus > 1)?0:(this.bonus + 1);
      },

      onLinkClick: function(e){
        if(e.target.dataset.hours){
          this.hours = parseInt(e.target.dataset.hours);
          this.bonus = 0;
        }
        if(e.target.dataset.bonus){
          this.bonus = parseInt(e.target.dataset.bonus);
          this.hours = 0;
        }
        this.$screens.onLinkClick(e)
      },

      showExternalPage: function(e){
        this.$screens.showExternalPage(e)
      },

      showPullout: function(e){
        this.$screens.showPullout(e)
      },

      onOverlayClick: function(e){
        this.$screens.onOverlayPanel(e)
      },

      closeOverlay: function(){
        this.$screens.setOverlayPanel()
      },

      closePullout: function(){
        this.$screens.closePullout();
      }
    },
    computed: {
      checkTotal: function(){
        let total = 1248;

        if(this.hours === 2){
          total = total + 32;
        }

        if(this.bonus === 2){
          total = total + 100;
        }

        return total.toLocaleString(undefined, { minimumFractionDigits: 2})
      },
      totalHours: function(){
        let total = 4472;

        if(this.hours === 2){
          total = total + 2;
        }

        return total.toLocaleString(undefined, { minimumFractionDigits: 2})
      },
      totalAmount: function(){
        let total = 141210.30;

        if(this.hours === 2){
          total = total + 32;
        }

        if(this.bonus === 2){
          total = total + 100;
        }

        return total.toLocaleString(undefined, { minimumFractionDigits: 2})

      }
    }
}
</script>

<style scoped>


.admin-reports {

}

.reports-labor-cost-threshold-chart {
  position: absolute;
  width: calc((366/1580) * 100%);
  left: calc((306.5/1580)* 100%);
  top: calc((812.5/1830) * 100%);
  opacity: 0;
  cursor: pointer;

}

.reports-labor-cost-threshold-chart:hover {
  opacity: 1.0;
}

.hours-input {
  position: absolute;
  width: calc(100% * 68 / 1580);
}

.hours-input-pro {
  position: absolute;
  width: calc(100% * 92 / 1580);
}

.bonus-input {
  position: absolute;
  width: calc(100% * 104 / 1580);
}

.bonus-input-pro {
  position: absolute;
  width: calc(100% * 104 / 1580);
}

.total-input {
  position: absolute;

}

.onsite-label {
  position: absolute;
  font-size: 0.75em;
  color: #8DBB74;
  background-color: white;
  -webkit-font-smoothing: subpixel-antialiased;
  text-align: right;
  /*D3E5F8*/
}







</style>
