


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">


    <div class="basic-scroll">


        <div class="detail-content" :data-active="true" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-list.png?v=10122022" />
          <div class="links">
            <screen-link left="87%" top="1%" width="11%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
          </div>
        </div>




  </div>
  </div>
  <div class="secondary-panel" ref="basicScroll">


    <div class="basic-scroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='add'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-add.png?v=10122022" />
          <div class="links">
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="80%" top="46.5%" width="17%" height="5%" data-tgt="details" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='details'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-details.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="80%" top="246%" width="19%" height="5%" data-tgt="employment" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='employment'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-details-employment.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="96.5%" width="16%" height="5%" data-tgt="details" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="96.5%" width="25%" height="5%" data-tgt="tasks" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='tasks'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-tasks.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="91%" width="16%" height="5%" data-tgt="employment" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="91%" width="25%" height="5%" data-tgt="compensation" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='compensation'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-compensation.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="155.5%" width="16%" height="5%" data-tgt="tasks" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="155.5%" width="25%" height="5%" data-tgt="taxes" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='taxes'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-taxes.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="108%" width="16%" height="5%" data-tgt="compensation" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="108%" width="25%" height="5%" data-tgt="eeo" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='eeo'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-eeo.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="93.5%" width="16%" height="5%" data-tgt="taxes" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="93.5%" width="25%" height="5%" data-tgt="custom" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='custom'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-custom.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="148.5%" width="16%" height="5%" data-tgt="eeo" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="148.5%" width="25%" height="5%" data-tgt="banking" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='banking'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-banking.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="87.5%" width="16%" height="5%" data-tgt="custom" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="73%" top="87.5%" width="25%" height="5%" data-tgt="deductions" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='deductions'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-deductions.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="62%" width="16%" height="5%" data-tgt="banking" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="42%" top="57.5%" width="17%" height="5%" data-tgt="deductions-add" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='deductions-add'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-deductions-add.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="62%" width="16%" height="5%" data-tgt="banking" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="26%" width="25%" height="5%" data-tgt="deductions-add-expand" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='deductions-add-expand'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-deductions-add-expand.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="9%" top="32%" width="30%" height="4%" data-tgt="deductions-add-401k" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='deductions-add-401k'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-deductions-add-401k.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>

            <screen-link left="78%" top="83%" width="17%" height="5%" data-tgt="time-off" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='time-off'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-time-off.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="64.5%" width="16%" height="5%" data-tgt="deductions" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
            <screen-link left="37%" top="60%" width="26%" height="5%" data-tgt="time-off-40" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='time-off-40'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-time-off-40.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="67.5%" top="16.5%" width="15%" height="5%" data-tgt="time-off" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
            <screen-link left="82.5%" top="16.5%" width="15%" height="5%" data-tgt="time-off-added" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='time-off-added'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-time-off-added.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="62%" width="15%" height="5%" data-tgt="deductions" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
            <screen-link left="74%" top="62%" width="24%" height="5%" data-tgt="contacts" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='contacts'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-contacts.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="50.5%" width="15%" height="5%" data-tgt="time-off-added" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
            <screen-link left="74%" top="50.5%" width="24%" height="5%" data-tgt="done" dot-class="dot-top-center" @click.native="showDetails"></screen-link>
          </div>
        </div>
        <div class="detail-content" :data-active="detailPanel=='done'" >
          <img class="w-full" src="/img/screens/essentials/pullout-worker-final-review-done.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="hiring-essentials-worker-final-review-completed" dot-class="dot-bottom-center" @click.native="$screens.onLinkClick"></screen-link>

            <screen-link left="42%" top="57%" width="16%" height="5%" data-tgt="hiring-essentials-worker-final-review-completed" dot-class="dot-top-center" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>



      </div>

    </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "worker-final-review-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "add",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){



        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        this.$refs['basicScroll'].scrollTo(0,0);


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.primary-panel {
  width: calc((320/1580) * 100%);

}

.secondary-panel {
  width: calc((600/1580) * 100% + 15px);
  height: 100%;
  right: calc((320/1580) * 100%);
  position: absolute;
  overflow-y:scroll;

}

.secondary-panel .links {
  width: 100%;
  height: 0;
  top: 0;
  position: absolute;
  padding-top: calc((860/600) * 100%);


}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;

}



</style>
