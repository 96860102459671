


<template>
<div class="">

  <div class="screen" data-screen="time-attendance-home" :data-active="currentScreen=='time-attendance-home'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-home.png?v=10122022" />
    <div class="links">

      <div class="approval-task-count" style="top:50%;left:70.9%;">
        <p>{{ approvalItemCount }}</p>
      </div>

      <div class="action-task-count" style="top:50%;left:79.5%;">
        <p>{{ actionItemCount }}</p>
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>




      <screen-link left="74.3%" top="59%" width="6%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-tasks" @click.native="onLinkClick"></screen-link>

      <screen-link left="73.3%" top="93%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-taa" data-init-info="time-summary" @click.native="showPullout"></screen-link>
      <screen-link left="42%" top="47%" width="12%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>

      <screen-link left="63.5%" top="55%" width="3.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="customize-graph" @click.native="showPullout"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />


      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


      

      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>By integrating a Time and Attendance solution, clients can track employee time and maximize payroll accuracy. Instead of managing data in multiple places, Paychex Flex® Time supports one integrated solution that includes all company and employee information in a single platform.</p>
      </info-box>
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-tasks" :data-active="currentScreen=='time-attendance-tasks'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-tasks-blank.png?v=10122022" />
    <div class="links">

      <div class="approval-items" style="">
        <img v-if="!tasksCompleted.approveTimeOff" src="/img/screens/taa/cutouts/approvals-all.png?v=10122022" />
        <img v-if="tasksCompleted.approveTimeOff" src="/img/screens/taa/cutouts/approvals-1left.png?v=10122022" />
      </div>

      <div class="action-items" :style="actionItemsStyle">
        <img v-if="!tasksCompleted.fixTimePunch" src="/img/screens/taa/cutouts/actions-all.png?v=10122022" />
        <screen-link v-if="!tasksCompleted.fixTimePunch" left="2%" top="32%" width="96%" height="25%" dot-class="dot-right-center" data-tgt="pullout-taa" data-init-info="fix-timepunch" @click.native="$screens.showPullout"></screen-link>

      </div>

      <screen-link left="15%" top="14%" width="11%" height="4%" dot-class="dot-right-center" data-tgt="time-attendance-home" @click.native="$screens.onLinkClick"></screen-link>
      <screen-link v-if="!tasksCompleted.approveTimeOff" left="29%" top="44.5%" width="56.8%" height="6%" dot-class="dot-right-center" data-tgt="pullout-taa" data-init-info="approve-time-off" @click.native="$screens.showPullout"></screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>By integrating a Time and Attendance solution, clients can track employee time and maximize payroll accuracy. Instead of managing data in multiple places, Paychex Flex® Time supports one integrated solution that includes all company and employee information in a single platform.</p>
      </info-box>
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-status-board" :data-active="currentScreen=='time-attendance-status-board'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-statusboard.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>




      <screen-link left="26.5%" top="30%" width="9%" height="4%"  dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="35.5%" top="30%" width="9%" height="4%"  dot-class="dot-top-center" data-tgt="time-attendance-punch-log" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>
  <div class="screen" data-screen="time-attendance-punchlog" :data-active="currentScreen=='time-attendance-punch-log'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-statusboard-punchlog.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>


      <screen-link left="26.5%" top="30%" width="9%" height="4%"  dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="35.5%" top="30%" width="9%" height="4%"  dot-class="dot-top-center" data-tgt="time-attendance-punch-log" @click.native="onLinkClick"></screen-link>


      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div v-if="!$store.state.tasksCompleted.fixTimePunch" class="screen" data-screen="time-attendance-time-cards" :data-active="currentScreen=='time-attendance-time-cards'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards.png?v=10122022" />
    <div class="links">

      <div v-if="$store.state.taa.baleyRangeApproved" class="approved-label" style="left:75.5%; top: 78%;">
        <img src="/img/screens/taa/cutouts/approved.png?v=10122022">
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>


      <screen-link left="68.5%" top="35%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-time-cards-filtered"  @click.native="onLinkClick"></screen-link>

      <screen-link left="96.25%" top="55%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="refresh-options"  @click.native="$screens.onOverlayPanel"></screen-link>

      <screen-link left="95.5%" top="66.5%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="view-time-card-options"  @click.native="$screens.onOverlayPanel"></screen-link>
      <screen-link left="95.5%" top="83.5%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="view-time-card-options-baley"  @click.native="$screens.onOverlayPanel"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options'"  left="87%" top="66.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary', screenLink: true},
                {label:'View approvals', value:'view-timecard-approvals', screenLink: true},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options-baley'"  left="87%" top="83.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary-baley', screenLink: true},
                {label:'View approvals', value:'view-timecard-summary-baley'},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'refresh-options'"  left="83%" top="54.5%" width="14%"
                           :on-select="refreshTimecards"
                           :items="[
                               {label:'Change approval role', value:'change-approval-role', screenLink: true},
                {label:'Refresh', value:'refresh'},


            ]"
      />
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
      

    </div>
  </div>
  <div v-else class="screen" data-screen="time-attendance-time-cards" :data-active="currentScreen=='time-attendance-time-cards'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards-andrea-approved.png?v=10122022" />
    <div class="links">

      <div v-if="$store.state.taa.baleyRangeApproved" class="approved-label" style="left:75.5%; top: 65%;">
        <img src="/img/screens/taa/cutouts/approved.png?v=10122022">
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link v-if="$store.state.taa.baleyRangeApproved" left="87%" top="42%" width="9%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-cards-batch"  @click.native="onLinkClick"></screen-link>


      <screen-link left="96.25%" top="42%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="refresh-options"  @click.native="$screens.onOverlayPanel"></screen-link>


      <screen-link v-if="!$store.state.taa.baleyRangeApproved" left="95.5%" top="70.5%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="view-time-card-options-baley"  @click.native="$screens.onOverlayPanel"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options'"  left="87%" top="53.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary', screenLink: true},
                {label:'View approvals', value:'view-timecard-approvals', screenLink: true},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options-baley'"  left="87%" top="70.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary-baley', screenLink: true},
                {label:'View approvals', value:'view-timecard-summary-baley'},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'refresh-options'"  left="83%" top="41.5%" width="14%"
                           :on-select="refreshTimecards"
                           :items="[
                               {label:'Change approval role', value:'change-approval-role', screenLink: true},
                {label:'Refresh', value:'refresh'},


            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />


      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-time-cards-filtered" :data-active="currentScreen=='time-attendance-time-cards-filtered'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards-filtered.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>


      <screen-link left="68.5%" top="35%" width="8.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-time-cards-filtered"  @click.native="onLinkClick"></screen-link>

      <screen-link left="96.25%" top="55%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="refresh-options"  @click.native="$screens.onOverlayPanel"></screen-link>

      <screen-link left="95.5%" top="66.5%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="view-time-card-options"  @click.native="$screens.onOverlayPanel"></screen-link>


      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options'"  left="87%" top="66.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary', screenLink: true},
                {label:'View approvals', value:'view-timecard-approvals', screenLink: true},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options-baley'"  left="87%" top="83.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
               {label:'View summary', value:'view-timecard-summary-baley', screenLink: true},
                {label:'View approvals', value:'view-timecard-summary-baley'},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'refresh-options'"  left="83%" top="54.5%" width="14%"
                           :on-select="refreshTimecards"
                           :items="[
                               {label:'Change approval role', value:'change-approval-role', screenLink: true},
                {label:'Refresh', value:'refresh'},


            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
      

    </div>
  </div>


  <div class="screen" data-screen="time-attendance-time-cards-batch" :data-active="currentScreen=='time-attendance-time-cards-batch'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards-batch.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.2%" top="47%" width="3%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-cards-batch-selected"  @click.native="onLinkClick"></screen-link>
      <screen-link left="81.5%" top="42.5%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-cards"  @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-time-cards-batch-selected" :data-active="currentScreen=='time-attendance-time-cards-batch-selected'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards-batch-selected.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="86.5%" top="42%" width="10%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-cards-approved"  @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-time-cards-approved" :data-active="currentScreen=='time-attendance-time-cards-approved'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-timecards-approved.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="83%" top="24%" width="16%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="96.25%" top="42%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="refresh-options"  @click.native="$screens.onOverlayPanel"></screen-link>
      <screen-link left="95.5%" top="53.5%" width="2%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="view-time-card-options"  @click.native="$screens.onOverlayPanel"></screen-link>


      <basic-options-panel v-if="$store.state.overlayPanel === 'view-time-card-options'"  left="87%" top="53.5%" width="12%"
                           :on-select="viewTimeCard"
                           :items="[
                {label:'View summary', value:'view-timecard-summary', screenLink: true},
                {label:'View approvals', value:'view-timecard-approvals', screenLink: true},

            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'refresh-options'"  left="85%" top="41.5%" width="14%"
                           :on-select="refreshTimecards"
                           :items="[
                               {label:'Change approval role', value:'change-approval-role', screenLink: true},
                {label:'Refresh', value:'refresh'},


            ]"
      />

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />


      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules" :data-active="currentScreen=='time-attendance-schedules'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules.png?v=10122022" />
    <div class="links">

      <div v-if="$store.state.taa.shiftAdded" class="added-shift" style="left:38.4%; top:45.4%; width:39%; height: 3.8%;">
        <p class="added-time">8:00 AM – 4:00 PM</p>
        <p class="added-label"><span>9h</span> Fulfillment Associate</p>
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="29.5%" top="35.5%" width="4.25%" height="5%" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="35.5%" width="4.25%" height="5%" dot-class="dot-top-center" data-tgt="time-attendance-schedules-week" @click.native="onLinkClick"></screen-link>

      <screen-link v-if="!$store.state.taa.shiftAdded" left="38.5%" top="45.5%" width="40%" height="5.5%" dot-class="dot-top-center" data-tgt="time-attendance-schedules-addshift" @click.native="onLinkClick"></screen-link>
      <screen-link left="28.5%" top="76.75%" width="15%" height="5.5%" dot-class="dot-top-center" data-tgt="time-attendance-schedules-add-overlap" @click.native="onLinkClick"></screen-link>

      <screen-link left="93%" top="29.25%" width="6%" height="4.5%" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />


      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-week" :data-active="currentScreen=='time-attendance-schedules-week'">
    <img v-if="$store.state.taa.shiftDuplicated" class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules-week-duplicated.png?v=10122022" />
    <img v-else class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules-week.png?v=10122022" />

    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="27.5%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="31.75%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules-week" @click.native="onLinkClick"></screen-link>

      <screen-link v-if="!$store.state.taa.shiftDuplicated" left="38.5%" top="60.5%" width="10%" height="6.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-taa" data-init-info="week-editshift"  @click.native="showPullout"></screen-link>


      <screen-link left="93%" top="29.25%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />


      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-templates" :data-active="currentScreen=='time-attendance-schedules-templates'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules-templates.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>


      <screen-link left="25.5%" top="35.5%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-taa" data-init-info="add-template" @click.native="showPullout"></screen-link>

      <screen-link left="15.5%" top="30%" width="10%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-schedules"  @click.native="onLinkClick"></screen-link>


      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="18.5%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-addshift" :data-active="currentScreen=='time-attendance-schedules-addshift'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules-addshift.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="39%" top="45.5%" width="39%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-taa" data-init-info="add-shift" @click.native="showPullout"></screen-link>
      <screen-link left="28.5%" top="76.75%" width="15%" height="5.5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules-add-overlap" @click.native="onLinkClick"></screen-link>

      <screen-link left="93%" top="29%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <screen-link left="29.5%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules-week" @click.native="onLinkClick"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-add-overlap" :data-active="currentScreen=='time-attendance-schedules-add-overlap'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-add-overlap.png?v=10122022" />
    <div class="links">
      <div v-if="$store.state.taa.shiftAdded" class="added-shift" style="left:38.4%; top:45.4%; width:39%; height: 3.8%;">
        <p class="added-time">8:00 AM – 4:00 PM</p>
        <p class="added-label"><span>9h</span> Fulfillment Associate</p>
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link v-if="!$store.state.taa.shiftAdded" left="39%" top="45.5%" width="39%" height="5.5%"  dot-class="dot-left-center" data-tgt="time-attendance-schedules-addshift" @click.native="onLinkClick"></screen-link>
      <screen-link left="28.5%" top="76.75%" width="15%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-taa" data-init-info="day-overlap" @click.native="showPullout"></screen-link>

      <screen-link left="93%" top="29%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <screen-link left="29.5%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="35.5%" width="4.25%" height="5%"  dot-class="dot-top-center" data-tgt="time-attendance-schedules-week" @click.native="onLinkClick"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-add-overlap-warning" :data-active="currentScreen=='time-attendance-schedules-add-overlap-warning'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-add-overlap-warning.png?v=10122022" />
    <div class="links">

      <div v-if="$store.state.taa.shiftAdded" class="added-shift" style="left:38.4%; top:58.2%; width:39%; height: 3.8%;">
        <p class="added-time">8:00 AM – 4:00 PM</p>
        <p class="added-label"><span>9h</span> Fulfillment Associate</p>
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="58%" top="34.5%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-schedules-add-overlap-filtered" @click.native="onLinkClick"></screen-link>
      <screen-link left="28.2%" top="92.5%" width="30%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-taa" data-init-info="day-overlap-delete" @click.native="showPullout"></screen-link>

      <screen-link left="93%" top="41.75%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-add-overlap-filtered" :data-active="currentScreen=='time-attendance-schedules-add-overlap-filtered'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-add-overlap-filtered.png?v=10122022" />
    <div class="links">


      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="58%" top="34.5%" width="8%" height="4%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-schedules-add-overlap-filtered" @click.native="onLinkClick"></screen-link>
      <screen-link left="28.2%" top="61%" width="30%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-taa" data-init-info="day-overlap-delete" @click.native="showPullout"></screen-link>

      <screen-link left="93%" top="29.25%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-schedules-addshift-success" :data-active="currentScreen=='time-attendance-schedules-addshift-success'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-schedules-success.png?v=10122022" />
    <div class="links">

      <div class="added-shift" style="left:38.4%; top:45.3%; width:39%; height: 3.8%;">
        <p class="added-time">8:00 AM – 4:00 PM</p>
        <p class="added-label"><span>9h</span> Fulfillment Associate</p>
      </div>

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <screen-link left="84%" top="24%" width="15%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.5%" top="76.75%" width="15%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-add-overlap" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="29.25%" width="6%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-templates" @click.native="onLinkClick"></screen-link>

      <screen-link left="29.5%" top="35.5%" width="4.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="35.5%" width="4.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules-week" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-time-off" :data-active="currentScreen=='time-attendance-time-off'">
    <img v-if="!tasksCompleted.approveTimeOff" class="w-full max-w-100" src="/img/screens/taa/time-attendance-timeoff.png?v=10122022" />
    <img v-else class="w-full max-w-100" src="/img/screens/taa/time-attendance-timeoff-approved.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>



      <screen-link left="91%" top="24%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="batch-actions" @click.native="showPullout"></screen-link>

      <screen-link left="65.3%" top="80.5%" width="8%" height="9%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="time-off-day" @click.native="showPullout"></screen-link>

      <screen-link v-if="!tasksCompleted.approveTimeOff" left="16%" top="48%" width="20%" height="6%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="pullout-taa" data-init-info="approve-time-off" @click.native="showPullout"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      

    </div>
  </div>


  <div class="screen" data-screen="time-attendance-classic-home" :data-active="currentScreen=='time-attendance-classic-home'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>


      <screen-link left="17%" top="21%" width="16%" height="4%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceSelections" selection="Status Board" @select="onLinkClick"></select-box>
      </screen-link>

      <screen-link left="83%" top="28%" width="13.25%" height="3%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceTimeFrames" selection="Current Week" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <screen-link left="37%" top="70%" width="18%" height="22%" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-overtime" @click.native="showModal"></screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>By integrating a Time and Attendance solution, clients can track employee time and maximize payroll accuracy. Instead of managing data in multiple places, Paychex Flex® Time supports one integrated solution that includes all company and employee information in a single platform.</p>
      </info-box>
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-four-months-ago" :data-active="currentScreen=='time-attendance-classic-four-months-ago'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-four-months-ago.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="21%" width="16%" height="4%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceSelections" selection="Status Board" @select="onLinkClick"></select-box>
      </screen-link>

      <screen-link left="83%" top="28%" width="13.25%" height="3%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceTimeFrames" selection="Four Months Ago" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <screen-link left="37%" top="70%" width="18%" height="22%" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-overtime" @click.native="showModal"></screen-link>
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-missing-punches" :data-active="currentScreen=='time-attendance-classic-missing-punches'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-missing-punches.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="21%" width="16%" height="4%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceSelections" selection="Missing Punches - Exceptions" @select="onLinkClick"></select-box>
      </screen-link>

      <screen-link left="83%" top="28%" width="13.25%" height="3%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceTimeFrames" selection="Current Week" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <screen-link left="37%" top="70%" width="18%" height="22%" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-overtime" @click.native="showModal"></screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-summary-widget" :data-active="currentScreen=='time-attendance-classic-summary-widget'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-summary-widget.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="21%" width="16%" height="4%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceSelections" selection="Summary Widget" @select="onLinkClick"></select-box>
      </screen-link>

      <screen-link left="83%" top="28%" width="13.25%" height="3%" dot-class="dot-right-center">
        <select-box :selections="timeAttendanceTimeFrames" selection="Current Week" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <screen-link left="37%" top="70%" width="18%" height="22%" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-overtime" @click.native="showModal"></screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-employees" :data-active="currentScreen=='time-attendance-classic-employees'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-employees.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="14%" width="10%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="96.25%" top="17.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85.5%" top="14%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-configurations" :data-active="currentScreen=='time-attendance-classic-configurations'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-configurations.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="14%" width="10%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="96.25%" top="17.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85.5%" top="14%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-audit-trail" :data-active="currentScreen=='time-attendance-classic-audit-trail'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-audit-trail.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="14%" width="10%" height="3%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="96.25%" top="17.5%" width="3%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85.5%" top="14%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards" :data-active="currentScreen=='time-attendance-classic-time-cards'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>

      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="19%" top="44.6%" width="11%" height="3%" data-tgt="time-attendance-classic-time-cards-suzy" @click.native="onLinkClick"></screen-link>

      <screen-link left="82%" top="23.5%" width="17.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-time-cards" @click.native="onLinkClick"></screen-link>


      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards-approvals" :data-active="currentScreen=='time-attendance-classic-time-cards-approvals'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards-approvals.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>


      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="63%" width="4%" height="15%"  data-tgt="time-attendance-classic-time-cards-approvals-partial" @click.native="onLinkClick"></screen-link>

      <screen-link left="82%" top="23.5%" width="17.5%" height="5.5%"  dot-class="dot-left-center" data-tgt="time-attendance-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards-approvals-partial" :data-active="currentScreen=='time-attendance-classic-time-cards-approvals-partial'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards-approvals-partial.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="63%" width="4%" height="15%" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="63%" width="3%" height="15%" dot-class="dot-right-center" data-tgt="time-attendance-classic-time-cards-approvals-fully" @click.native="onLinkClick"></screen-link>
      <screen-link left="82%" top="23.5%" width="17.5%" height="5.5%" dot-class="dot-left-center" data-tgt="time-attendance-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards-approvals-fully" :data-active="currentScreen=='time-attendance-classic-time-cards-approvals-fully'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards-approvals-fully.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="63%" width="4%" height="15%"  data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="63%" width="3%" height="15%"  dot-class="dot-right-center" data-tgt="time-attendance-classic-time-cards-approvals-partial" @click.native="onLinkClick"></screen-link>

      <screen-link left="82%" top="23.5%" width="17.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-time-cards" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards-suzy" :data-active="currentScreen=='time-attendance-classic-time-cards-suzy'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards-suzy.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="19%" top="50%" width="11%" height="3%" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="72%" top="63%" width="3%" height="4%"  dot-class="dot-right-center" data-tgt="time-attendance-classic-time-cards-suzy-geolocation" @click.native="onLinkClick"></screen-link>

      <screen-link left="82%" top="23.5%" width="17.5%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-cards-suzy-geolocation" :data-active="currentScreen=='time-attendance-classic-time-cards-suzy-geolocation'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-time-cards-suzy-geolocation.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="15.5%" top="30.6%" width="7.5%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.5%" top="30.6%" width="12%" height="3.5%"  dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards-approvals" @click.native="onLinkClick"></screen-link>

      <screen-link left="19%" top="44.6%" width="11%" height="3%" data-tgt-scrolltop="true" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="64%" top="37.5%" width="4%" height="5%"  dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-cards-suzy" @click.native="onLinkClick"></screen-link>
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-off" :data-active="currentScreen=='time-attendance-classic-time-off'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-time-off.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="28%" width="8.5%" height="3%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="timeOffViews" selection="[All]" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-off-requested" :data-active="currentScreen=='time-attendance-classic-time-off-requested'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-time-off-requested.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>
      <screen-link left="44%" top="42%" width="5%" height="2%" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-off-requested-approved" @click.native="onLinkClick"></screen-link>

      <screen-link left="41.2%" top="42%" width="2%" height="4%" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-off-requested-calc" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="28%" width="8.5%" height="3%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="timeOffViews" selection="Requested" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-off-requested-calc" :data-active="currentScreen=='time-attendance-classic-time-off-requested-calc'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-time-off-requested-calc.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>
      <screen-link left="44%" top="40.2%" width="5%" height="2%" dot-class="dot-left-center" data-tgt="time-attendance-classic-time-off-requested-approved" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="28%" width="8.5%" height="3%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="timeOffViews" selection="Requested" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-time-off-requested-approved" :data-active="currentScreen=='time-attendance-classic-time-off-requested-approved'">
    <img class="w-full max-w-100" src="/img/screens/admin-time-attendance/admin-time-attendance-time-off-requested-approved.png?v=10122022" />
    <div class="links">

      <screen-link left="15.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-employees" @click.native="onLinkClick"></screen-link>
      <screen-link left="30.5%" top="15.5%" width="8.25%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="38.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="15.5%" width="7.75%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="93%" top="15%" width="3%" height="6%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-time-off-requested" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="28%" width="8.5%" height="3%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="timeOffViews" selection="Requested" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules" :data-active="currentScreen=='time-attendance-classic-schedules'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>

      <screen-link left="34%" top="54.5%" width="16%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-schedules-add-schedule" @click.native="onLinkClick"></screen-link>


      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-add-schedule" :data-active="currentScreen=='time-attendance-classic-schedules-add-schedule'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-add-schedule.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>

      <screen-link left="39%" top="55%" width="4%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-schedules-add-schedule-options" @click.native="onLinkClick"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-add-schedule-options" :data-active="currentScreen=='time-attendance-classic-schedules-add-schedule-options'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-add-schedule-options.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>

      <screen-link left="41.5%" top="58%" width="6%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-classic-schedules-add-schedule-meal" @click.native="onLinkClick"></screen-link>

      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />

      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-add-schedule-meal" :data-active="currentScreen=='time-attendance-classic-schedules-add-schedule-meal'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-add-schedule-meal.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-template" :data-active="currentScreen=='time-attendance-classic-schedules-template'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-template.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-advanced" :data-active="currentScreen=='time-attendance-classic-schedules-advanced'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-advanced.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>

      <screen-link left="57%" top="49%" width="8%" height="3.25%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-schedules-advanced-details" @click.native="showModal"></screen-link>

      <screen-link left="16.1%" top="35.25%" width="8.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="advancedSchedulerViews" selection="Capacity View" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-schedules-advanced-employee" :data-active="currentScreen=='time-attendance-classic-schedules-advanced-employee'">
    <img class="w-full max-w-100" src="/img/screens/taa/admin-time-attendance-schedules-advanced-employee.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="86%" top="24.5%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>


      <screen-link left="15.5%" top="30.5%" width="10.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="26%" top="30.5%" width="11.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-template" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="30.5%" width="12.25%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-schedules-advanced" @click.native="onLinkClick"></screen-link>

      <screen-link left="45%" top="45%" width="12%" height="7%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="time-attendance-classic-schedules-advanced-details" @click.native="showModal"></screen-link>

      <screen-link left="16.1%" top="35.25%" width="8.5%" height="3.5%" data-tgt-scrolltop="true" dot-class="dot-top-center">
        <select-box :selections="advancedSchedulerViews" selection="Employee View" @select="onLinkClick" class="bg-white"></select-box>
      </screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-reports" :data-active="currentScreen=='time-attendance-classic-reports'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-reports.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>


      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />

    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-payroll" :data-active="currentScreen=='time-attendance-classic-payroll'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-payroll.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>


      <screen-link left="15.25%" top="33%" width="27%" height="3%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="time-attendance-classic-payroll-pay-period" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-payroll-pay-period" :data-active="currentScreen=='time-attendance-classic-payroll-pay-period'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-payroll-expanded.png?v=10122022" />
    <div class="links">

      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="26%" top="24%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>

      <screen-link left="53%" top="94%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll-pay-period-submitted" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />
    </div>
  </div>

  <div class="screen" data-screen="time-attendance-classic-payroll-pay-period-submitted" :data-active="currentScreen=='time-attendance-classic-payroll-pay-period-submitted'">
    <img class="w-full max-w-100" src="/img/screens/taa/time-attendance-payroll-submitted.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="18%" width="5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="6.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-status-board" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-time-cards" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="18%" width="5.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-schedules" @click.native="onLinkClick"></screen-link>
      <screen-link left="37.5%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-time-off" @click.native="onLinkClick"></screen-link>
      <screen-link left="42%" top="18%" width="4%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="46%" top="18%" width="4.5%" height="4%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>


      <screen-link left="26%" top="24%" width="13%" height="4%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="time-attendance-classic-payroll" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.5%" top="13.5%" width="6.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="pullout-taa" data-init-info="status-options" @click.native="showPullout"></screen-link>
      <screen-link left="96%" top="13.5%" width="2.25%" height="5.5%" data-tgt-scrolltop="true" dot-class="dot-bottom-center" data-tgt="taa-classic-options" @click.native="$screens.setOverlayPanel('taa-classic-options')"></screen-link>
      <basic-options-panel v-if="$store.state.overlayPanel === 'taa-classic-options'"  left="85%" top="13%" width="14%"
                           :on-select="setClassicScreen"
                           :items="classicOptions"
      />
      <company-tab company="enterprise" select-tgt="time-attendance-home" :active-companies="{}" />


    </div>
  </div>

  <!---POPOVERS-->
  <!-- NOTE: These popovers use a local modal flag. These should be changed to something more url friendlhy -->
  <div class="popover popover-with-bkg" data-screen="time-attendance-classic-overtime" :data-active="modal === 'time-attendance-classic-overtime'">
    <img class="popover-time-attendance" src="/img/screens/admin-time-attendance/admin-time-attendance-overtime.png?v=10122022" />
    <div class="links">
      <screen-link left="83%" top="20%" width="2.5%" height="4%" dot-class="dot-right-center" data-tgt="" @click.native="closePullout"></screen-link>
    </div>
  </div>

  <div class="popover popover-with-bkg" data-screen="time-attendance-classic-schedules-advanced-details" :data-active="modal=='time-attendance-classic-schedules-advanced-details'">
    <img class="popover-schedule-details" src="/img/screens/admin-time-attendance/admin-time-attendance-schedules-advanced-details.png?v=10122022" />
    <div class="links">
      <screen-link left="77.5%" top="20%" width="2.5%" height="4%" dot-class="dot-right-center" data-tgt="" @click.native="closePullout"></screen-link>
    </div>
  </div>

</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import InfoBox from "@/components/InfoBox";
import SelectBox from "@/components/SelectBox";
import BasicOptionsPanel from "@/components/BasicOptionsPanel";





export default {
    name: "admin-time-attendance",
  components: {
    BasicOptionsPanel,
    SelectBox,


    InfoBox,
   ScreenLink},
  props: {
      currentScreen: {},
  },
  mounted() {
      this.$store.state.tasksCompleted.fixTimePunch = false;
    this.$store.state.tasksCompleted.approveTimeOff = false;
    this.$store.state.tasksCompleted.reviewException = false;
    this.$store.state.taa.shiftDuplicated = false;
    this.$store.state.taa.shiftAdded = false;
    this.$store.state.taa.shiftOverlap = false;

  },
  data: function(){
        return {
          modal: "",
          rate: 37.00,
          hours: 40.00,
          bonus: 0,
          checkTotal: 1480.00,
          payrollTotal: 31843.97,
          hoursTotal: 698.00,
          classicOptions: [
            {label:'Employees', value:'time-attendance-classic-employees', screenLink: true},
            {label:'Configuration', value:'time-attendance-classic-configurations', screenLink: true},
            {label:'Audit trails', value:'time-attendance-classic-audit-trail', screenLink: true},


          ],
          timeAttendanceSelections: [
            { label: "Status Board", tgt: "time-attendance-classic-home"},
            { label: "Missing Punches - Exceptions", tgt: "time-attendance-classic-missing-punches"},
            { label: "Summary Widget", tgt: "time-attendance-classic-summary-widget"},

          ],

          timeAttendanceTimeFrames: [
            { label: "Current Month", tgt: ""},
            { label: "Previous Month", tgt: ""},
            { label: "Two Months Ago", tgt: ""},
            { label: "Three Months Ago", tgt: ""},
            { label: "Four Months Ago", tgt: "time-attendance-classic-four-months-ago", showDot: true},
            { label: "Current Week", tgt: "time-attendance-classic-home", showDot: true},
            { label: "Previous Week", tgt: ""},
            { label: "Two Weeks Ago", tgt: ""},
            { label: "Three Weeks Ago", tgt: ""},
            { label: "Four Weeks Ago", tgt: ""},
          ],
          timeOffViews: [
            { label: "[All]", tgt: "time-attendance-classic-time-off", showDot: true},
            { label: "Requested", tgt: "time-attendance-classic-time-off-requested", showDot: true},
            { label: "Approved", tgt: ""},
            { label: "Denied", tgt: ""},


          ],
          advancedSchedulerViews: [
            { label: "Capacity View", tgt: "time-attendance-classic-schedules-advanced"},
            { label: "Employee View", tgt: "time-attendance-classic-schedules-advanced-employee"},


          ],

        }
        },
    methods: {
      onLinkClick: function(e){
        this.$screens.onLinkClick(e)
      },

      showPullout: function(e){
        this.$screens.showPullout(e)
      },

      showModal: function(e){
        this.modal = e.target.dataset.tgt;
      },

      showExternalPage: function(e){
        this.$screens.showExternalPage(e)
      },

      closeOverlay: function(){
        this.$screens.setOverlayPanel()
      },

      closePullout: function(){
        //this.$screens.closePullout();
        this.modal = ""
      },

      setClassicScreen: function(option){
        this.$screens.setOverlayPanel("")
        if(option.screenLink){
          this.onLinkClick({
            target: {
              dataset: {
                tgt: option.value,
              }
            }
          })
        }
      },

      viewTimeCard: function(option){

        this.$screens.setOverlayPanel("")
        if(option.screenLink){
          this.showPullout({
            target: {
              dataset: {
                tgt: "pullout-taa",
                initInfo: option.value
              }
            }
          })
        }


      },

      refreshTimecards: function(e){

        console.log(e);

        if(e.value === "change-approval-role"){
          this.$screens.showPullout({
            target: {
              dataset: {
                tgt: "pullout-taa",
                initInfo: "change-approval-role"
              }

            }
          })
        }else{
          this.$screens.setOverlayPanel("")
        }



      }
    },
    computed: {
      tasksCompleted: function(){
        return this.$store.state.tasksCompleted
      },

      actionItemCount: function(){
        return ((this.tasksCompleted.reviewException?0:1) + (this.tasksCompleted.fixTimePunch?0:1));
      },

      approvalItemCount: function(){
        return (this.tasksCompleted.approveTimeOff?1:2)
      },

      actionItemsStyle: function(){
        if(this.tasksCompleted.approveTimeOff){
          return {
            top: "45%"
          }
        }else{
          return {
            top: "52.9%"
          }
        }

      }
    }
}
</script>

<style scoped>


.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.approval-items {
  position: absolute;
  width: calc((932/1580) * 100%);
  left: 27.8%;
  top: 25.3%;
}

.approval-items img {
  display: block;
  width: 100%;
}

.action-items {
  position: absolute;
  width: calc((932/1580) * 100%);
  left: 27.8%;
  top: 52.9%;

}

.action-items img {
  display: block;
  width: 100%;
}

.action-task-count {
  position: absolute;
background: white;
  width: 5%;
  height: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
}

.approval-task-count {
  position: absolute;
background: white;
  width: 5%;
  height: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
}

.added-shift {
  position: absolute;
  padding: 0.6em;
  border-radius: 0.3em;
  background-color: #FFFBFB;
  color: #565656;
  font-size: 0.75em;
  border: 2px solid #F7CBCA;
}

.added-shift p {
  line-height: 1em;
  margin: 0;
}

.added-time {
  font-size: 1.01em;
  color: #444444;
}

.added-label {
  padding-top: 0.8em;
}
.added-label span {
  padding-right: 0.3em;
}

.approved-label {
  position: absolute;
  width: calc((200/1580)*100%);

}

.approved-label img {
  width: 100%;
}




</style>
