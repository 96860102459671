


<template>
<div class="pullout-panel">
  <div class="bkg"></div>
  <div class="list">


    <div class="scroll" :data-active="!detailPanel">
      <img class="w-full" src="/img/screens/settings/pullouts/pullout-settings-approval-rules-admin.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="10%" top="10%" width="14%" height="6%" data-tgt="edit" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'approval-rules'" >
      <img class="w-full" src="/img/screens/settings/pullouts/pullout-settings-approval-rules-admin.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="2%" top="5%" width="17%" height="6%" data-tgt="approval-rules" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="19%" top="5%" width="12%" height="6%" data-tgt="approval-rules-personal" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="scroll" :data-active="detailPanel == 'approval-rules-personal'" >
      <img class="w-full" src="/img/screens/settings/pullouts/pullout-settings-approval-rules-personal.png?v=10122022" />
      <div class="links">
        <screen-link left="93%" top="1%" width="5%" height="4%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="2%" top="5%" width="17%" height="6%" data-tgt="approval-rules" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="19%" top="5%" width="12%" height="6%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "settings-editor-pullout",
  components: {ScreenLink},
  props: {
      role:{
        type: String,
        default: ""
      },
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
        if(this.$route.query.level !== undefined){
          this.detailPanel = this.$route.query.level;
        }

    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.pullout-panel {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);
}

.pullout-panel .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.pullout-panel img {
  display: block;
}

.links {

}


.list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  overflow-y: scroll;


}



.scroll {
  min-height: 100%;
  display: none;
}

.scroll[data-active="true"]{
  display: block;
}


.w-full {
  width: 100%;
}

.links {

}

</style>
