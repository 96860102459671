import { render, staticRenderFns } from "./EasySetupScreens.vue?vue&type=template&id=2f0b6f83&scoped=true&"
import script from "./EasySetupScreens.vue?vue&type=script&lang=js&"
export * from "./EasySetupScreens.vue?vue&type=script&lang=js&"
import style0 from "./EasySetupScreens.vue?vue&type=style&index=0&id=2f0b6f83&scoped=true&lang=css&"
import style1 from "./EasySetupScreens.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0b6f83",
  null
  
)

export default component.exports