<template>
  <div>
    <div class="screen" data-screen="documents" :data-active="currentScreen=='documents'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents.png?v=10122022" />
      <div class="links">
        <screen-link left="83%" top="43%" width="3%" height="5%" dot-class="dot-left-center" data-tgt="documents-options" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="78%" top="26.5%" width="10%" height="3.5%" dot-class="dot-right-center" data-tgt="documents-library" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="89.2%" top="15%" width="10%" height="6%" dot-class="dot-bottom-center" data-tgt="documents-library" @click.native="showOverlay('document-add-options')"></screen-link>
        <document-add-options-panel v-if="overlayPanel === 'document-add-options'" top="14%" left="86%" @select="showOverlay" />
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
        <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Company-level documents can be added and viewed in this centralized and secure file repository for all company forms, policies and resources. </p>
        </info-box>
      </div>


    </div>

    <div class="screen" data-screen="documents-options" :data-active="currentScreen=='documents-options'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-options.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="48%" width="2%" height="3%" dot-class="dot-left-center" data-tgt="documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="69%" top="51%" width="10%" height="5%" dot-class="dot-left-center" data-tgt="documents-harassment" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="78%" top="26.5%" width="10%" height="3.5%" dot-class="dot-right-center" data-tgt="documents-library" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />

      </div>
    </div>

    <div class="screen" data-screen="documents-library" :data-active="currentScreen=='documents-library'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-library.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13%" width="5%" height="3.5%" dot-class="dot-right-center" data-tgt="documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="27%" top="90.5%" width="50%" height="5%" dot-class="dot-left-center" data-tgt="/img/screens/documents/eeoc_self_print_poster.pdf" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link left="27%" top="98.5%" width="50%" height="5%" dot-class="dot-left-center" data-tgt="/img/screens/documents/eeoc_self_print_poster_spanish.pdf" @click.native="$screens.showExternalPage"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
      </div>
    </div>


    <div class="screen" data-screen="documents-harassment" :data-active="currentScreen=='documents-harassment'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-harassment.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="13%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="78%" top="30%" width="8%" height="6%" dot-class="dot-left-center" data-tgt="documents-harassment-activity" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
      </div>
    </div>

    <div class="screen" data-screen="documents-harassment-activity" :data-active="currentScreen=='documents-harassment-activity'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-harassment-activity.png?v=10122022" />
      <div class="links">
        <screen-link left="96%" top="8%" width="2.5%" height="4%" dot-class="dot-right-center" data-tgt="documents-harassment" @click.native="$screens.onLinkClick"></screen-link>

      </div>
    </div>

    <div class="screen" data-screen="documents-add-form" :data-active="currentScreen=='documents-add-form'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-add-form.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="14%" width="7.5%" height="4%" dot-class="dot-right-center" data-tgt="documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="36%" top="50%" width="42%" height="12%" dot-class="dot-top-center" data-tgt="documents-add-form-build" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
      </div>
    </div>

    <div class="screen" data-screen="documents-add-form-build" :data-active="currentScreen=='documents-add-form-build'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-add-form-build.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="14%" width="11%" height="4%" dot-class="dot-right-center" data-tgt="documents-add-form" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="38%" top="53.5%" width="11.5%" height="3%" dot-class="dot-top-center" data-tgt="documents-add-form-build-fields" @click.native="$screens.onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
      </div>
    </div>

    <div class="screen" data-screen="documents-add-form-build-fields" :data-active="currentScreen=='documents-add-form-build-fields'">
      <img class="w-full max-w-100" src="/img/screens/documents/documents-add-form-build-fields.png?v=10122022" />
      <div class="links">
        <screen-link left="15%" top="14%" width="11%" height="4%" dot-class="dot-right-center" data-tgt="documents" @click.native="$screens.onLinkClick"></screen-link>
        <screen-link left="93.5%" top="18%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="pullout-documents" data-init-info="save-form" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hr" :active-companies="{enterprise: true}" />
      </div>
    </div>


  </div>
</template>

<script>
import DocumentAddOptionsPanel from "@/components/DocumentAddOptionsPanel";
export default {
  name: "AdminDocuments",
  components: {DocumentAddOptionsPanel},
  props: {
    currentScreen: {}
  },
  data: function(){
    return {
      overlayPanel: ""
    }
  },
  methods: {
    showOverlay: function(tgt){
      this.overlayPanel = tgt
    }
  }
}
</script>

<style scoped>

</style>